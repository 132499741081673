import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from "react";
import {
    CampaignImage,
    TextDescription,
    MoviesList,
    MovieSection,
    SetImage,
    SetContent
} from "../../../containers/topSchoolMovies/styles";
// import Banner from '../../../assets/media/banner/Unterseite-Topfilme-102022.jpg';
import { Link } from "react-router-dom";
import infoPNG from '../../../../app/assets/media/info.png';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import i18next from 'i18next';
// import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const propTypes = {
    fetchTopFilmTips: PropTypes.func.isRequired,
    movies: PropTypes.array.isRequired,
    films: PropTypes.array.isRequired,
    getTopMovies: PropTypes.func.isRequired,
    getMovieDetail: PropTypes.func.isRequired,
}
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
const TopFilmTips = ({ fetchTopFilmTips, movies, getMovieDetail, films }) => {
    const [moviesData, setMoviesData] = useState([]);
    const [t, i18n] = useTranslation();
    const [filmList, setFilmList] = useState(films);
    const [filmListData, setFilmListData] = useState([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [place, setPlace] = useState(windowSize.innerWidth < 767 ? "top" : "right");
    const [ratingList, setRatingList] = useState([]);
    const [lowestValue, setLowestValue] = useState([]);
    const uniqueKeys = new Set();
    // const current = new Date();
    // const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    // const location = useLocation();
    // let movieIdString = location?.state?.movieId;
    // let moviesIds = [];
    // if (movieIdString !== undefined) {
    //     moviesIds = JSON.parse(movieIdString);
    // }
    const navigate = useNavigate();
    const languageContents = useMemo(
        () => ({
            title: i18n.language === 'en' || i18n.language === 'en-US' ? 'TitleEN' : 'title',
        }),
        [i18n.language]
    );
    useEffect(() => {
        fetchTopFilmTips()
        // getTopMovies({
        //     date: date
        // })
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        if (windowSize.innerWidth < 767) {
            setPlace("top")
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    useEffect(() => {
        setMoviesData(movies?.[0]);
        movies?.[0]?.linkedMovies?.forEach((item) => {
            getMovieDetail({ id: item?.HOFilmCode })
        })

    }, [movies])
    useEffect(() => {
        setFilmListData([...filmListData, films])
    }, [films])
    // console.log("movies?.[0]",movies?.[0]?.linkedMovies);
    // console.log("moviesIds",moviesIds);
    let newarray = [];
    useEffect(() => {
        // if (moviesIds.length > 0) {
        if (movies?.[0]?.linkedMovies && movies?.[0]?.linkedMovies.length) {
            // moviesIds.map((item) => {
            movies?.[0]?.linkedMovies?.map((item) => {
                // if (films.length > 0) {
                //     const filtered = films.filter(film => {
                //         return film.id === item.HOFilmCode
                //     });
                //     console.log("filtered?.[0]", filtered?.[0])
                //     newarray.push(filtered?.[0])
                // }
                if (filmListData.length > 0) {
                    const filtered = filmListData?.filter(film => {
                        if (film?.id === item.HOFilmCode) {
                            return film.id === item.HOFilmCode;
                        }
                        else {
                            return film?.HOFilmCode === item.HOFilmCode;
                        }
                    });
                    newarray.push(filtered?.[0])
                }
            })
        }
        setFilmList(newarray)
        // }, [films])
    }, [movies?.[0], filmListData])

    const ChangeUrl = async (e, id, cinemaIds, movieDetail) => {
        e.preventDefault();
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('state', cinemaIds);
        // navigate("/movie-detail", { state: { 'id': id, 'state': cinemaIds } });
        navigate(`/film-details/${movieDetail?.shortURL ? movieDetail?.shortURL : movieDetail?.titleCalculated?.replaceAll(' ', '-')}`, { state: { 'id': id, 'state': cinemaIds } });
    }
    useEffect(() => {
        const data = filmList?.length && filmList?.map((item) => {
            const filtered = filmListData.length > 0 && filmListData?.filter(film => {
                return (film?.id === item?.HOFilmCode || film?.HOFilmCode === item?.HOFilmCode)
            });
            return filtered?.[0];
        });
        data?.length && data?.map((item) => {
            if (item?.allStateRatings !== undefined) {
                const ddd = item?.allStateRatings;
                let newData = [];
                if (ddd) {
                    const data = JSON.parse(ddd);
                    let Data22 = data?.stateFsk
                    setRatingList((prevRating) => ({
                        ...prevRating,
                        [item?.id]: Data22,
                    }));
                    if (data?.stateFsk) {
                        data?.stateFsk?.map((item) => {
                            newData.push(item?.fsk);
                        })
                    }
                    let result = newData.sort((a, b) => a.value - b.value)
                    const dataArray = [result[0]]
                    setLowestValue((preValue) => ([...preValue, {
                        [item?.id]: dataArray
                    }]));
                    // setLowestValue(result[0])
                }
            }
            else {
                setRatingList((prevRating) => ({
                    ...prevRating,
                    [item?.id]: [],
                }));
            }


        })

    }, [filmList]);

    // Filter out lowestValue objects with  duplicate keys
    const filteredLowestValueData = lowestValue?.filter(obj => {
        const key = Object.keys(obj)[0];
        if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            return true;
        }
        return false;
    });

    return (
        <>
            <div className='row' style={{ margin: '0' }}>
                <CampaignImage>
                    <h1 className="page-headline">{i18next?.language === "en" ? moviesData?.headtopmovieEN : moviesData?.headtopmovie}</h1>
                    <img src={moviesData?.picture} style={{ width: '100%' }} />
                </CampaignImage>
                <TextDescription>
                    <div className='set_text'>
                        <div className='row'>
                            <div className='col-lg-10 col-md-10 col-12 offset-md-2 p-4' style={{ margin: '0 auto' }}>
                                <h2 className="page-subline">{i18next?.language === "en" ? moviesData?.head2topmovieEN : moviesData?.head2topmovie}</h2>
                                <p className="copytext introtext" dangerouslySetInnerHTML={{ __html: i18next?.language === "en" ? moviesData?.descriptionEN : moviesData?.description }} />
                            </div>
                        </div>
                    </div>
                </TextDescription>
                <MoviesList>
                    <MovieSection>
                        {filmList?.length > 0 ?
                            filmList?.map((item, key) => {
                                const filtered = filmListData.length > 0 && filmListData?.filter(film => {
                                    return film?.id === item?.id

                                });
                                return (
                                    < div className="row mb-5" key={key} >
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <SetImage>
                                                <img src={item?.posterImage} />
                                            </SetImage>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                            <SetContent>
                                                <h2>{item?.titleCalculated}</h2>
                                                <span className="date">Filmstart: {moment(item?.openingDate).format('DD.MM.YYYY')}</span>
                                                <div className="example-container">
                                                    <button className='age_button'>
                                                        <Tooltip anchorId={`my-anchor-element_${key}`} place={place} style={{ backgroundColor: "#fff", color: "#000", textAlign: 'left', fontWeight: "600", boxShadow: '0px 0px 5px 2px #cfcfcf', opacity: '1' }}>
                                                            {(ratingList[item?.id]?.length > 0)
                                                                ? ratingList[item?.id]?.map((item, key) => (
                                                                    < p key={key} >
                                                                        <span className='age_title'>{t(`movie_detail_state-Id.${item?.stateId}`)} : </span>
                                                                        {item?.fsk?.[languageContents.title].toLowerCase()}
                                                                    </p>
                                                                ))
                                                                :
                                                                t('movies.age_info')
                                                            }
                                                        </Tooltip>
                                                        {/* <span>Altersfreigabe:</span><br></br>
                                                    <span><b>ab 10 Jahren</b></span> */}
                                                        <span>{t('movies.age_rating')}:</span><br></br>
                                                        {ratingList[item?.id]?.length > 0 && lowestValue ?
                                                            (
                                                                filteredLowestValueData?.length > 0 && filteredLowestValueData?.map((data) => {
                                                                    return (
                                                                        <span key={key}><b>
                                                                            {data[item?.id]?.[0]?.[languageContents.title]?.toLowerCase()}
                                                                        </b></span>)
                                                                })
                                                            )
                                                            :
                                                            <span key={key}><b>
                                                                {filtered?.[0]?.rating ? filtered?.[0]?.rating : filtered?.[0]?.ratingDescription}
                                                            </b>
                                                            </span>

                                                        }
                                                        <img id={`my-anchor-element_${key}`} src={infoPNG} className='info_set' />
                                                    </button>
                                                </div>
                                                <p>{item?.descriptionCalculated}</p>
                                                <Link to={`/film-details/${item?.shortURL ? item?.shortURL : item?.titleCalculated?.replaceAll(' ', '-')}`} onClick={e => ChangeUrl(e, item?.id, item?.cinemaIds, item)} className="link_set">
                                                    {t('movies.more_info_movie')}
                                                </Link>
                                            </SetContent>
                                        </div>
                                    </div>
                                )
                            })
                            : <h2>{t('common.no_data')}...</h2>
                        }
                    </MovieSection>
                </MoviesList>
            </div>
        </>
    )
}

TopFilmTips.propTypes = propTypes;
export default TopFilmTips;