import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CardUI from '../../Card/CardUI';
import Loader from '../../Loader';
import { Pagination } from 'react-pagination-bar';
import 'react-pagination-bar/dist/index.css'

const propTypes = {
    films: PropTypes.array.isRequired,
    upcoming_films: PropTypes.array.isRequired,
    getTopMovies: PropTypes.func.isRequired,
    getUpComingData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const FilmCard = ({
    getTopMovies,
    films,
    isLoading,
    upcoming_films,
    getUpComingData
}) => {
    // const current = new Date();
    // const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    const [currentPage, setCurrentPage] = useState(1);
    const pagePostsLimit = 20;

    useEffect(() => {
        getTopMovies({
            // date: date,
            filmtipp: true
        })
        getUpComingData()
    }, []);
    // console.log("films,upcoming_films", films, upcoming_films);


    let new_upcoming_films = upcoming_films.filter(movie => {
        if (movie?.master?.Filmtipp === true) {
            return movie;
        }
        return null;
    });

    if (!Array.isArray(films)) {
        films = [];
    }

    films = [...films, ...new_upcoming_films];

    films = films.filter((film, index) => {
        return index === films.findIndex(o => film.id === o.id);
    });

    if (films?.master?.Sortierung === "") {
        films.sort(function (a, b) {
            let firstName = a.master?.title.split("*")[1];
            let lastName = b.master?.title.split("*")[1];
            // console.log("FirstName, SecondName", firstName, lastName);
            if (firstName < lastName) {
                return -1;
            }
            if (firstName > lastName) {
                return 1;
            }
            return 0;
        });
    }
    else {
        films.sort((a, b) => {
            // console.log("a,b", a, b);
            return a.master.Sortierung - b.master.Sortierung;
        })
    }
    // console.log("films", films)
    return (
        <>
            <section className='container'>
                <div className='row'>
                    <div className='main-overview col-lg-9 offset-lg-2 home_page'>
                        <h1 className="overview">Filmtipps</h1>
                        <div className='row'>
                            {films?.length > 0 &&
                                films?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                    ?.map((film, key) => (
                                        <div className='d-flex justify-content-center custom_col' key={key}>
                                            <CardUI film={film}></CardUI>
                                        </div>
                                    ))
                            }
                        </div>
                        <div className='row'>
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={pagePostsLimit}
                                onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                totalItems={films?.length}
                                pageNeighbours={2}
                                customClassNames={{
                                    rpbItemClassName: 'custom-item',
                                    rpbItemClassNameActive: 'custom-item--active',
                                    rpbGoItemClassName: 'custom-go-item',
                                    rpbItemClassNameDisable: 'custom-item--disable',
                                    rpbProgressClassName: 'custom-progress-bar',
                                    rpbRootClassName: 'custom-root',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {isLoading && <Loader />}
        </>
    )
}

FilmCard.propTypes = propTypes;
export default FilmCard