/**
 * Class for Api errors
 */
export class ApiError extends Error {
    /**
     * Creates APIError instance
     *
     * @constructor
     * @param message {string}
     * @param code {number}
     * @param url {string}
     * @param errors {object|undefined}
     * @param type {string|undefined}
     */
    constructor(message, code, url, errors, type) {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        this.code = code;
        this.errors = errors;
        this.type = type;
        this.url = url;
    }
}

/**
 * Class for Auth errors
 */
export class AuthError extends Error {
    /**
     * Creates AuthError instance
     *
     * @constructor
     * @param message {string}
     * @param code {number}
     */
    constructor(message, code) {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        this.code = code;
    }
}
