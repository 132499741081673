import React from 'react'
import { Outlet } from 'react-router'
import Footer from './Footer'
import Navbar from './Navbar'

const index = () => {
    return (
        <div>
            <Navbar />
            <main className='container-fluid'>
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default index