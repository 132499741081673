/**
 * Builds query
 *
 * @param data {object}
 * @returns {string}
 */
const buildQuery = data => {
  const query = Object.keys(data)
    .map(key => {
      const param = String(data[key]).replace(/\s/gi, '+');

      return `${key}=${encodeURIComponent(param)}`;
    })
    .join('&');

  return query ? `?${query}` : '';
};

/**
 * Builds options
 *
 * @param method {string}
 * @param data {object|undefined}
 * @param headers {object}
 * @returns {object}
 */
const buildOptions = (method, data, headers) => ({
  method,
  body: data ? JSON.stringify(data) : null,
  headers
});

/**
 * Pipes further response or reject it as error
 *
 * @private
 * @returns {Promise<Response|Error>}
 */
const handleResponse = res => {
  if (res.ok) {
    return res;
  }

  throw res;
};

/**
 * Makes request
 *
 * @param url {string}
 * @param method {string}
 * @param body {object|undefined}
 * @param query {object|undefined}
 * @param headers {object}
 * @returns {Promise<Response|Error>}
 */
export default function ({ url, method, body, query, headers }) {
  const q = query ? buildQuery(query) : '';
  const options = buildOptions(method, body, headers);
  const path = `${url}${q}`;

  return fetch(path, options).then(handleResponse);
}
