import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import {  useLocation, useNavigate   } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { ToastMsg } from '../../../containers/newsLetter/styles';
import { useTranslation } from 'react-i18next';

const propTypes = {
    // result: PropTypes.func.isRequired,
    activate: PropTypes.func.isRequired
};
const NewsLetterRegistration = ({activate}) =>{

              
        const navigate = useNavigate();
        const [t] = useTranslation();
        const search = useLocation().search
        const searchParams = new URLSearchParams(search)
        const email = searchParams.get('email');
   
              
        useEffect(()=>{
            if(search.length){
                activate(true,email);
                    toast.success(t('newsletter.newsletter_successfully_subscribe'), {
                        position: toast.POSITION.TOP_CENTER,
                        className: 'toast-message_suces'
                    });
                setTimeout(() => {
                    navigate('/')
                },5000);
                }
        },[search])
        
    // useEffect(() => {
    //     if (result!== undefined) {
    //         toast.success('You have registered to Newsletter successfully.', {
    //             position: toast.POSITION.TOP_CENTER,
    //             className: 'toast-message_suces'
    //         });            
    //     }
    //     else {
    //         toast.error('You have not registered to Newsletter successfully.', {
    //             position: toast.POSITION.TOP_CENTER,
    //             className: 'toast-message_suces'
    //         });
    //     }
    //     setTimeout(() => {
    //             navigate('/') 
    //     }, 2000);
    // }, [result])
    return(
        <div>
        <h1 style={{textAlign:"center"}}>Newsletter Registrierung</h1>
        <ToastMsg>
        <ToastContainer/>
        </ToastMsg>
        </div>
    )
}

NewsLetterRegistration.propTypes = propTypes;

export default NewsLetterRegistration;