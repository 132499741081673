import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import UpcomingMovie from "../../components/common/UpcomingMovie";
import { WrapperPage, RowPage } from "./styles";

const UpComingMovie = () => {
    return (
        <WrapperPage>
            <RowPage>
                <UpcomingMovie />
            </RowPage>
        </WrapperPage>
    );
};

export default UpComingMovie;



