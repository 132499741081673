import React from "react";
import TopFilmTipss from "../../components/common/TopFilmTips";
import styled from "styled-components";

const RowPage = styled.main`
    width: 100%;
    float: left;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
`;
const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
`;

const TopFilmTips = () => {

    return (
        <WrapperPage>
            <RowPage>
                <TopFilmTipss />
            </RowPage>
        </WrapperPage>
    );
};

export default TopFilmTips;