import React from "react";
import PropTypes from 'prop-types';
import { CheckBoxes, CheckBoxeDiv } from "../../containers/aktuelle/styles";
import { useTranslation } from "react-i18next";

const propTypes = {
    CheckBoxValue: PropTypes.array.isRequired,
    setCheckBoxValue: PropTypes.array.isRequired,
};
function AgeSidebar({ CheckBoxValue, setCheckBoxValue }) {
    const [t] = useTranslation();
    const checkBoxData = [
        { key: "unrestricted", value: "uneingeschränkt" },
        { key: "6", value: "6" },
        { key: "8", value: "8" },
        { key: "10", value: "10" },
        { key: "12", value: "12" },
        { key: "14", value: "14" },
        { key: "16", value: "16,18" },
        // { key: "18", value: "18" },
        { key: "all", value: "all" }
    ];
    const ageFunction = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            const value_ = e.target.value;
            let value = value_.split(",");
            setCheckBoxValue(CheckBoxValue => [...CheckBoxValue, ...value])
        } else {
            const value_ = e.target.value;
            let value = value_.split(",");
            let filteredValues = CheckBoxValue;
            value.forEach(v => {
                filteredValues = filteredValues.filter(f => f !== v)
            });
            setCheckBoxValue(filteredValues);
        }
    }
    return (
        <>
            <CheckBoxes>
                {
                    checkBoxData?.map((film, key) => (
                        <CheckBoxeDiv key={key}>
                            <div className="check-button">
                                <input type="checkbox" value={film.value} name="age_ratting" onClick={ageFunction} />
                            </div>
                            {film.key === "unrestricted" ? <div className="selection"><b>{t('common.unrestricted')}</b></div>
                                : film.key === "all" ?
                                    <div className="selection"><b>{t('common.all')}</b></div>
                                    : <div className="selection">{t('common.from')} <b className="add-space"> {film.key} </b> {t('common.years')}</div>
                            }
                        </CheckBoxeDiv>
                    ))
                }
            </CheckBoxes>
            <div className="clearfix"></div>
        </>
    )
}

AgeSidebar.propTypes = propTypes;
export default AgeSidebar;
