export const SEND_NEWSLETTER_FORM_DATA = 'newsletter/SEND_NEWSLETTER_FORM_DATA';

export const SUCCESS_SEND_NEWSLETTER_FORM_DATA = 'newsletter/SUCCESS_SEND_NEWSLETTER_FORM_DATA';

export const ACTIVATE_REQUEST = 'newsletter/ACTIVATE_REQUEST';

export const SUCCESS_REGISTRATION_FORM_DATA = 'newsletter/SUCCESS_REGISTRATION_FORM_DATA';


export const DEREGISTRATION_NEWSLETTER = 'newsletter/DEREGISTRATION_NEWSLETTER';

export const SUCCESS_DEREGISTRATION_NEWSLETTER_DATA = 'newsletter/SUCCESS_DEREGISTRATION_NEWSLETTER_DATA';

export const GET_NEWSLETTER_REQUEST_DATA = 'movies/GET_NEWSLETTER_REQUEST_DATA';
export const GET_NEWSLETTER_REQUEST_SUCCESS_DATA = 'movies/GET_NEWSLETTER_REQUEST_SUCCESS_DATA';



