import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { CampaignImage, TextDescription } from '../../../containers/campaigns/styles';
import { CampaignBtn, CampaignsSubClass } from '../../../containers/campaigns/styles';
import 'react-toastify/dist/ReactToastify.css';
// import Banner from '../../../assets/media/banner/header_schulaktionen.jpg';
// import CampaignDummy from '../../../assets/media/campaign-dummy.png';
import { Link } from 'react-router-dom';
import infoPNG from '../../../../app/assets/media/info.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { Tooltip } from 'react-tooltip';

const propTypes = {
    fetchCampaigns: PropTypes.func,
    data: PropTypes.array,
    fetchCampaignsList: PropTypes.func,
    dataList: PropTypes.array,
    getTopMovies: PropTypes.func.isRequired,
    getMovieDetail: PropTypes.func.isRequired,
    films: PropTypes.array.isRequired,
    filmsList: PropTypes.array.isRequired,
};
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
const CampaignsPage = ({ fetchCampaigns, data, filmsList, getMovieDetail, fetchCampaignsList, dataList, getTopMovies }) => {
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const [cdata, setCData] = useState(data[0]);
    // const [Films_, setFilms_] = useState(films);
    const current = new Date();
    const [movieId, setMovieId] = useState();
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;

    const CampaingnsDetail = (e, campaignId, movieId, movieName, cinemaIds, technologie, objectName) => {
        e.preventDefault();
        // navigate("/movie-detail", { state: { 'id': id, 'state': cinemaIds } });     
        // navigate(`/campaigns-detail`, {
        navigate(`/campaigns-detail/${objectName}`, {
            state: {
                'campaignId': campaignId,
                'movieId': movieId,
                'cinemaIds': cinemaIds,
                'technologie': technologie,
                'movieName': movieName,
            }
        });
        // navigate("/campaigns-detail", { 'state': movieId, 'cinemaId': cinemaIds });
    }
    useEffect(() => {
        setCData(data[0])
    }, [data])

    // useEffect(() => {
    //     setFilms_(films)
    // }, [films])
    useEffect(() => {
        fetchCampaigns()
        fetchCampaignsList()
        getTopMovies({ date: date })
    }, [])

    const ChangeUrl = async (e, id, cinemaIds, movieDetail) => {
        e.preventDefault();
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('state', cinemaIds);
        // navigate("/movie-detail", { state: { 'id': id, 'state': cinemaIds } });
        navigate(`/film-details/${movieDetail?.[0]?.shortURL ? movieDetail?.[0]?.shortURL : movieDetail?.[0]?.titleCalculated?.replaceAll(' ', '-')}`, { state: { 'id': id, 'state': cinemaIds } });
    }
    const [ratingList, setRatingList] = useState([]);
    const [lowestValue, setLowestValue] = useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [filmListData, setFilmListData] = useState([]);
    const [place, setPlace] = useState(windowSize.innerWidth < 767 ? "top" : "right");
    const languageContents = useMemo(
        () => ({
            title: i18n.language === 'en' || i18n.language === 'en-US' ? 'TitleEN' : 'title',
        }),
        [i18n.language]
    );
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        if (windowSize.innerWidth < 767) {
            setPlace("top")
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (dataList) {
            dataList?.map((item) => {
                getMovieDetail({ id: item?.hOFilmcode })
            })
        }
    }, [dataList?.length])
    useEffect(() => {
        if (filmsList?.id) {
            setFilmListData((prev) => [...prev, filmsList])
        }
    }, [filmsList])
    useEffect(() => {
        const data = dataList?.map((item) => {
            const filtered = filmListData.length > 0 && filmListData?.filter(film => {
                setMovieId(item.hOFilmcode);
                return (film.id === item.hOFilmcode || film.HOFilmCode === item.hOFilmcode)
            });
            return filtered?.[0];
        });
        data?.map((item) => {
            if (item?.allStateRatings !== undefined) {
                const ddd = item?.allStateRatings;
                let newData = [];
                if (ddd) {
                    const data = JSON.parse(ddd);
                    // console.log("datae3333", data, data?.stateFsk)
                    let Data22 = data?.stateFsk
                    if (movieId) {
                        setRatingList((prevRating) => ({
                            ...prevRating,
                            [item?.id === movieId ? item?.id : movieId]: Data22,
                        }));
                    }
                    if (data?.stateFsk) {
                        data?.stateFsk?.map((item) => {
                            newData.push(item?.fsk);
                        })
                    }
                    let result = newData.sort((a, b) => a.value - b.value)
                    setLowestValue(result[0])
                }
            }
            else {
                setRatingList((prevRating) => ({
                    ...prevRating,
                    [item?.id === movieId ? item?.id : movieId]: [],
                }));
            }


        })

    }, [dataList, filmListData, movieId])

    return (
        <div className='row' style={{ margin: '0' }}>
            <CampaignImage>
                <h1 className="page-headline">{i18next?.language === "en" ? cdata?.headcampaignEN : cdata?.headcampaign}</h1>
                <img src={cdata?.background} style={{ width: '100%' }} />
            </CampaignImage>
            <TextDescription>
                <div className='set_text'>
                    <div className='row'>
                        <div className='col-lg-10 col-md-10 col-12 offset-md-2' style={{ margin: '0 auto' }}>
                            <p className="copytext introtext" dangerouslySetInnerHTML={{ __html: i18next?.language === "en" ? cdata?.descriptionEN : cdata?.description }} />
                        </div>
                    </div>
                </div>
            </TextDescription>
            <CampaignsSubClass>
                {dataList?.length > 0 &&
                    dataList?.map((item, key) => {
                        const filtered = filmListData.length > 0 && filmListData?.filter(film => {
                            // return film.id === item.hOFilmcode
                            // return film.HOFilmCode === item.hOFilmcode
                            if (item?.hOFilmcode?.slice(0, 2) === "HO") {
                                return film.id === item.hOFilmcode;
                            } else {
                                return film.HOFilmCode == item.hOFilmcode;
                            }
                        });
                        return (
                            <div className='row mb-4' style={{ width: '100%', margin: '0' }} key={key}>
                                <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                    <div className='w-100'>
                                        <img className='poster_img' src={filtered?.[0]?.posterImage} />
                                    </div>
                                </div>
                                <div className='col-lg-9 col-md-9 col-sm-12 col-12 gap-2'>
                                    <h2 className='title_head'>{item?.headline}</h2>
                                    <div className="example-container">
                                        <button className='age_button'>
                                            <Tooltip anchorId={`my-anchor-element_${key}`} place={place} style={{ backgroundColor: "#fff", color: "#000", textAlign: 'left', fontWeight: "600", boxShadow: '0px 0px 5px 2px #cfcfcf', opacity: '1' }}>
                                                {(ratingList[item?.hOFilmcode]?.length > 0)
                                                    ? ratingList[item?.hOFilmcode]?.map((item, key) => (
                                                        < p key={key} >
                                                            <span className='age_title'>{t(`movie_detail_state-Id.${item?.stateId}`)} : </span>
                                                            {item?.fsk?.[languageContents.title].toLowerCase()}
                                                        </p>
                                                    ))
                                                    :
                                                    t('movies.age_info')
                                                }
                                            </Tooltip>
                                            <span>{t('movies.age_rating')}:</span><br></br>
                                            <span><b>
                                                {ratingList[item?.hOFilmcode]?.length > 0 && lowestValue ? lowestValue?.[languageContents.title].toLowerCase()
                                                    :
                                                    <>
                                                        {filtered?.[0]?.rating ? filtered?.[0]?.rating : filtered?.[0]?.ratingDescription}
                                                    </>
                                                }
                                            </b></span>
                                            <img id={`my-anchor-element_${key}`} src={infoPNG} className='info_set' />
                                        </button>
                                    </div>
                                    <p className='text_description_part' dangerouslySetInnerHTML={{ __html: item?.descriptionShort }} />
                                    <CampaignBtn>
                                        <Link to="/campaigns-detail" type="submit" className="form-input-button" onClick={e => CampaingnsDetail(e, item?.id, item?.hOFilmcode, filtered?.[0]?.title, filtered?.[0]?.cinemaIds, filtered?.[0]?.technologies[0], item?.objectName)}>{t('campaigns.more_info')}</Link>
                                        <Link onClick={e => ChangeUrl(e, item?.hOFilmcode, filtered?.[0]?.cinemaIds, filtered)} className="form-input-button" style={{ marginLeft: '15px' }}>{t('campaigns.more_film_info')}</Link>
                                    </CampaignBtn>
                                </div>
                            </div >
                        )
                    })
                }
            </CampaignsSubClass >
        </div >
    )
}

CampaignsPage.propTypes = propTypes;

export default CampaignsPage;