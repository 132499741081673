import routes from './routes';

export default (apiEndpoint, apiIalEndpoint, cmsApiEndpoint, domainGroupId, gtmId
) => ({
    rootElementId: 'vapc',
    country: null,
    api: {
        endpoint: apiEndpoint,
        cmsEndpoint: cmsApiEndpoint,
        version: '/api/v1',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Cineplexx-Platform': 'SCHOOLCINEMA',
            'client-key': `${apiIalEndpoint}`,
        },
        user: {
            workZipCode: null
        }
    },

    cmsApi: {
        endpoint: cmsApiEndpoint,
        version: '/restV',
        headers: {
            Authorization:
            'Bearer YPJen3UoCRmKx4aC6oYv5DJ1nwoP0O1tsThFAm41NRT8Uixk9JW8nf4bWc5KAquh4Z9Cbv3F34OiN2Db'
        },
        user: {
            workZipCode: null
        }
    },
    bonusCard: {
        enabled: false
    },
    routes,
    domainGroupId,
    gtmId,
    companyName: 'Schoolcinema',

    player: {
        jquery: '//cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.js',
        url: '//uvp-cineplexx.sf.apa.at/scripts/jquery.uvp.stable.js'
    },

    headerTypes: {
        ACCEPT_LANGUAGE: 'Accept-Language',
        AUTHORIZATION: 'Authorization',
        CONTENT_TYPE: 'Content-Type'
    }
});
