import BaseAPI from '../BaseAPI';

class Campaings extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of cinema advertising
   *
   * @returns {Promise<*|Error>}
   */
  fetchCinemaAll() {
    const url = `/cinema-advertising${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of online advertising
   *
   * @returns {Promise<*|Error>}
   */
  fetchOnlineAll() {
    const url = `/online-advertising${this.baseUrl}`;

    return this.get({ url });
  }

  /**
   * Gets a list of press info
   *
   * @returns {Promise<*|Error>}
   */
  fetchPressInfoAll() {
    const url = `/press-info${this.baseUrl}`;
    return this.get({ url });
  }

  fetchCampaings() {
    const url = `/school-campaings${this.baseUrl}`;
    return this.get({ url });
  }

  fetchLeft() {
    const url = `/school-cinema-carousel-left${this.baseUrl}`;
    return this.get({ url });
  }

  fetchCampaingns() {
    const url = `/school-cinema-campaign-main${this.baseUrl}`;
    return this.get({ url });
  }

  fetchCampaingnsList(id) {
    // const url = `/school-campaings${this.baseUrl}`;
    if (id) {
      const url = `/school-campaings${this.baseUrl}&objectName=${id}`;
      return this.get({ url });
    } else {
      const url = `/school-campaings${this.baseUrl}`;
      return this.get({ url });
    }
  }

  fetchSchoolTopList() {
    const url = `/school-cinema-top-movie${this.baseUrl}`;
    return this.get({ url });
  }

  fetchTopFilmTips() {
    const url = `/school-movie-filmtipp${this.baseUrl}`;
    return this.get({ url });
  }

  fetchRight() {
    const url = `/school-cinema-carousel-right${this.baseUrl}`;

    return this.get({ url });
  }

  fetchCinemaData() {
    const url = `/school-cinema-request${this.baseUrl}`;

    return this.get({ url });
  }

  fetchPosterDataD() {
    const url = `/school-cinema-newsletter${this.baseUrl}`;

    return this.get({ url });
  }

  fetchContactData() {
    const url = `/school-cinema-contact${this.baseUrl}`;

    return this.get({ url });
  }

  fetchAboutInfoData() {
    const url = `/school-cinema-about-info${this.baseUrl}`;

    return this.get({ url });
  }

  fetchImpressumData() {
    const url = `/school-cinema-impressum${this.baseUrl}`;

    return this.get({ url });
  }

  fetchSchoolLandingPageData() {
    // const url = `/school-landing-page${this.baseUrl}`;
    // return this.get({ url });

    const pathname = window.location.pathname;
    const queryParams = pathname.split('/')[2] || '';
    const url = `/school-landing-page${this.baseUrl}&objectName=${queryParams}`;
    return this.get({ url });
  }
}

export default Campaings;
