import styled from 'styled-components';

export const TopMovies = styled.main`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    // margin:24px auto;
    width: 100%;
    float: left;
    .main-overview {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 10px;
    }
    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 17.5%;
        margin: 1% 2% 1% 0;
        vertical-align: top;
        background-color: #ffffff;
        cursor: pointer;
    }
    @media (max-width: 1201px and min-width: 481px) {
        .poster-item {
            width: 20%;
            margin: 0px;
        }
    }
    @media (max-width: 480px) {
        .poster-item {
            width: 50%;
            margin: 0px;
        }
    }
    .figure__caption{
        font-size: 12px;
        font-family: "Arvo",Arial,Verdana;
    }
    h1.overview {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        font-weight: 400;
        color: black;
        margin-left: 20px;
    }
    .poster-item img {
        width: 100%;
        height: auto;
        display: block;
    }
    .poster-title {
        padding: 5px;
        text-align: center;
        background-color: #e9e9e9;
    }
    .home_page .row .custom_col {
        width: 20%;
    }
    @media (max-width: 767px) {
        .home_page .row .custom_col {
            width: 33.33%;
        }
    }
    @media (max-width: 481px) {
        .home_page .row .custom_col {
            width: 50%;
        }
    }
`;

export const CampaignImage = styled.div`
    width: 100%;
    float: left;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    h1.page-headline {
        position: absolute;
        top: 10%;
        left: 10%;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
        @media (max-width: 481px) {
            top: 5%;
            left: 5%;
        }
    }
`;

export const TextDescription = styled.div`
    padding-left: 0;
    padding-right: 0;
    .set_text {
        position: relative;
        background-color: #e9e9e9;
        p.copytext.introtext {
            padding-top: 20px;
        }
        h2.page-subline {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #ff7500;
        }
    }
`;

export const MoviesList = styled.div``;
export const MovieSection = styled.div`
    max-width: 80%;
    width: 100%;
    margin: 30px auto;
`;
export const SetImage = styled.div`
img {
    width: 100%;
    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
}
`;
export const SetContent = styled.div`
h2 {
    text-transform: uppercase;
    font-family: "Arvo",Arial,Verdana;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: black;
    white-space: normal;
}
span.date {
    text-transform: none;
    font-family: "Arvo",Arial,Verdana;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #ff7500;
    white-space: normal;
}
button{
    text-align: center;
    border: 1px solid black;
    margin: 10px 5px 10px 0;
    display: block;
    padding: 5px 30px;
    background: #fff;
    position: relative;
    img.info_set {
        position: absolute;
        bottom: 13%;
        right: 4%;
        height: 17px;
        width: 17px;
    }
}
a.link_set {
    color: #ffffff;
    background-color: #a8a8a8;
    border: 1px solid #a8a8a8;
    padding: 10px 15px;
    font-family: "Arvo",Arial,Verdana;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
    vertical-align: top;
    margin-top: 10px;
    display: inline-block;
}
.example-container .tooltip-censorship-state span {
    float: right;
    margin-left: 10px;
    // box-shadow: 0px 0px 5px 2px #cfcfcf;
}
@media (max-width: 767px) {
    h2{
        font-size: 16px;
    }
    span.date {
        font-size: 14px;
    } 
    p {
        font-size: 12px;
    }
    a.link_set{
        padding: 7px 12px;
        font-size: 12px;
        margin-top: 0px;
    } 
    button{
        font-size: 10px;
    }
}
`;
