import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import CampaignsDetail from './CampaignsDetail.component';
import layoutModule from '../../../store/layout';

const {
    requestMovieDetail,
    requestStateList,
    requestDetailOfCinema,
    requestTopMovies,
    requestForSendFormData,
    requestCampaingsList,
    requestSchoolLandingPage,
    requestCampaignsList,
    requestCinemasList
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getMovieDetail: requestMovieDetail,
    getStateList: requestStateList,
    getDetailOfCinema: requestDetailOfCinema,
    getTopMovies: requestTopMovies,
    sendFormData: requestForSendFormData,
    getCampaingsList: requestCampaingsList,
    getSchoolLandingPage: requestSchoolLandingPage,
    getLocations: requestLocations,
    fetchCampaignsList: requestCampaignsList,
    getCinemas: requestCinemasList,
};

const mapStateToProps = ({ movies, details, layout }) => (
    {
        states: movies.states,
        shortList: movies.shortList,
        details: details,
        films: movies.films,
        filmsList: movies.filmsDetail,
        output: movies.output,
        campaingsList: movies.list,
        isLoading: layout.isLoading,
        dataList: movies.dataList,
        partner: movies.partner,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsDetail);