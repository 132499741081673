import React from 'react';
import { Wrapper } from '../Loader/styles';
import gif from '../../assets/media/orange.gif';

const Svg = () => (
  <Wrapper>
    <img src={gif} className="loader_class" />
  </Wrapper>
);

export default Svg;
