import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { HomeCarousels } from "../../../containers/home/styles";
import LeftCarouselClass from "../../Carousel/left";
import RightCarouselClass from "../../Carousel/right";
import FilmGrid from "../FilmGrid";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../../assets/css/custom.css';
import Loader from '../../Loader';

const propTypes = {
    fetchLeftSlider: PropTypes.func.isRequired,
    leftSlider: PropTypes.array.isRequired,
    fetchRightSlider: PropTypes.func.isRequired,
    rightSlider: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getTopMovies: PropTypes.func.isRequired,
    getMovieDetail: PropTypes.func.isRequired,
    films: PropTypes.array.isRequired,
    filmsLocation: PropTypes.array.isRequired,
}
const Home = ({
    fetchLeftSlider,
    leftSlider,
    fetchRightSlider,
    rightSlider,
    isLoading,
    getTopMovies,
    getMovieDetail,
    films,
    filmsLocation,
}) => {

    const current = new Date();
    const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
    const [filmListData, setFilmListData] = useState([]);
    useEffect(() => {
        fetchLeftSlider()
        fetchRightSlider()
        getTopMovies()
    }, [date])

    // useEffect(() => {
    //     fetchLeftSlider()
    //     fetchRightSlider()
    //     getTopMovies()
    // }, [])

    useEffect(() => {
        const a = leftSlider.map((data) => (data?.linkedMovies))
        const flattenedArray = a.flat();
        flattenedArray && flattenedArray?.map((item) => {
            getMovieDetail({ id: item?.HOFilmCode })
        })
    }, [leftSlider?.length])
    useEffect(() => {
        rightSlider?.[0]?.linkedCampaigns.map((data) => {
            getMovieDetail({ id: data?.hOFilmcode })
        })
    }, [rightSlider?.length])
    useEffect(() => {
        if (films?.id) {
            // setFilmListData([...filmListData, films])
            setFilmListData((prev) => [...prev, films]);
        }
        else if (films?.[0]?.master?.id) {
            setFilmListData([...filmListData, films?.[0]?.master]);
        }
    }, [films?.id])
    return (
        <>
            <HomeCarousels>
                <div className="container">
                    <div className="row main_slider_part" style={{
                        width: '100%', float: 'left', margin: '0',
                        //  background: '#d3d3d3' 
                    }} >
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                            <div className="left_slider_section">
                                <LeftCarouselClass
                                    dataItem={leftSlider}
                                    films={films}
                                    filmListData={filmListData}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                            <div className="right_slider_section">
                                <RightCarouselClass
                                    dataItem={rightSlider}
                                    films={filmsLocation}
                                    filmListData={filmListData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HomeCarousels>
            <FilmGrid />
            {isLoading && <Loader />}
        </>
    )
}

Home.propTypes = propTypes;
export default Home;