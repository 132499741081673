import { connect } from 'react-redux';
import newsletterModule from '../../../store/newsletter';
import NewsLetterDeRegistration from './NewsLetterDeRegistration.component';


const {
    requestForDeregistrationNewsletter,
} = newsletterModule.actions;
const mapDispatchToProps = {
    deactivate: requestForDeregistrationNewsletter,
};

const mapStateToProps = ({newsletter}) => (
    {
        result : newsletter.result,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsLetterDeRegistration);





