import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import NewsLetter from "../../components/common/NewsLetter";

const RowPage = styled.main`
    width: 100%;
    float: left;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
`;
const WrapperPage = styled.main`
max-width: 1280px;
    margin: auto;
`;

const NewsLetterPage = () => {
    return (
        <WrapperPage>
            <RowPage>
                <NewsLetter />
            </RowPage>
        </WrapperPage>
    );
};

export default NewsLetterPage;



