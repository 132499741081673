import React from "react";
import {
    HomePage,
    WrapperPage, RowPage
} from './styles';
import HomeContainer from "../../components/common/Home/Home.container";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/custom.css";

const Home = () => {

    return (
        <WrapperPage>
            <RowPage>
                <HomePage>
                    <HomeContainer />
                </HomePage >
            </RowPage>
        </WrapperPage>
    );
};

export default Home;