import React from "react";
import logo from '../../assets/media/Schulkino_Logo.png'
import styled from "styled-components";
import { HeaderContainer,MovieInfoContainer,PriceInfo,} from "./style.js";
import PropTypes from 'prop-types';

const propTypes = {
    pdfData: PropTypes.array,
};
const PDFExport = ()=>{
    // console.log("PDFDATAAIN EXport",pdfData)
const RowPage = styled.main`
    width: 100%;
    float: left;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
    .footer-info{
    padding-top: 140px;
    padding-bottom: 40px;
    }
    .logo img{
        width: 500px;
    }
`;
const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
`;
    return(
        <WrapperPage>
            <RowPage>
                <HeaderContainer>
                    <div>
                        <div className="header-subcomponent">
                            <div className='right-box'>
                                <h2>Cineplexx Donau AMSTETTEN</h2>
                                {/* <h2>Village Cineplexx  AMSTETTEN</h2> */}                               
                            </div>
                            <div className='left-box'>
                                <h2 className="fw-bold">Service-Information</h2>
                                <p>Reservieren/ Buchen Sie Ihre Schulervorstellung unter unserer Schulhotline per Mail oder uber
                                    unsere Homepage!Hier konnen Sie sich auch anmelden, wenn Sie unsere Filmtipps fur Schulen, Kindergarten etc.
                                    erhalten mochten:
                                </p>
                                <ul>
                                    <li><span>Hotline</span>: +43 (0664) 813 00 13</li>
                                    <li>werktags Mo-Do 08:30-15:00, Fr 08:30-12:00</li>
                                    <li><span>m@il</span>: <a className="link" href='mailto:schule@schulkino.at' alt='Link for Email' >schule@schulkino.at</a> </li>
                                    <li><span>www</span>: <a className="link" href='www.schulkino.at' alt='Link for Website' target='_blank'>www.schulkino.at</a></li>
                                    <li><span>Newsletter Anmeldung: folgen Sie dem</span> <a className="link" href='www.google.com' alt='Link for Website' target='_blank'>Link</a></li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className='header-cinemaInfo'>
                            <div className='cinema-detail'>
                                <ul>
                                    <li>AN/TO: <span className="fw-bold">Stiftsgymnasium Seitenstetten</span> / Fr.Schmutzer</li>
                                    <li>DATUM/DATE: Freitag, 4.August 2023</li>
                                </ul>
                            </div>
                            <div className='contact-detail'>
                                <ul>
                                    <li>Tel: 0664 / 467 55 74</li>
                                    <li>Mail: <a className="link fw-light" href='mailto:xyz@gmail.com' alt='Link for Email' >xyz@gmail.com</a></li>
                                </ul>
                            </div>

                        </div>
                        <hr className='hr-tag mt-0'></hr>
                    {/* </div> */}
                </HeaderContainer>
                <MovieInfoContainer>
                    <div className='movie-info'>
                        <h2 className="fw-bold">BESTATIGUNG GRUPPENBUCHUNG  ,,Hilfe, ich hab meine Eltern geschrumpft&quot;</h2>
                        <p className="normal-text">Sehr geehrte Frau Schmutzer,</p>
                        <p className="normal-text">anbei finden Sie alle Details zu der von Ihnen bestellen Vorstellung:</p>
                        <ul>
                            <li><label>Termin:</label><span className='bold'>Freitag, der 22.Dezember 2023</span></li>
                            <li><label>Zeit:</label> <span className='bold'>17:30</span></li>
                            <li><label>Film:</label><span><span className='normal-text'>Hilfe, ich hab meine Eltern geschrumpft</span>
                                <p className="normal-text">(Lange Hauptfilm: ca.99 Minuten, Altersfreigabe: ab 6 Jahre)</p></span></li>
                            <li><label>Kino:</label><span><span className='bold'>Cineplexx Amstetten</span><span className="normal-text">(Waidhofner Strabe 42b, 3300 Amstetten-Greinsfurth)</span></span></li>
                        </ul>
                        <hr className='hr-tag' />
                    </div>
                </MovieInfoContainer>
                <PriceInfo>
                    <div className='price-info'>
                        <ul>
                            <li><label>Anzahl der Schulerlnnen:</label><span className="normal-text">~40 - 45 Kinder + Begleitpersonen </span></li>
                            {/* <li><label>Anzahl der Schulerlnnen:</label><span className='bold'>~40 - 45 Kinder + Begleitpersonen </span></li> */}
                            <li><label>Verrechnung:</label><span><span className='bold'>
                                <p> <span className="euro">€ 6,-/ </span> Kind (ab 10 Kinder)</p>
                                <p>€ 60, -/ Kleingruppenpauschale(wenn unter 10 Kinder)</p>
                            </span>
                            <p className="normal-text">Auf 10 kinder geht eine Begleitperson frei</p>
                            </span></li>
                            <li><label>Ablauf Vorstellung:</label><span className='bold'>
                                <p className='mb-2'>Es wird in einer offentlichen Vorstellung des regularen Programms fur lhre Gruppe ein Kartenkontingent reserviert.Bitte holen Sie die Karten bis zumindest 15 Minuten vor Filmbeginn ab!</p>
                                <p>Bitte das Geld vor der Vorstellung einsammeln und an der kassa ubergeben! Alternativ ist auch die Komplettzahlung mit Kredit- oder Bankomatkarte moglich.</p>
                            </span></li>
                        </ul>
                        <p className='bold bottom_info'>WIR WUNSCHEN IHNEN EIN UNVERGESSLICHES KINOERLEBNIS!</p>
                        <p className='mb-0 bold'>Matthias Pfeiffer</p>
                        <p className="normal-text">Buchung Kinder- & Schulvorstellungen</p>
                    </div>
                    <div className='info-das-box'>
                        <p className='headline'>Info an das Kino:</p>
                        <p className="normal-text">Bitte nehmen Sie die Reservierung in der regularen Vorstellung vor!</p>
                        <p className="normal-text">Die Karten sind unter XXX reserviert</p>
                    </div>
                </PriceInfo>
                <div className='footer-info'>
                    <div className='logo'>
                        <img src={logo} alt='School Logo' />
                    </div>
                </div>
            </RowPage>
        </WrapperPage>      
    )
}

PDFExport.propTypes = propTypes;

export default PDFExport;