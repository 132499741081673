/* eslint-disable no-dupe-else-if */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import 'react-pagination-bar/dist/index.css';
import { AdminLoginWrapper, TabBtnWrapper } from '../../../containers/adminLogin/styles';
import logo from '../../../assets/media/Schulkino_Logo.png'
import Table from 'react-bootstrap/Table'
import Loader from '../../Loader';
import { Pagination } from 'react-pagination-bar';
import { useTranslation } from 'react-i18next';
import { TextBox } from 'react-form-elements';
import Excelexport from '../../Excelexport';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FormInput from '../../FormInput';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { HeaderContainer, MovieInfoContainer, PriceInfo, RowPage, WrapperPage } from '../../PDFExport/style';
const propTypes = {
    getRequestData: PropTypes.func.isRequired,
    getCampaignRequestData: PropTypes.func.isRequired,
    getCampaignOtherRequestData: PropTypes.func.isRequired,
    getNewsLetterRequestData: PropTypes.func.isRequired,
    sendFormData: PropTypes.func.isRequired,
    sendCampainFormData: PropTypes.func.isRequired,
    requestData: PropTypes.array,
    requestDataNewsLetter: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
};

const AdminDetailList = ({ getRequestData, getCampaignRequestData, getCampaignOtherRequestData, getNewsLetterRequestData, requestData, requestDataNewsLetter, isLoading }) => {
    const [t] = useTranslation();
    const [dataList, setDataList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState({});
    const pagePostsLimit = 10;
    const [defaultForm, setdefaultForm] = useState("schoolCinemaRequest");
    const [fromdate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [pdfData, setPdfData] = useState([]);
    // const [answered, setAnswered] = useState(false);    
    const userData = useRef();

    useEffect(() => {
        if (defaultForm === 'schoolCinemaRequest') {
            getRequestData({ "limit": 0, "offset": 0 });
            setToDate("");
            setFromDate("");
            setPdfData([]);
            setCurrentPage(1);
            // console.log("schoolCinemaRequest, requestData",requestData);
        }
        else if (defaultForm === 'schoolCinemapromotionsRequest') {
            getCampaignRequestData({ "limit": 0, "offset": 0 })
            setToDate("");
            setFromDate("");
            setCurrentPage(1);
            setPdfData([]);
            // console.log("schoolCinemapromotionsRequest, requestData",requestData);
        }
        else if (defaultForm === 'schoolCinemaTeachingStaffRequest') {
            getCampaignOtherRequestData({ "limit": 0, "offset": 0 })
            setToDate("");
            setFromDate("");
            setCurrentPage(1);
            setPdfData([]);
        }
        else {
            // console.log("NewsLetter Data")
            getNewsLetterRequestData({ "limit": 0, "offset": 0 });
            setCurrentPage(1);
            setPdfData([]);
        }
    }, [defaultForm])

    useEffect(() => {
        setDataList(requestData)
    }, [requestData])

    useEffect(() => {
        setDataList(requestDataNewsLetter)
    }, [requestDataNewsLetter])

    const GoesToForm = (e, type) => {
        e.preventDefault();
        setdefaultForm(type)
    }
    // console.log("datalist",dataList);
    const handleChange = (event) => {
        let Data = { ...searchInput, [event.target.name]: event.target.value };
        setSearchInput(Data)
        globalSearch(Data)
        if (defaultForm === 'newsLetterregistration') {
            if (event.target.value === "") {
                setDataList(requestDataNewsLetter);
            }
        }
        else {
            if (event.target.value === "") {
                setDataList(requestData)
            }
        }
    };

    const globalSearch = (searchValue) => {

        let filterDataList;
        const filteredData = dataList?.filter((value) => {
            if (searchValue?.firstName) {
                filterDataList = value?.firstname?.toLowerCase().includes(searchValue?.firstName?.toLowerCase())
            }

            if (searchValue?.lastName) {
                filterDataList = value?.lastname?.toLowerCase().includes(searchValue?.lastName?.toLowerCase())
            }

            if (searchValue?.email) {
                filterDataList = value?.mail?.toLowerCase().includes(searchValue?.email?.toLowerCase())
            }

            if (searchValue?.firstName && searchValue?.lastName) {
                filterDataList = value?.firstname?.toLowerCase().includes(searchValue?.firstName?.toLowerCase()) &&
                    value?.lastname?.toLowerCase().includes(searchValue?.lastName?.toLowerCase())
            }

            if (searchValue?.firstName && searchValue?.lastName && searchValue?.email) {
                filterDataList = value?.firstname?.toLowerCase().includes(searchValue?.firstName?.toLowerCase()) &&
                    value?.lastname?.toLowerCase().includes(searchValue?.lastName?.toLowerCase()) &&
                    value?.mail?.toLowerCase().includes(searchValue?.email?.toLowerCase())
            }


            if (searchValue?.firstName && searchValue?.email) {
                filterDataList = value?.firstname?.toLowerCase().includes(searchValue?.firstName?.toLowerCase()) &&
                    value?.mail?.toLowerCase().includes(searchValue?.email?.toLowerCase())
            }

            if (searchValue?.lastName && searchValue?.email) {
                filterDataList = value?.lastname?.toLowerCase().includes(searchValue?.lastName?.toLowerCase()) &&
                    value?.mail?.toLowerCase().includes(searchValue?.email?.toLowerCase())
            }
            return filterDataList;
        }
        );
        setDataList(filteredData);
    };

    // const handleCheckedAnswer = (e,data) =>{
    //     // console.log("Checked Answer.,data",data,event.target.checked)
    //     const dateTime = new Date(data.dateTime.date).toISOString()
    //     console.log("dateTime,dateTime2",data.dateTime.date,dateTime);        
    //     // console.log("dateTime",);        
    //     if(e.target.checked === true){
    //         data.answered = true;
    //         data.dateTime = dateTime;
    //         // delete data.dateTime
    //         sendCampainFormData(data);            
    //         console.log("data",data,data.answered,e.target?.checked);
    //     }
    //     else{
    //         data.answered = false;
    //         data.dateTime = dateTime;
    //         // delete data.dateTime
    //         sendCampainFormData(data);
    //         console.log("data",data,data.answered,e.target?.checked);
    //     }

    // }
    // const handleCheckedAnswerMovieRequest = (e,data) =>{
    //     // console.log("Checked Answer.,data",data,event.target.checked)
    //     // const dateTime = new Date(data.dateTime.date).toISOString()
    //     // console.log("dateTime,dateTime2",data.dateTime.date,dateTime);        
    //     // console.log("dateTime",);        
    //     if(e.target.checked === true){
    //         data.answered = true;
    //         // data.dateTime = dateTime;
    //         sendFormData(data);

    //         console.log("data",data,data.answered,e.target?.checked);
    //     }
    //     else{
    //         data.answered = false;
    //         // data.dateTime = dateTime;
    //         sendFormData(data);
    //         console.log("data",data,data.answered,e.target?.checked);
    //     }

    // }
    const handleFromDate = (e) => {
        setFromDate(e);
        setDataList(requestData);
    }
    const handleToDate = (e) => {
        setToDate(e);
        setDataList(requestData);
    }
    useEffect(() => {
        if (fromdate !== '' && toDate !== '') {
            filterDate();
        }
    }, [fromdate, toDate])
    const filterDate = () => {
        // const startDate = moment(fromdate).format('DD.MM.YYYY')
        const startDate = moment(fromdate, 'DD.MM.YYYY')
        const startYear = startDate?.format('YYYY');
        const startMonth = startDate?.format('MM');
        const startDateVal = startDate?.format('DD');

        const endDate = moment(toDate, 'DD.MM.YYYY');
        const endYear = endDate?.format('YYYY');
        const endMonth = endDate?.format('MM');
        const endDateVal = endDate?.format('DD');
        // let filterDataList;
        const filteredData = dataList?.filter((data) => {
            // let date = data?.dateTime?.date.split(" ")[0];
            let dataMoment = moment(data?.dateTime, 'DD.MM.YYYY');
            const dataYear = dataMoment?.format('YYYY');
            const dataMonth = dataMoment?.format('MM');
            const dataDate = dataMoment?.format('DD');



            if (startYear === endYear) {
                if (startMonth === endMonth) {
                    return dataYear === startYear && dataMonth === startMonth &&
                        dataDate >= startDateVal && dataDate <= endDateVal;
                } else {
                    return dataYear === startYear && dataMonth >= startMonth ||
                        dataYear === endYear && dataMonth <= endMonth;
                }
            } else {
                return (dataYear === startYear && dataMonth >= startMonth && dataDate >= startDateVal) ||
                    (dataYear === endYear && dataMonth <= endMonth && dataDate <= endDateVal) ||
                    (dataYear > startYear && dataYear < endYear);
            }
        })
        // console.log("filterDataList", filterDataList);
        setDataList(filteredData);
        setCurrentPage(1)
        if (fromdate === null && toDate === null) {
            setDataList(requestData);
        }
    }
    const exportAsPdfData = (data) => {
        setPdfData([data]);
        setTimeout(() => {
            exportAsPdf();

        }, 1000);
    }
    const exportAsPdf = () => {
        html2canvas(userData.current).then((canvas) => {
            // console.log("CANvas",canvas)
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save("user_data.pdf");
        });
    };


    // const exportAsPdf = ()=>{
    //     console.log("user",userData.current)
    // }


    return (
        <>
            <section className='container'>
                <div className='row'>
                    <AdminLoginWrapper>
                        <div className='adminLogin_navbar'>
                            <TabBtnWrapper>
                                <div className=''>
                                    <Link className={"flat-button flat-button-details mb-4 " + ((defaultForm === "schoolCinemaRequest") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "schoolCinemaRequest")}>
                                        <p className="m-0">Schulkino Anfragen</p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link className={"flat-button flat-button-details mb-4 " + ((defaultForm === "schoolCinemapromotionsRequest") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "schoolCinemapromotionsRequest")}>
                                        <p className="m-0">Schulkino Aktionen Anfragen</p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link className={"flat-button flat-button-details mb-4 " + ((defaultForm === "schoolCinemaTeachingStaffRequest") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "schoolCinemaTeachingStaffRequest")}>
                                        <p className="m-0">Schulkino Aktion Anfragen Lehrpersonal</p>
                                    </Link>
                                </div>
                                <div className=''>
                                    <Link className={"flat-button flat-button-details mb-4 " + ((defaultForm === "newsLetterregistration") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "newsLetterregistration")}>
                                        <p className="m-0">NewsLetter Registrierung</p>
                                    </Link>
                                </div>
                            </TabBtnWrapper>
                        </div>

                        <div className='adminLogin_heading'>
                            <h2>{t('form.admin_detail')}</h2>
                        </div>
                        <div className='admin_filter_List'>
                            <div className='admin_data'>
                                <div className='admin_input_field'>
                                    <TextBox
                                        label={t('form.firstName')}
                                        name="firstName"
                                        className="form-input"
                                        onChange={handleChange}
                                        id="firstName"
                                        value={searchInput?.firstName || ''}
                                    />
                                </div>
                                <div className='admin_input_field'>
                                    <TextBox
                                        label={t('form.lastName')}
                                        name="lastName"
                                        className="form-input"
                                        onChange={handleChange}
                                        id="lastName"
                                        value={searchInput?.lastName || ''}
                                    />
                                </div>
                                <div className='admin_input_field'>
                                    <TextBox
                                        label={t('form.email')}
                                        name="email"
                                        className="form-input"
                                        onChange={handleChange}
                                        id="email"
                                        value={searchInput?.email || ''}
                                    />
                                </div>
                                {(defaultForm === 'schoolCinemaRequest' || defaultForm === 'schoolCinemapromotionsRequest' || defaultForm === 'schoolCinemaTeachingStaffRequest') &&
                                    <>
                                        <div className='admin_input_field form-input'>
                                            {/* <label className='heading'>{` ${t('common.date')}`}</label> */}
                                            <label className='heading'>Datum von</label>
                                            <FormInput
                                                type={"date"}
                                                label="false"
                                                selected={fromdate}
                                                name={fromdate}
                                                className="form-input"
                                                onChange={(e) => handleFromDate(e)}
                                                id='date'
                                                autoComplete={"off"}
                                            />
                                        </div>
                                        <div className='admin_input_field form-input'>
                                            <label className='heading'>bis</label>
                                            <FormInput
                                                type={"date"}
                                                label="false"
                                                selected={toDate}
                                                name={toDate}
                                                className="form-input"
                                                onChange={(e) => handleToDate(e)}
                                                id="bis"
                                                autoComplete={"off"}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='admin_export_button'>
                                <Excelexport excelData={dataList} fileName="AdminDetail" />
                            </div>
                        </div>
                        {/* <div className='mt-3'>
                            <Table bordered hover responsive className='admin_table'>
                                <thead>
                                    <tr>
                                        <th>School Name</th>
                                        <th>Street</th>
                                        <th>Zip</th>
                                        <th>Town</th>
                                        <th>State</th>
                                        <th>Country</th>
                                        <th>School Phone Numer</th>
                                        <th>Title</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email </th>
                                        <th>Phone Number</th>
                                        <th>Movie</th>
                                        <th>Cinema Name</th>
                                        <th>Another Film</th>
                                        <th>Date Time</th>
                                        <th>Technologie</th>
                                        <th>Number Of Persons</th>
                                        <th>Number Of Students</th>
                                        <th>Language Version</th>
                                        <th>Further Info</th>
                                        <th>Newsletter Subscribe</th>
                                        <th>Answered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList?.length > 0 ?
                                        dataList?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                            ?.map((data, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{data?.nameofschool}</td>
                                                        <td>{data?.street}</td>
                                                        <td>{data?.zip}</td>
                                                        <td>{data?.town}</td>
                                                        <td>{data?.state}</td>
                                                        <td>{data?.country}</td>
                                                        <td>{data?.schoolPhonenumer}</td>
                                                        <td>{data?.title}</td>
                                                        <td>{data?.firstname}</td>
                                                        <td>{data?.lastname}</td>
                                                        <td>{data?.mail}</td>
                                                        <td>{data?.phone}</td>
                                                        <td>{data?.movie}</td>
                                                        <td>{data?.cinema}</td>
                                                        <td>{data?.anotherFilm}</td>
                                                        <td>{moment(data?.dateTime?.date).format('DD.MM.YYYY HH:mm:ss')}</td>
                                                        <td>{data?.technologie}</td>
                                                        <td>{data?.numberaccoPersons}</td>
                                                        <td>{data?.numberofStudents}</td>
                                                        <td>{data?.languageVersion}</td>
                                                        <td>{data?.furtherInfo}</td>
                                                        <td>{data?.subscribe === true ? "Subscribed" : "Not Subscribed"}</td>
                                                        <td><input type="checkbox" checked={data?.answered === true ? true : false} /></td>
                                                    </tr>)
                                            })
                                        : <h2>{t('common.no_data')}</h2>
                                    }
                                </tbody>
                            </Table>
                        </div> */}

                        {/* For  schoolCinemaRequest*/}
                        {
                            defaultForm === 'schoolCinemaRequest' &&
                            <>
                                <div className='mt-3'>
                                    <Table bordered hover responsive className='admin_table'>
                                        <thead>
                                            <tr>
                                                <th>School Name</th>
                                                <th>Street</th>
                                                <th>Zip</th>
                                                <th>Town</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>School Phone Numer</th>
                                                <th>Title</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email </th>
                                                <th>Phone Number</th>
                                                <th>Movie</th>
                                                <th>Cinema Name   </th>
                                                <th>Another Film</th>
                                                <th>Date Time</th>
                                                <th>Technologie</th>
                                                <th>Number Of Persons</th>
                                                <th>Number Of Students</th>
                                                <th>Language Version</th>
                                                <th>Further Info</th>
                                                <th>Answered</th>
                                                {/* <th>Export PDF</th> */}
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {dataList?.length > 0 ?
                                                dataList?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                                    ?.map((data, key) => {
                                                        // console.log("data",data)
                                                        return (

                                                            <tr key={key}>
                                                                <td>{data?.nameofschool}</td>
                                                                <td>{data?.street}</td>
                                                                <td>{data?.zip}</td>
                                                                <td>{data?.town}</td>
                                                                <td>{data?.state}</td>
                                                                <td>{data?.country}</td>
                                                                <td>{data?.schoolPhonenumer}</td>
                                                                <td>{data?.title}</td>
                                                                <td>{data?.firstname}</td>
                                                                <td>{data?.lastname}</td>
                                                                <td>{data?.mail}</td>
                                                                <td>{data?.phone}</td>
                                                                <td>{data?.movie}</td>
                                                                <td>{data?.cinema}</td>
                                                                <td>{data?.anotherFilm}</td>
                                                                <td>{data?.dateTime}</td>
                                                                {/* <td>{moment(data?.dateTime?.date).format('DD.MM.YYYY HH:mm:ss')}</td> */}
                                                                <td>{data?.technologie}</td>
                                                                <td>{data?.numberaccoPersons}</td>
                                                                <td>{data?.numberofStudents}</td>
                                                                <td>{data?.languageVersion}</td>
                                                                <td>{data?.furtherInfo}</td>
                                                                <td><input type="checkbox" checked={(data?.answered === true ? true : false)} /></td>
                                                                <td><button style={{ width: '90px' }} onClick={() => exportAsPdfData(data)}>Export Pdf</button></td>
                                                                {/* <td><input type="checkbox" checked={(data?.answered === true ? true : false)} onChange={(event)=>handleCheckedAnswerMovieRequest(event,data)}/></td> */}
                                                                {/* <td>{data?.title ? <button >Export Pdf</button> : <PDFExport pdfData={data} />}</td> */}
                                                            </tr>

                                                        )
                                                    })
                                                // : <div><h2 style={{width:"430px"}}>{t('common.no_data')}</h2></div>
                                                : <tr><td colSpan={22} style={{ textAlign: 'center' }}><h2>{t('common.no_data')}</h2></td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='row pagination'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={dataList?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </>
                        }
                        {/* For schoolCinemapromotionsRequest */}
                        {
                            defaultForm === "schoolCinemapromotionsRequest" &&
                            <>
                                <div className='mt-3'>
                                    <Table bordered hover responsive className='admin_table'>
                                        <thead>
                                            <tr>
                                                <th>School Name</th>
                                                <th>Street</th>
                                                <th>Zip</th>
                                                <th>Town</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>School Phone Numer</th>
                                                <th>Title</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email </th>
                                                <th>Phone Number</th>
                                                <th>Movie</th>
                                                <th>Cinema Name</th>
                                                <th>Another Film</th>
                                                <th>Date Time</th>
                                                <th>Technologie</th>
                                                <th>Number Of Persons</th>
                                                <th>Number Of Students</th>
                                                <th>Language Version</th>
                                                <th>Further Info</th>
                                                <th>Answered</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataList?.length > 0 ?
                                                dataList?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                                    ?.map((data, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{data?.nameofschool}</td>
                                                                <td>{data?.street}</td>
                                                                <td>{data?.zip}</td>
                                                                <td>{data?.town}</td>
                                                                <td>{data?.state}</td>
                                                                <td>{data?.country}</td>
                                                                <td>{data?.schoolPhonenumer}</td>
                                                                <td>{data?.title}</td>
                                                                <td>{data?.firstname}</td>
                                                                <td>{data?.lastname}</td>
                                                                <td>{data?.mail}</td>
                                                                <td>{data?.phone}</td>
                                                                <td>{data?.movie}</td>
                                                                <td>{data?.cinema}</td>
                                                                <td>{data?.anotherFilm}</td>
                                                                <td>{data?.dateTime}</td>
                                                                {/* <td>{moment(data?.dateTime?.date).format('DD.MM.YYYY HH:mm:ss')}</td> */}
                                                                <td>{data?.technologie}</td>
                                                                <td>{data?.numberaccoPersons}</td>
                                                                <td>{data?.numberofStudents}</td>
                                                                <td>{data?.languageVersion}</td>
                                                                <td>{data?.furtherInfo}</td>
                                                                {/* <td><input type="checkbox" checked={data?.answered === true ? true : false} onChange={(event)=>handleCheckedAnswer(event,data)}/></td> */}
                                                                <td><input type="checkbox" checked={data?.answered === true ? true : false} /></td>
                                                                <td><button style={{ width: '90px' }} onClick={() => exportAsPdfData(data)}>Export Pdf</button></td>
                                                            </tr>)
                                                    })
                                                : <tr><td colSpan={22} style={{ textAlign: 'center' }}><h2>{t('common.no_data')}</h2></td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='row'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={dataList?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </>
                        }
                        {/* For schoolCinemaTeachingStaffRequest */}
                        {
                            defaultForm === "schoolCinemaTeachingStaffRequest" &&
                            <>
                                <div className='mt-3'>
                                    <Table bordered hover responsive className='admin_table'>
                                        <thead>
                                            <tr>
                                                <th>School Name</th>
                                                <th>Street</th>
                                                <th>Zip</th>
                                                <th>Town</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>School Phone Numer</th>
                                                <th>Title</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email </th>
                                                <th>Phone Number</th>
                                                <th>Movie</th>
                                                <th>Cinema Name</th>
                                                <th>Another Film</th>
                                                <th>Date Time</th>
                                                <th>Technologie</th>
                                                <th>Number Of Persons</th>
                                                <th>Number Of Students</th>
                                                <th>Language Version</th>
                                                <th>Further Info</th>
                                                <th>Answered</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataList?.length > 0 ?
                                                dataList?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                                    ?.map((data, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{data?.nameofschool}</td>
                                                                <td>{data?.street}</td>
                                                                <td>{data?.zip}</td>
                                                                <td>{data?.town}</td>
                                                                <td>{data?.state}</td>
                                                                <td>{data?.country}</td>
                                                                <td>{data?.schoolPhonenumer}</td>
                                                                <td>{data?.title}</td>
                                                                <td>{data?.firstname}</td>
                                                                <td>{data?.lastname}</td>
                                                                <td>{data?.mail}</td>
                                                                <td>{data?.phone}</td>
                                                                <td>{data?.movie}</td>
                                                                <td>{data?.cinema}</td>
                                                                <td>{data?.anotherFilm}</td>
                                                                <td>{data?.dateTime}</td>
                                                                {/* <td>{moment(data?.dateTime?.date).format('DD.MM.YYYY HH:mm:ss')}</td> */}
                                                                <td>{data?.technologie}</td>
                                                                <td>{data?.numberaccoPersons}</td>
                                                                <td>{data?.numberofStudents}</td>
                                                                <td>{data?.languageVersion}</td>
                                                                <td>{data?.furtherInfo}</td>
                                                                {/* <td><input type="checkbox" checked={data?.answered === true ? true : false} onChange={(event)=>handleCheckedAnswer(event,data)}/></td> */}
                                                                <td><input type="checkbox" checked={data?.answered === true ? true : false} /></td>
                                                                <td><button style={{ width: '90px' }} onClick={() => exportAsPdfData(data)}>Export Pdf</button></td>
                                                            </tr>)
                                                    })
                                                : <tr><td colSpan={22} style={{ textAlign: 'center' }}><h2>{t('common.no_data')}</h2></td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='row'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={dataList?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </>
                        }
                        {/*For newsLetterregistration */}
                        {
                            defaultForm === "newsLetterregistration" &&
                            <>
                                <div className='mt-3'>
                                    <Table bordered hover responsive className='admin_table'>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Salutation</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Address </th>
                                                <th>PostCode </th>
                                                <th>Location </th>
                                                <th>School Name </th>
                                                <th>Telephone</th>
                                                <th>School Group </th>
                                                <th>Email </th>
                                                <th>Send NewsLetter</th>
                                                {/* <th>Excel Export</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataList?.length > 0 ?
                                                dataList?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                                    ?.map((data, key) => {
                                                        return (
                                                            <>
                                                                <tr key={key}>
                                                                    <td>{data?.title}</td>
                                                                    <td>{data?.salutation}</td>
                                                                    <td>{data?.firstname}</td>
                                                                    <td>{data?.lastname}</td>
                                                                    <td>{data?.address}</td>
                                                                    <td>{data?.postcode}</td>
                                                                    <td>{data?.location}</td>
                                                                    <td>{data?.schoolname}</td>
                                                                    <td>{data?.telephone}</td>
                                                                    <td>{data?.schoolgroup}</td>
                                                                    <td>{data?.email}</td>
                                                                    <td><input type="checkbox" checked={(data?.sendnewsletter === true ? true : false)} /></td>
                                                                    {/* <td><button onClick={()=>exportAsPdfData(data)}>Export Pdf</button></td> */}
                                                                    {/* <td><input type="checkbox" checked={(data?.answered === true ? true : false)} onChange={(event)=>handleCheckedAnswerMovieRequest(event,data)}/></td> */}
                                                                </tr>
                                                            </>)
                                                    })
                                                : <tr><td colSpan={22} style={{ textAlign: 'center' }}><h2>{t('common.no_data')}</h2></td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                                <div className='row '>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={dataList?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </AdminLoginWrapper>

                    {/* for Downloading PDF */}
                    {
                        pdfData?.map((data, key) => {
                            return (
                                <WrapperPage ref={userData} key={key}>
                                    <RowPage>
                                        <HeaderContainer>
                                            <div>
                                                <div className="header-subcomponent">
                                                    <div className='right-box'>
                                                        <h2>{data?.cinema}</h2>
                                                    </div>
                                                    <div className='left-box'>
                                                        <h2 className='fw-bold'>Service-Information</h2>
                                                        <p>Reservieren/ Buchen Sie Ihre Schulervorstellung unter unserer Schulhotline per Mail oder uber
                                                            unsere Homepage!Hier konnen Sie sich auch anmelden, wenn Sie unsere Filmtipps fur Schulen, Kindergarten etc.
                                                            erhalten mochten:</p>
                                                        <ul>
                                                            <li><span>Hotline</span>: +43 (0664) 813 00 13</li>
                                                            <li>werktags Mo-Do 08:30-15:00, Fr 08:30-12:00</li>
                                                            <li><span>m@il</span>: <a className="link" href='mailto:schule@schulkino.at' alt='Link for Email' >schule@schulkino.at</a> </li>
                                                            <li><span>www</span>: <a className="link" href='www.schulkino.at' alt='Link for Website' target='_blank'>www.schulkino.at</a></li>
                                                            <li><span>Newsletter Anmeldung: folgen Sie dem</span> <a className="link" href='www.google.com' alt='Link for Website' target='_blank'>Link</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='header-cinemaInfo'>
                                                    <div className='cinema-detail'>
                                                        <ul>
                                                            <li>AN/TO: <span className='fw-bold'>Stiftsgymnasium Seitenstetten</span> / Fr.Schmutzer</li>
                                                            <li>DATUM/DATE:{moment.utc(data?.dateTime?.date).format("dddd, D. MMMM YYYY")}</li>
                                                        </ul>
                                                    </div>
                                                    <div className='contact-detail'>
                                                        <ul>
                                                            <li>Tel: {data?.phone}</li>
                                                            <li>Mail: <a className="link fw-light" href='mailto:xyz@gmail.com' alt='Link for Email' >{data?.mail}</a></li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <hr className='hr-tag mt-0' />
                                            </div>
                                        </HeaderContainer>
                                        <MovieInfoContainer>
                                            <div className='movie-info '>
                                                <h2 className='fw-bold'>BESTATIGUNG GRUPPENBUCHUNG  ,,Hilfe, ich hab meine Eltern geschrumpft</h2>
                                                <p className='normal-text'>Sehr geehrte Frau Schmutzer,</p>
                                                <p className='normal-text'>anbei finden Sie alle Details zu der von Ihnen bestellen Vorstellung:</p>
                                                <ul>
                                                    <li><label>Termin:</label><span className='bold'>{moment.utc(data?.dateTime?.date).format("dddd MMM Do, YYYY")}</span></li>
                                                    <li><label>Zeit:</label> <span className='bold'>{moment.utc(data?.dateTime?.date).format("h:mm")}</span></li>
                                                    <li><label>Film:</label><span><span className='normal-text'>{data?.movie}</span>
                                                        <p className='normal-text'>(Lange Hauptfilm: ca.99 Minuten, Altersfreigabe: ab 6 Jahre)</p></span></li>
                                                    <li><label>Kino:</label><span><span className='bold'>{data?.cinema}</span><span className='normal-text'>(Waidhofner Strabe 42b, 3300 Amstetten-Greinsfurth)</span></span></li>
                                                </ul>
                                                <hr className='hr-tag' />
                                            </div>
                                        </MovieInfoContainer>
                                        <PriceInfo>
                                            <div className='price-info'>
                                                <ul>
                                                    <li><label>Anzahl der Schulerlnnen:</label><span className='normal-text'>~40 - 45 Kinder + Begleitpersonen </span></li>
                                                    <li><label>Verrechnung:</label><span><span className='bold'>
                                                        <p><span className="euro">€ 6,-/ </span> Kind (ab 10 Kinder)</p>
                                                        <p>€ 60, -/ Kleingruppenpauschale(wenn unter 10 Kinder)</p>
                                                    </span><p className='normal-text'>Auf 10 kinder geht eine Begleitperson frei</p>
                                                    </span></li>
                                                    <li><label>Ablauf Vorstellung:</label><span className='bold'>
                                                        <p className='mb-2'>Es wird in einer offentlichen Vorstellung des regularen Programms fur lhre Gruppe ein Kartenkontingent reserviert.Bitte holen Sie die Karten bis zumindest 15 Minuten vor Filmbeginn ab!</p>
                                                        <p>Bitte das Geld vor der Vorstellung einsammeln und an der kassa ubergeben! Alternativ ist auch die Komplettzahlung mit Kredit- oder Bankomatkarte moglich.</p>
                                                    </span></li>
                                                </ul>
                                                <p className='bold bottom_info'>WIR WUNSCHEN IHNEN EIN UNVERGESSLICHES KINOERLEBNIS!</p>
                                                <p className='mb-0 bold'>Matthias Pfeiffer</p>
                                                <p className='normal-text'>Buchung Kinder- & Schulvorstellungen</p>
                                            </div>
                                            <div className='info-das-box'>
                                                <p className='headline'>Info an das Kino:</p>
                                                <p className='normal-text'>Bitte nehmen Sie die Reservierung in der regularen Vorstellung vor!</p>
                                                <p className='normal-text'>Die Karten sind unter XXX reserviert</p>
                                            </div>
                                        </PriceInfo>
                                        <div className='footer-info'>
                                            <div className='logo'>
                                                <img src={logo} alt='School Logo' />
                                            </div>
                                        </div>
                                    </RowPage>
                                </WrapperPage>
                            )
                        })
                    }
                </div>
            </section>
            {isLoading && <Loader />}
        </>

    )
}

AdminDetailList.propTypes = propTypes;

export default AdminDetailList;