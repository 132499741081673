import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import ArchiveFilmList from './ArchiveFilmList.component';
import layoutModule from '../../../store/layout';

const {
    requestArchiveMovies,
    requestStateList
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getArchiveMovies: requestArchiveMovies,
    getStateList: requestStateList,
    getLocations: requestLocations,
};

const mapStateToProps = ({ movies, layout }) => ({
    films: movies.films_archive,
    archiveDates: movies.archiveDates,
    states: movies.states,
    shortList: movies.shortList,
    isLoading: layout.isLoading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArchiveFilmList);





