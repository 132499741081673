import styled from 'styled-components';

export const CarouselBlock = styled.div.attrs({
    className: "carousel-block"
})`
    width: 640px;
    height: 360px;
    background: linear-gradient(180deg, #C9A491 0%, rgba(243, 206, 151, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .carousel-block__images {
        display: flex;
        width: 384px;
        justify-content: center;
        align-items: center;
        padding: 17px;
        background: #ffffff;
        .image {
            width: 80px;
            height: 116px;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .carousel-block__footer {
        width: 384px;
        padding: 13px 10px;
        background: #ffffff;
        margin-top: 10px;
        h3 {
            text-align: left;
            font-weight: 700;
            font-size: 28px;
            line-height: 35px;
            color: #000000;
            margin: 0 0 2px 0;
        }
        .subtitle {
            display: flex;
            justify-content: space-between;
            h4 {
                text-align: left;
                font-weight: 400;
                font-size: 15px;
                line-height: 17px;
                color: #000000;
                margin: 0;
            }
            .moreBtn {
                border: 1px solid #000000;
            }
        }
    }
`;

export const CarouselBlockRIght = styled.div.attrs({
    className: "carousel-block"
})`
    width: 640px;
    height: 360px;
    background: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

export const ArrowButton = styled.button`
    background: #E6E5E6;
    border: 2px solid #111111;

    &:hover {
        background: #f58220;
    }
`;

export const CardLI = styled.li`
vertical-align: top;
list-style: none;
display: inline-block;
outline: 0;

&:hover{
    .figure__caption{
        background:gray;
        color:#fff;
        font-size: 12px;
        font-family: "Arvo",Arial,Verdana;
    }
}

.poster-title {
    padding: 5px;
    text-align: center;
    background-color: #e9e9e9;
}

.filmtitel, .startdatum {
    position: relative;
    text-transform: none;
    font-family: "Arvo",Arial,Verdana;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: black;
    white-space: normal;
    margin-bottom: 0px;
}
.startdatum {
    font-weight: 700;
}

.figure{
    overflow:hidden;

    &__link{
        text-decoration:none;
        display:flex;
        flex-direction:column;

        img{
            transition : all 0.5s;
        }
    }
    &__caption{
        transition : all 0.5s;
        position:relative;
        z-index;2;
        color:black;
        background-color:lightgray;
        text-align:center;
        text-decoration:none;
    }

    &:hover{
        img{
            scale:1.05;
        }
    }
}
`;

export const PosterWrapper = styled.li`
list-style: none;
h2 {
    text-transform: none;
    font-family: "Arvo",Arial,Verdana;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: black;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    display: inline-block;
    margin-bottom: 30px;
}
`;

export const SideBar = styled.main`
    padding: 20px 40px 20px 40px;
    width: 25%;
    float:left;
    background-color: #e9e9e9;
    h3 {
        font-family: 'Arvo';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
    }
    .clearfix:after {
        clear: both;
        display: table;
        content: "";
    }
    @media (max-width: 991px) {
        width: 30%;
        padding: 10px;
        h3 {
            font-size: 18px;
        }
    }
    @media (max-width: 767px) {
        width:100%;
        float: none;
    }
`;

export const RightList = styled.main`
    width: 75%;
    float: right;
    padding: 20px;
    background-color: #ffffff;
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/overview_bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 25%;
        vertical-align: top;
    }
    @media (max-width: 991px) {
        width: 70%;
    }
    @media (max-width: 767px) {
        .row {
            margin: 0;
        }
    }
`;

export const CheckBoxes = styled.div`
width: 100%;
float: left;
`;
export const CheckBoxeDiv = styled.div`
    position: relative;
    width: 70%;
    height: 15px;
    cursor: pointer;
    float: left;
    margin: 10px 0 5px 0;
    padding: 3px 0 0 0;
    display: flex;
    .selection {
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 20px;
        padding-left: 10px;
        color: black;
        width: 100%;
    }
`;

export const SortElementsDiv = styled.div`
    margin-top: 25px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    p.hinweis {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: black;
        padding: 7px;
        margin-top: 10px;
        text-align: center;
        background-color: #ffffff;
        width: 100%;
    }
    span.alphabet_div {
        font-weight: 600;
    }
`;