import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import CampaignsPage from './CampaignsPage.component';

const {
    requestCampaingn,
    requestCampaignsList,
    requestTopMovies,
    requestMovieDetail
} = movieModule.actions;

const mapDispatchToProps = {
    fetchCampaigns: requestCampaingn,
    fetchCampaignsList: requestCampaignsList,
    getTopMovies: requestTopMovies,
    getMovieDetail: requestMovieDetail,
};

const mapStateToProps = ({ movies }) => (
    {
        data: movies.data,
        dataList: movies.dataList,
        films: movies.films,
        filmsList: movies.filmsDetail,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsPage);





