import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Svg from '../Svg';

const propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
}

const defaultProps = {
    src: '',
    alt: ''
}

const ImageWithLoader = ({ src, alt }) => {
    const [isLoaded, setStatusLoad] = useState(true);
    // const [isLoadError, setStatusError] = useState(false);
    // const onLoadImage = useCallback(() => setStatusLoad(true), []);
    // const onErrorImage = useCallback(() => setStatusError(true), []);

    const ImgBox = styled.span`
    position:relative;
    width:100%;

    img{
        position:relative;
        height:100%;
        width:100%;
        object-fit:contain;
        z-index:0;
        transition:all 500ms;
    }
    `;

    useEffect(() => {
        setStatusLoad(false);
    }, [src]);

    return (
        <ImgBox>
            {isLoaded && <Svg />}
            <img
                src={src}
                alt={alt}
                className="image"
            // onLoad={onLoadImage}
            // onError={onErrorImage}
            />
        </ImgBox>
    )
}

ImageWithLoader.propTypes = propTypes;
ImageWithLoader.defaultProps = defaultProps;

export default ImageWithLoader;
