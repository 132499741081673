import {
    mergeMap,
    startWith,
    concatMap,
    catchError,
} from "rxjs/operators";
import {
    // forkJoin,
    from,
    of
} from 'rxjs';
import { ofType } from 'redux-observable';
import {
    setLoader,
    setErrorApi,
    separateErrors,
    // authErrorHandleAfterManualCatch,
    handleErrors,
    handleUnknownError,
    createToast
} from './deps';
import {successForSendNewsletterFormData,successRegistrationData,successForDeregistrationNewsletter,successRequestSchoolCinemaNewsLetterData} from './actions';
import {SEND_NEWSLETTER_FORM_DATA,ACTIVATE_REQUEST,DEREGISTRATION_NEWSLETTER,GET_NEWSLETTER_REQUEST_DATA} from './types';

function sendNewsletterFormDataFunction($action, $state, { api }) {
        const $api = api.getModuleByName('newsletter');
        return $action.pipe(
            ofType(SEND_NEWSLETTER_FORM_DATA),
            mergeMap(action => {
                const { params } = action.payload;
                return from($api.sendNewsletterFormData(params))
                    .pipe(
                        concatMap(res => {
                            const { data, errors, hasErrors } = separateErrors(res);
                            return [
                                successForSendNewsletterFormData(data),
                                setErrorApi(hasErrors ? errors : null),
                                setLoader(false)
                            ];
                        }),
    
                        ...handleErrors(action),
    
                        startWith(setLoader(true))
                    );
            })
        );
    }
function activate($action, $state, { api }) {
const $api = api.getModuleByName('newsletter');

return $action.pipe(
    ofType(ACTIVATE_REQUEST),
    mergeMap(({ payload: { status, email } }) =>
    from($api.activate(status, email)).pipe(
        concatMap((res) =>{ 
            const {data, errors, hasErrors} = separateErrors(res);
            // console.log("data_epics_newsLetter",data);
            return [
                successRegistrationData(data),
                setErrorApi(hasErrors ? errors : null),
                createToast('info', 'toasters.user_activation'), 
                setLoader(false)
            ]
        }),

        catchError(handleUnknownError),
        catchError(() =>
        of(createToast('warning', 'toasters.user_activation_failed'), setLoader(false))
        ),

        startWith(setLoader(true))
    )
    )
);
}

function deregistrationNewsletterFunction($action, $state, { api }) {
    const $api = api.getModuleByName('newsletter');
    return $action.pipe(
        ofType(DEREGISTRATION_NEWSLETTER),
        mergeMap(action => {
            const { deleteuser, email } = action.payload;
            return from($api.sendEmailForDeregistration(deleteuser, email))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        // console.log("data_inside_epic",data);
                        return [
                            successForDeregistrationNewsletter(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}
function getSchoolCinemaNewsLetterRequestData($action, $state, { api }) {
    const $api = api.getModuleByName('newsletter');
    return $action.pipe(
        ofType(GET_NEWSLETTER_REQUEST_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            // console.log("params-----",params);
            return from($api.getRequestNewsLetterData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successRequestSchoolCinemaNewsLetterData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}


    export {        
    sendNewsletterFormDataFunction,
    activate,
    deregistrationNewsletterFunction,
    getSchoolCinemaNewsLetterRequestData
    };