import {
    LOADING,
    MODAL,
    SET_ERROR,
    CLEAR_ERROR,
    CREATE_TOAST,
    REMOVE_TOAST,
    SET_ERROR_API,
    CLEAR_ERROR_API,
    SUCCESS_LOCATIONS,
    SET_ELEMENTS_VISIBILITY
  } from './types';
  
  const uid = () =>
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substr(2);
  
  const initialState = {
    isLoading: false,
    error: null,
    apiError: null,
  
    modals: {
      forgotPassword: false,
      forgotPasswordSuccess: false,
      accountCreated: false,
      carousel: false,
      emailConfirmation: false,
      seatPlanLegend: false,
      deleteAccount: false,
      errorConfirm: false,
      mobileGiftcard: false
    },
  
    toasters: [],
  
    locations: [],
  
    elementsVisibility: { header: true, footer: true }
  };
  
  const toasters = (t, state, { id, type, message }) => {
    switch (t) {
      case CREATE_TOAST: {
        return [
          {
            id: uid(),
            type,
            message
          }
        ];
      }
  
      case REMOVE_TOAST: {
        return state.filter(toast => toast.id !== id);
      }
  
      default: {
        return state;
      }
    }
  };
  
  const modal = (state, { name, visible }) => ({
    ...state,
    [name]: visible
  });
  
  export default function(state = initialState, { type, payload }) {
    switch (type) {
      case LOADING: {
        return {
          ...state,
          isLoading: payload.val
        };
      }
  
      case SET_ERROR: {
        return {
          ...state,
          error: {
            code: payload.code,
            message: payload.message,
            error: payload.error
          }
        };
      }
  
      case CLEAR_ERROR: {
        return {
          ...state,
          error: null
        };
      }
  
      case MODAL: {
        return {
          ...state,
          modals: modal(state.modals, payload)
        };
      }
  
      case CREATE_TOAST:
      case REMOVE_TOAST: {
        return {
          ...state,
          toasters: toasters(type, state.toasters, payload)
        };
      }
  
      case SET_ERROR_API: {
        return {
          ...state,
          apiError: payload.error
        };
      }
  
      case CLEAR_ERROR_API: {
        return {
          ...state,
          apiError: null
        };
      }
  
      case SUCCESS_LOCATIONS: {
        return {
          ...state,
          locations: payload.locations
        };
      }
  
      case SET_ELEMENTS_VISIBILITY: {
        return {
          ...state,
          elementsVisibility: payload.elementsVisibility
        };
      }
  
      default: {
        return state;
      }
    }
  }
  