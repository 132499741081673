import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import newsletterModule from '../../../store/newsletter';
import AdminDetailList from './AdminDetail.component';

const {
    requestSchoolCinemaData,
    requestSchoolCinemaCampaignData,
    requestSchoolCinemaCampaignOtherData,
    requestForSendFormData,
    requestForSendCampaignFormData
} = movieModule.actions;
const {
    requestSchoolCinemaNewsLetterData
} = newsletterModule.actions;
const mapDispatchToProps = {
    getRequestData: requestSchoolCinemaData,
    getCampaignRequestData: requestSchoolCinemaCampaignData,
    getCampaignOtherRequestData: requestSchoolCinemaCampaignOtherData,
    sendFormData: requestForSendFormData,
    sendCampainFormData: requestForSendCampaignFormData,
    getNewsLetterRequestData: requestSchoolCinemaNewsLetterData,
};

const mapStateToProps = ({ movies, layout, newsletter }) => ({
    requestData: movies.requestData,
    requestDataNewsLetter: newsletter.requestData,
    isLoading: layout.isLoading,
    output: movies.output,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminDetailList);






