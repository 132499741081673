/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Form, TextBox } from 'react-form-elements';
import { useTranslation } from 'react-i18next';
import 'react-pagination-bar/dist/index.css';
import { AdminLoginWrapper } from '../../../containers/adminLogin/styles';
import { SchoolactionFormwrapper } from '../../../containers/newsLetter/styles';
import { useNavigate } from 'react-router';

const AdminLoginForm = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [FormError, setFormError] = useState([]);
    const [formData, updateFormData] = useState({});
    const errors = {};


    const hasFormSubmit = (e) => {
        e.preventDefault();

        // eslint-disable-next-line no-useless-escape
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (formData.email === null || formData.email === undefined || formData.email === "") {
            errors.email = t('form.errors.required')
        } else if (!formData?.email?.match(mailformat)) {
            errors.email = t('form.errors.email')
        }


        var passwordformat = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (formData.password === null || formData.password === undefined || formData.password === "") {
            errors.password = t('form.errors.required')
        } else if (!formData?.password?.match(passwordformat)) {
            errors.password = t('form.errors.password')
        }

        setFormError(errors);
        if (Object.keys(errors)?.length === 0) {
            console.log("submit")
            navigate("/admin-detail")
        }
    }

    const handleChange = async (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    }

    return (
        <>
            <section className='container'>
                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4'>
                        <AdminLoginWrapper>
                            <div className='adminLogin_heading'>
                                <h2>{t('form.admin_login')}</h2>
                            </div>
                            <div className='adminLogin_wrapper'>
                                <Form id="form_container"
                                    name="form_sub"
                                >
                                    <SchoolactionFormwrapper>
                                        <div className='row'>
                                            <div>
                                                <TextBox
                                                    label={t('form.email')}
                                                    name="email"
                                                    className="form-input"
                                                    onChange={handleChange}
                                                    id="email"
                                                    value={formData.email}
                                                />
                                                <p className='error m-0'>{FormError.email}</p>

                                            </div>
                                            <div>
                                                <TextBox
                                                    label={t('form.password')}
                                                    name="password"
                                                    className="form-input"
                                                    onChange={handleChange}
                                                    id="password"
                                                    value={formData.password}
                                                />
                                                <p className='error m-0'>{FormError.password}</p>

                                            </div>
                                            <div className='col-lg-9'>
                                                <div className='form-input mobile_response'>
                                                    <label className='w-100 mb-3'></label>
                                                    <input className="form-input-button" type="submit" id="subscribeSubmitButton" onClick={hasFormSubmit} value={t('form.login')} />
                                                </div>
                                            </div>
                                        </div>
                                    </SchoolactionFormwrapper>

                                </Form>
                            </div>
                        </AdminLoginWrapper>
                    </div>
                    <div className='col-4'></div>
                </div>
            </section>
        </>

    )
}

export default AdminLoginForm;