import styled from 'styled-components';

export const DetailsHeader = styled.main`
position: relative;
    background-color: #e9e9e9;
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/details_bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flow-root;
    padding: 20px;
`;

export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;

export const DetailWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: 15px;
`;
export const DetailMainWrapper = styled.div`
position: relative;
    float: left;
    width: 100%;
    background-color: #f6f2f0;
`;
export const DetailsPoster = styled.div`
position: relative;
    float: left;
    width: 241px;
    height: 342px;
    img.poster {
        width: 100%;
        height: auto;
        display: block;
    }
    img.placeholder_img {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 768px){
        width: 210px;
        height: 300px;
    }
    @media (max-width: 711px) and (min-width: 676px){
        width: 190px;
        height: 270px
    }
    @media (max-width: 671px) and (min-width: 660px){
        width: 190px;
        height: 270px
    }
    @media (max-width: 661px) {
        width: 200px;
    }
    @media (max-width: 601px){
        width: 100%;
        img.image {
            width: 100%;
        }
    }
`;

export const DetailsText = styled.div`
    position: relative;
    float: left;
    width: calc(100% - 241px - 32.5%);
    padding: 20px;
    margin-left: 1%;
    h1.details-title {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        color: black;
        background-color: transparent;
        display: inline-block;
    }
    h3.details-releasedate {
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        color: #ff7500;
        margin-bottom: 5px;
    }
    p.details-copytext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: black;
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;
        border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    p.details-copytext p {
        display: initial;
    }
    .info_set{
        position: absolute;
        bottom: 15%;
        right: 5%;
        height: 20px;
        width: 20px;
    }
    button.age_button {
        height: 54px;
        width: 180px;        

    }
    
    .info_set {
        display: inline-block;
        vertical-align: sub;
        margin-left: ${({ theme }) => theme.indent.s};
        width: 18px;
        height: 18px;
    
        &:hover {
          .svg-question-mark {
            opacity: 1;
          }
    
          & + .b-entity-info__age-text {
            opacity: 1;
            transform: translateX(-50%) scale(1);
            span.age_title {
              display: inline !important;
            }
          }
        }
    }
    p.details-copytext b {
        text-transform: uppercase;
        font-weight: 700;
    }
    p.details-copytext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: black;
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;
        border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    p.details-copytext b {
        text-transform: uppercase;
        font-weight: 700;
        margin-right: 5px;
    }
    @media (max-width: 768px) {
        width: calc(100% - 232px - 31%);
        padding: 10px;
        h3.details-links-black {
            font-size: 17px;
        }
    }
    @media (max-width: 767px) and (min-width: 662px) {
        padding: 10px;
        p.details-copytext {
            font-size: 12px;
        }
    }
    @media (max-width: 711px) and (min-width: 676px){
        width: calc(100% - 227px - 28%);
    }
    @media (max-width: 671px) and (min-width: 660px){
        width: calc(100% - 209px - 28%);
    }
    @media (max-width: 661px){
        width: calc(100% - 197px - 32.5%);
        padding: 10px;
        h1.details-title {
            font-size: 18px;
        }   
    }
    @media (max-width: 601px){
        width: 100%;
    }
    @media(max-width: 601px){
        .example-container{
            display: flex;
            justify-content: center;
            padding-right: 10px;
        }
        .age_button{
            border: 1px solid black;
            color: black;
        }
    }
`;

export const DetailsLinkwrapper = styled.div`
    display: block;
    background-color: #e3c9b2;
    padding-left: 0.4%;
    position: relative;
    float: left;
    width: 31.5%;
    margin-top: 0;
    .flat-button-details {
        width: 70%;
        position: relative;
        display: block;
        text-align: center;
        line-height: 32px;
        padding: 0 10px 0 10px;
        margin: 0px auto;
        color: black;
        background-color: #e9e9e9;
        border: 1px solid #000000;
        cursor: pointer;
        pointer-events: auto;
        @media (max-width: 767px) {
            p {
                font-size: 14px;
            }
        }
        @media (max-width: 661px) {
            p {
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
    @media (max-width: 768px) {
        width: 33%;
        h3.details-links {
            font-size: 17px;
        }
    }
    @media (max-width: 671px){
        width: 30%;
    }
    @media (max-width: 661px){
        width: 30%;
    }
    @media (max-width: 601px){
        width: 100%;
    }
`;

export const DetailsLinkAnfrage = styled.div`   
    background-color: #ff7500;
    display: block;
    padding-left: 0.4%;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 0 0;
    min-height: 114px;
    padding: 23px 20px 16px 20px;
    h3.details-links {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 5px;
    }
    @media (max-width: 768px) and (min-width: 737px) {
        min-height: 100px;
        padding: 15px 20px 16px 20px;
        h3.details-links {
            font-size: 17px;
        }
        .flat-button-details p.m-0 {
            font-size: 12px;
        }
    }
    @media (max-width: 736px) and (min-width: 712px) {
        min-height: 100px;
        padding: 15px 20px 16px 20px;
        h3.details-links {
            font-size: 16px;
        }
        .flat-button-details p.m-0 {
            font-size: 12px;
        }
    }
    @media (max-width: 711px) and (min-width: 672px) {
        min-height: 90px;
        padding: 10px 20px 15px 20px;
        h3.details-links {
            font-size: 15px;
        }
        .flat-button-details p.m-0 {
            font-size: 11px;
        }
    }
    @media (max-width: 671px) and (min-width: 601px) {
        min-height: 90px;
        padding: 10px 20px 15px 20px;
        h3.details-links {
            font-size: 13px;
        }
        .flat-button-details p.m-0 {
            font-size: 8px;
        }
    }
    @media (max-width: 600px) {
        min-height: 117px;
        h3.details-links {
            font-size: 17px;
        }
    }
    @media (max-width: 661px) {
        min-height: 100px;
        padding: 15px 20px 16px 20px;
        h3.details-links{
            font-size: 11px;
        }
    }
`;

export const DetailsLinkVideo = styled.div`
    margin-top: 1.3%;
    background-color: #e5e5e5;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 0 0;
    min-height: 114px;
    padding: 23px 20px 16px 20px;
    h3.details-links-black {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        color: black;
        margin-bottom: 5px;
    }
    a.disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        pointer-events: none;
    }
    @media (max-width: 768px) and (min-width: 737px) {
        min-height: 100px;
        padding: 15px 20px 16px 20px;
        h3.details-links-black {
            font-size: 17px;
        }
        .flat-button-details p.m-0 {
            font-size: 12px;
        }
    }
    @media (max-width: 736px) and (min-width: 672px) {
        min-height: 100px;
        padding: 15px 20px 16px 20px;
        h3.details-links-black {
            font-size: 16px;
        }
        .flat-button-details p.m-0 {
            font-size: 12px;
        }
    }
    @media (max-width: 671px) and (min-width: 601px) {
        min-height: 90px;
        padding: 10px 20px 15px 20px;
        h3.details-links-black {
            font-size: 13px;
        }
        .flat-button-details p.m-0 {
            font-size: 8px;
        }
    }
    @media (max-width: 600px) {
        min-height: 117px;
    }
    @media (max-width: 661px) {
        min-height: 100px;
        padding: 20px 20px 16px 20px;
        h3.details-links-black, h3.details-links {
            font-size: 11px;
        }
    }
`;

export const DetailsLinkUnterricht = styled.div`
    margin-top: 1.3%;
    background-color: #989898;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 0 0;
    min-height: 114px;
    padding: 23px 20px 16px 20px;
        h3.details-links {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 18px;
            line-height: 26px;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 5px;
        }
        p.no-mat {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #000000;
        }
        @media (max-width: 768px) and (min-width: 737px) {
            min-height: 100px;
            padding: 15px 20px 16px 20px;
            h3.details-links {
                font-size: 17px;
            }
            .flat-button-details p.m-0 {
                font-size: 12px;
            }
        }
        @media (max-width: 736px) and (min-width: 672px) {
            min-height: 100px;
            padding: 15px 20px 16px 20px;
            h3.details-links {
                font-size: 16px;
            }
            .flat-button-details p.m-0 {
                font-size: 12px;
            }
        }
        @media (max-width: 671px) and (min-width: 601px) {
            min-height: 90px;
            padding: 10px 20px 15px 20px;
            h3.details-links {
                font-size: 13px;
            }
            .flat-button-details p.m-0 {
                font-size: 8px;
            }
        }
        @media (max-width: 600px) {
            min-height: 117px;
        }
        @media (max-width: 661px) {
            min-height: 100px;
            padding: 20px 20px 16px 20px;
            h3.details-links {
                font-size: 11px;
            }
        }
`;

export const DetailStory = styled.div`
    padding: 20px;
    width: 100%;
    float: left;
    h2.story-hl {
        position: relative;
        display: inline-block;
        width: 100%;
        float: left;
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        background-color: transparent;
        padding: 10px 0px 10px 0px;
        display: inline-block;
    }
    p.storytext {
        position: relative;
        display: inline-block;
        width: 60%;
        text-align: justify;
        padding-bottom: 20px;
        float: left;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    .movie-image {
        // position: relative;
        // display: inline-block;
        width: 36%;
        // margin-left: 4%;
        // margin-top: 25px;
        // float: left;
        // height:60%;
        .EUYlogo{
            width: 30%;
            position: relative;
            right: 12px;
            top: 10px;
        }
        @media (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            .EUYlogo{
                right: 8px;
            }
        }
    }
    .movie-image img {
        width: 100%;
        display: block;
    }
    @media (max-width: 767px) {
        padding: 10px;
    }
`;

export const DetailTrailer = styled.div`
    padding: 20px;
    width: 100%;
    float: left;
    h2.page-subline-transparent {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        background-color: transparent;
        padding: 10px 0px 10px 0px;
        display: inline-block;
    }
    button.b-trailer__btn-play {
        position: absolute;
        top: 50%;
        background: transparent;
        border: none;
    }
    @media (max-width: 767px) {
        padding: 10px;
    }
`;

export const MovieGallery = styled.div`
    padding: 20px;
    width: 100%;
    float: left;
    h2.page-subline-transparent {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        background-color: transparent;
        padding: 10px 0px 10px 0px;
        display: inline-block;
    }
    @media (max-width: 767px) {
        padding: 10px;
        .carousel.carousel-slider .control-prev, .carousel.carousel-slider .control-next {
            width: 30px;
            height: 30px;
            // background-repeat: round;
            background-repeat: no-repeat;
            top: 40%;
        }
    }
`;

export const DetailPopupContent = styled.div`
    width: 100%;
    min-height: 200px;
    background-color: #e9e9e9;
    padding: 20px;
    h2.page-subline-white {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        background-color: #ffffff;
        padding: 10px 20px 10px 20px;
        display: inline-block;
        margin-bottom: 0px;
    }
    .umat-item {
        position: relative;
        width: 100%;
        height: 40px;
        background-color: #ffffff;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        .umat-item-title {
            position: relative;
            width: 60%;
            float: left;
        }
        .umat-item-button {
            position: relative;
            width: 15%;
            float: left;
            padding: 3px 15px 0 10px;
            min-width: 140px;
        }
        .umat-item-format {
            position: relative;
            width: 15%;
            float: left;
        }
        .flat-button {
            position: relative;
            display: block;
            text-align: center;
            line-height: 32px;
            padding: 0 10px 0 10px;
            margin: 0px auto;
            color: black;
            background-color: #e9e9e9;
            border: 1px solid #000000;
            cursor: pointer;
            pointer-events: auto;
        }
        .umat-item-title p {
            padding-left: 10px;
            font-size: 12px;
        }
    }
    @media (max-width: 500px) {
        .umat-item{
            height: auto;
            padding: 10px 20px 20px;
        }
    }
`;
