import React from "react";
import styled from "styled-components";
import AdminDetailList from "../../components/common/AdminDetail/index";
// import { WrapperPage,RowPage } from "../adminLogin/styles";

const RowPage = styled.main`
    width: 100%;
    float: left;
    background-color: #e9e9e9;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px;    
}`;

const WrapperPage = styled.main`
    max-width: 100%;
    margin: auto;
    width: 100%;
    height: 100vh;
`;
const AdminDetail = () => {
    return (
        <WrapperPage>
            <RowPage>
                <AdminDetailList />
            </RowPage>
        </WrapperPage>
    );
};

export default AdminDetail;

