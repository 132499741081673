import { connect } from "react-redux";
import SearchMovies from "./SearchMovies.component";
import searchModule from "../../../store/search";
import movieModule from "../../../store/movies";

const { searchRequest } = searchModule.actions;

const { requestArchiveMovies, requestFetchUpcomingMovies, requestTopMovies } =
  movieModule.actions;

const mapDispatchToProps = {
  findMovie: searchRequest,
  getArchiveMovies: requestArchiveMovies,
  getUpComingData: requestFetchUpcomingMovies,
  getTopMovies: requestTopMovies,
};

const mapStateToProps = ({ search, movies }) => ({
  movies: search.movies,
  films: movies.films_archive,
  filmsUpcoming: movies.up_coming_films,
  sclCinemaFilm: movies.films
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMovies);
