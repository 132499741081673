import React from "react";
import { Provider } from 'react-redux';
import {
    BrowserRouter, Route, Routes
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import ConfigContext from './context/config';
import config from '../config';
import API from './services/api';
import storage from './services/Storage';
import configureStore from './configureStore';
// Layout
import DefaultLayout from './containers/layouts/default';
// pages
import Home from "./containers/home";
import Aktuelle from "./containers/aktuelle";
import MovieDetail from "./containers/movieDetail";
import MovieSubDetail from "./containers/movieSubDetail";
import NewsLetterPage from "./containers/newsLetter";
import UpComingMovie from "./containers/upComingMovie";
import ContactPage from "./containers/contact";
import FilmArchive from "./containers/filmarchive";
import Campaigns from "./containers/campaigns";
import CampaignsDetail from "./containers/CampaignsDetail";
import TopSchoolMovies from "./containers/topSchoolMovies";
import TopFilmTips from "./containers/topFilmTips";
import SchoolLandingPage from "./containers/landing";
// import MovieRequest from "./components/common/FilmSubDetail/MovieRequest.component";
import PageNotFound from "./containers/pageNotFound";
import CookieBot from "react-cookiebot";
import AdminLogin from "./containers/adminLogin";
import AdminDetail from "./containers/adminDetail";
import NewsLetterRegistrationPage from "./containers/newsLetterRegistrationPage";
import NewsLetterDeRegistrationPage from './containers/newsLetterDeRegistrationPage';
import PDFExport from "./components/PDFExport";
import SearchMovieBar from "./containers/SearchMovieBar";
import TagManager from "react-gtm-module";

function App() {
    const {
        gtmId,
        domainGroupId,
    } = config;
    const api = new API(config, storage);
    const store = configureStore(config, api);

    const tagManagerArgs = {
        gtmId
    };
    console.log("gtmId>>>", gtmId);
    TagManager.initialize(tagManagerArgs);

    window.onload = function () {
        return <CookieBot domainGroupId={domainGroupId} />
    }

    return (
        <div className="App">
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <ConfigContext.Provider value={config}>
                        <BrowserRouter>
                            <Routes>
                                <Route element={<DefaultLayout />} >
                                    <Route exact path="/" element={<Home />} />
                                    <Route exact path="/suche" element={<SearchMovieBar />} />
                                    <Route exact path="/aktuelle-filme" element={<Aktuelle />} />
                                    <Route exact path="/upcoming" element={<UpComingMovie />} />
                                    {/* <Route exact path="/movie-detail" element={<MovieDetail />} /> */}
                                    <Route exact path="/film-details/:id" element={<MovieDetail />} />
                                    <Route exact path="/newsletter" element={<NewsLetterPage />} />
                                    <Route exact path="/newsletter/registration" element={<NewsLetterRegistrationPage />} />
                                    <Route exact path="/newsletter/deregistration" element={<NewsLetterDeRegistrationPage />} />
                                    <Route exact path="/anfragen" element={<MovieSubDetail />} />
                                    <Route exact path="/about/:type" element={<ContactPage />} />
                                    <Route exact path="/film-archive" element={<FilmArchive />} />
                                    <Route exact path="/campaigns" element={<Campaigns />} />
                                    <Route exact path="/campaigns-detail/:id" element={<CampaignsDetail />} />
                                    <Route exact path="/top-school-movies" element={<TopSchoolMovies />} />
                                    <Route exact path="/top-film-tips" element={<TopFilmTips />} />
                                    <Route exact path="/admin" element={<AdminLogin />} />
                                    <Route exact path="/admin-detail" element={<AdminDetail />} />
                                    <Route exact path="/PDF" element={<PDFExport />} />
                                    <Route path="*" element={<PageNotFound />} />
                                    <Route path="/sonderaktion/:slug" element={<SchoolLandingPage />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </ConfigContext.Provider>
                </ThemeProvider>
            </Provider>
        </div>
    );
}

export default App;
