import PropTypes from 'prop-types';
import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const propTypes = {
    excelData: PropTypes.array,
    fileName: PropTypes.string.isRequired,
};

const Excelexport = ({ excelData, fileName }) => {

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = (excelData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
            <button type="button" className="b-trailer__btn-play p-1" onClick={() => exportToExcel(excelData, fileName)} >
                Excel Export
            </button>
    )
}

Excelexport.propTypes = propTypes;

export default Excelexport;