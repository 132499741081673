import React, { useState } from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosClose } from "react-icons/io";
import { AnimateGroup } from 'react-animation';
import { Link } from "react-router-dom";
import logo from '../../../assets/media/Schulkino_Logo.png';
import "./navbar.css";
import { useTranslation } from 'react-i18next';


const Navbar = () => {
    const { t } = useTranslation();
    const [toggleMenu, setToggleMenu] = useState(false);
    const [hoverSearch, setHoverSearch] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleSearchTextChange = event => {
        setSearchText(event.target.value);
    }

    // const handleOnMouseEnterSearch = e => {
    //     e.preventDefault();
    //     setHoverSearch(true);
    // }

    const handleOnMouseLeaveSearch = e => {
        e.preventDefault();
        if (searchText === '') {
            setHoverSearch(false)
        }
    }

    return (
        <section>
            <div className="container px-0">
                <nav className="app__navbar">
                    <div className="row align-items-center px-3 app_nabbar_margin px-sm-0">
                        <div className="col-md-4 col-lg-2 col-6 ps-0 text-start">
                            <div className="app__navbar-logo">
                                <Link to="/">
                                    <img src={logo} alt="app logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-10 col-6 d-flex px-3 align-items-center justify-content-end pe-0">
                            <div className="app__navbar-actions pe-3">
                                {hoverSearch === false &&
                                    <div className="app__navbar-search false_event">
                                        {/* <div className="false_event "> */}
                                        <Link to='/suche'>
                                            <AiOutlineSearch
                                                // onMouseEnter={handleOnMouseEnterSearch}
                                            /></Link>
                                        {/* </div> */}
                                    </div>
                                }
                                {hoverSearch &&
                                    <div className="app__navbar-search true_event">
                                        <div className="true_event w3-animate-right"
                                            onMouseLeave={handleOnMouseLeaveSearch}
                                        >
                                            <AnimateGroup animation="bounce" durationOut={500}>
                                                <input type="text" width={0}
                                                    onChange={handleSearchTextChange}
                                                />
                                                <AiOutlineSearch />
                                                {/* <div className="mobile_search_bar">
                                                    <div className="searchbox-mobile" style="top: 60px;">
                                                        <div className="searchbox">
                                                            <form action="/suche/" method="get" name="searchFormMobile">
                                                                <input name="searchString" className="search-term" type="text" />
                                                                <input className="search-btn" type="image" src="/typo3conf/ext/school_cinema/Resources/Public/img/svg/btn_search_black.svg" />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </AnimateGroup>
                                        </div>
                                    </div>
                                }
                                <ul className="app__navbar-links">
                                    <li>
                                        <Link to="/">{t('navbar.home')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/aktuelle-filme">{t('navbar.current')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/upcoming">{t('navbar.upcoming')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/film-archive">{t('navbar.film-archiv')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/anfragen" onClick={() => {sessionStorage.getItem("state") && window.location.reload(true) , sessionStorage.removeItem("state"), sessionStorage.removeItem('id')}}>{t('navbar.movie-request')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/campaigns">{t('navbar.campaigns')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/newsletter">{t('navbar.news-letter')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/contact">{t('navbar.contact')}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="header-mobile">
                                <div className="app__navbar-smallscreen">
                                    <GiHamburgerMenu color="black" fontSize={27} onClick={() => setToggleMenu(true)} />
                                    {toggleMenu && (
                                        <div className="app__navbar-smallscreen_overlay">
                                            <IoIosClose fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
                                            <div className="topmenu-mobile">
                                                <ul className="app__navbar-smallscreen_links">
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/">Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/aktuelle-filme">Aktuelle Filme</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/upcoming">Kommende Filme</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/film-archive">Filmarchiv</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/anfragen">Anfrage</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/campaigns">Schulaktionen</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/newsletter">Newsletter</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => setToggleMenu(false)} to="/about/contact">Kontakt</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </section>

    );
};

export default Navbar;
