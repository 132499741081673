import React from "react";
import PropTypes from 'prop-types';
import placeholderPoster from "../../assets/media/place_holder/placeholder.jpg"
import { CardLI } from "../../containers/aktuelle/styles";
import ImageWithLoader from "../ImageWithLoader";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const propTypes = {
    film: PropTypes.object.isRequired,
    startDate: PropTypes.string.isRequired,
};

const defaultProps = {
    startDate: ''
}

const CardUI = ({
    film,
    startDate
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const ChangeUrl = async (e, id) => {
        e.preventDefault();
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('state', film?.master?.cinemaIds);
        // navigate("/movie-detail", { state: { 'id': id, 'state': film?.master?.cinemaIds } });
        navigate(`/film-details/${film?.master?.shortURL ? film?.master?.shortURL : film?.master?.titleCalculated?.replaceAll(' ', '-')}`, { state: { 'id': id, 'state': film?.master?.cinemaIds } });
    }
    // console.log("film", film);
    return (
        <>
            <CardLI>
                <figure className='figure'>
                    <Link to={`/film-details/${film?.master?.shortURL ? film?.master?.shortURL : film?.master?.titleCalculated?.replaceAll(' ', '-')}`} onClick={e => ChangeUrl(e, film.id)} className='figure__link'>
                        <ImageWithLoader src={film?.master?.posterImage}
                            onerror={(e) => e.target.src = placeholderPoster}
                            alt={film?.master?.titleCalculated} />

                        <figcaption className='figure__caption'>
                            {film.master?.titleCalculated}
                            {location?.pathname === '/upcoming' && startDate !== undefined &&
                                <p className="startdatum">Filmstart: {startDate}</p>
                            }
                        </figcaption>
                    </Link>
                </figure>
            </CardLI>
        </>
    )
}

CardUI.propTypes = propTypes;
CardUI.defaultProps = defaultProps;
export default CardUI;
