import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import headerSearchResult from "../../../assets/media/header_searchresult.jpg";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CampaignBtn } from "./styles";
import "./styles.css";
import moment from "moment";
import { Pagination } from "react-pagination-bar";

const propTypes = {
  filmsUpcoming: PropTypes.array.isRequired,
  getUpComingData: PropTypes.func.isRequired,

  movies: PropTypes.array.isRequired,
  findMovie: PropTypes.func.isRequired,

  films: PropTypes.array.isRequired,
  getArchiveMovies: PropTypes.func.isRequired,

  sclCinemaFilm: PropTypes.array.isRequired,
  getTopMovies: PropTypes.func.isRequired,
};

const debounce = (cb, delay) => {
  let timeout;
  return (e) => {
    e.persist();
    clearTimeout(timeout);
    timeout = setTimeout(cb.bind(this, e), delay);
  };
};

function SearchMovies({
  // movies,
  // findMovie,
  filmsUpcoming,
  getUpComingData,
  films,
  getArchiveMovies,
  sclCinemaFilm,
  getTopMovies,
}) {
  const [t, i18n] = useTranslation();
  // const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [searchTitle, setSearchTitle] = useState("");
  const [allFilms, setAllFilms] = useState();
  const [currentFilms, setCurrentFilms] = useState();
  const [dataForRequest] = useState({
    body: {},
    params: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pagePostsLimit = 10;

  const onInput = useCallback(
    () =>
      debounce((e) => {
        const value = e.target.value.trim();
        const filterdData = [];

        if (value === "") {
          setCurrentFilms(allFilms);
          setSearchTitle("");
        } else {
          allFilms?.map((data, index) => {
            setSearchTitle(value);
            if (
              data?.master?.titleCalculated
                ?.toLowerCase()
                .toString()
                .includes(value.toLowerCase().toString())
            ) {
              filterdData.push(data);
            }
            if (index == allFilms?.length - 1) {
              setCurrentFilms(filterdData);
            }
          });
        }
      }, 250),

    [allFilms, dataForRequest]
  );

  useEffect(() => {
    if (Array.isArray(sclCinemaFilm)) {
      setAllFilms([...sclCinemaFilm, ...filmsUpcoming, ...films]);
      setCurrentFilms([...sclCinemaFilm, ...filmsUpcoming, ...films]);
    }
  }, [filmsUpcoming, sclCinemaFilm, films]);

  useEffect(() => {
    // findMovie({ title });
    getArchiveMovies();
    getUpComingData();
    getTopMovies();
    // setTitle(title);
  }, []);

  const ChangeUrl = async (e, id, cinemaIds, movieDetail) => {
    e.preventDefault();
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("state", cinemaIds);
    // navigate("/movie-detail", { state: { id: id, state: cinemaIds } });
    navigate(`/film-details/${movieDetail?.master?.shortURL ? movieDetail?.master?.shortURL : movieDetail?.master?.titleCalculated?.replaceAll(' ', '-')}`, { state: { 'id': id, 'state': cinemaIds } });
  };

  useEffect(() => {
    if (i18n.language === "en" || i18n.language === "en-US") {
      let sortedMoviesEN = allFilms?.sort((a, b) =>
        a?.titleOriginalCalculated?.localeCompare(
          b?.titleOriginalCalculated,
          "es",
          { sensitivity: "base" }
        )
      );
      setCurrentFilms(sortedMoviesEN);
    }

    if (i18n.language === "de" || i18n.language === "de-DE") {
      let sortedMoviesDE = allFilms?.sort((a, b) =>
        a?.titleCalculated?.localeCompare(b?.titleCalculated, "es", {
          sensitivity: "base",
        })
      );
      setCurrentFilms(sortedMoviesDE);
    }
  }, [i18n, allFilms]);

  return (
    <>
      <div className="mb-4">
        <div>
          <div>
            <img src={headerSearchResult} className="img-fluid" />
          </div>
          <div
            style={{
              backgroundColor: "#e9e9e9",
            }}
          >
            <div className="container p-4" style={{ maxWidth: "1100px" }}>
              <h3 className="searchHeading mb-0">{t("search.searchFor")}</h3>
              <h2 className="searchString mb-0">{`"${!searchTitle && "undefined" ? "" : searchTitle
                }"`}</h2>
              <p className="searchResult mb-0">
                ergab {!searchTitle && "undefined" ? "0" : currentFilms?.filter((movie, index, self) => index === self.findIndex((m) => m.id === movie.id))?.length}{" "}
                Treffer.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            className="container px-4 pt-2 mt-2"
            style={{ maxWidth: "1100px" }}
          >
            <input
              type="search"
              onInput={onInput()}
              className="w-100 rounded-pill border py-2 px-3"
              placeholder={t("search.searchTitleError")}
            />
            <h5 className="ms-1 mt-3 mb-0">
              {!currentFilms?.length ? (
                <span>{t("search.nothing_found")}</span>
              ) : (
                <>
                  <strong>{t("common.movies")} </strong>({currentFilms?.filter((movie, index, self) => index === self.findIndex((m) => m.id === movie.id))?.length})
                </>
              )}
            </h5>
          </div>
          <div className="container p-4" style={{ maxWidth: "1100px" }}>
            {currentFilms
              ?.slice(
                (currentPage - 1) * pagePostsLimit,
                currentPage * pagePostsLimit
              )
              ?.filter((movie, index, self) => index === self.findIndex((m) => m.id === movie.id))
              ?.map((movie, ID) => {
                return (
                  <div className="card my-3 border-0 movieDiv" key={ID}>
                    <div className="row g-0 align-items-center py-2 px-3">
                      <div className="col-6 col-sm-3 col-lg-2 mx-auto">
                        <img
                          src={movie?.master?.posterImage}
                          alt=""
                          width="120px"
                          style={{ maxWidth: "100%" }}
                          height="170px"
                          className="rounded shadow"
                        />
                      </div>
                      <div
                        className="col-12 col-sm-9  col-lg-10 border-start"
                        style={{ minHeight: "174px" }}
                      >
                        <div className="card-body">
                          <h5 className="card-title movieTitle">
                            {i18n?.language === "en" ||
                              i18n?.language === "en-US"
                              ? movie?.master?.titleOriginalCalculated || ""
                              : movie?.master?.titleCalculated || ""}
                          </h5>
                          <p className="card-text p-1 startdate">
                            FILMSTART:{" "}
                            {moment(movie?.master?.openingDate).format(
                              "DD.MM.YYYY"
                            )}
                          </p>
                          <p className="card-text p-1 mb-0">
                            {movie?.master?.shortSynopsis}
                          </p>
                          {/* {genres ? (
                            <p className="l-found-movies__item-text card-text ms-1">
                              {genres}
                            </p>
                          ) : null} */}
                          <CampaignBtn>
                            <Link
                              key={movie?.id}
                              onClick={(e) =>
                                ChangeUrl(
                                  e,
                                  movie?.id,
                                  movie?.master?.cinemaIds,
                                  movie
                                )
                              }
                              className="form-input-button"
                              style={{ marginLeft: "5px" }}
                            >
                              {t("campaigns.more_film_info")}
                            </Link>
                          </CampaignBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row col-12">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pagePostsLimit}
              onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
              totalItems={currentFilms?.length}
              pageNeighbours={1}
              customClassNames={{
                rpbItemClassName: "custom-item",
                rpbItemClassNameActive: "custom-item--active",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

SearchMovies.propTypes = propTypes;

export default SearchMovies;
