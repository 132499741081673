import Movies from './modules/Movies';
import Auth from './modules/Auth';
import User from './modules/User';
import Campaings from './modules/Campaings';
import Newsletter from './modules/Newsletter';

class API {
  constructor(config, storage) {
    const { api, cmsApi, headerTypes } = config;
    const options = { ...api, storage, headerTypes };
    const cmsOptions = { ...cmsApi, storage, headerTypes };

    this.modules = {
      movies: new Movies(options),
      campaings: new Campaings(cmsOptions),
      auth: new Auth(options),
      user: new User(options),
      newsletter : new Newsletter(options)
    };
  }

  getModuleByName(name) {
    if (Object.keys(this.modules).indexOf(name) < 0) {
      throw new Error("API class doesn't contain module with specified name");
    }

    return this.modules[name];
  }
}

export default API;
