import React from "react";
import FilmList from "../../components/common/FilList";
import { WrapperPage, RowPage } from "./styles";

const Aktuelle = () => {
    return (
        <WrapperPage>
            <RowPage>
                <FilmList />
            </RowPage>
        </WrapperPage>
    );
};

export default Aktuelle;

