import * as deepmerge from 'deepmerge';
import common from './common';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const apiIalEndpoint = process.env.REACT_APP_TOKEN_IAL;
const cmsApiEndpoint = process.env.REACT_APP_CMS_API_ENDPOINT;
const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

const commonConfig = common(apiEndpoint, apiIalEndpoint, cmsApiEndpoint, domainGroupId, gtmId);
const arrayMerge = (destinationArray, sourceArray) => sourceArray;

const config = deepmerge(commonConfig, { arrayMerge });

export default config;
