import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
// import { Slide } from "../../containers/home/styles";
import Slider from "react-slick";
import { CarouselBlock } from "../../containers/aktuelle/styles";
import { Link, useNavigate } from "react-router-dom";

const propTypes = {
    dataItem: PropTypes.array.isRequired,
    films: PropTypes.array.isRequired,
    filmListData: PropTypes.array.isRequired,
};

const defaultProps = {
    films: []
};

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
function RightCarouselClass({
    dataItem,
    films,
    filmListData
}) {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [settings, setsettings] = useState([]);
    const [rightItem, setRightItem] = useState(dataItem?.[0]);
    const [allItem, setAlltItem] = useState(dataItem);
    const navigate = useNavigate();
    const CampaingnsDetail = (e, campaignId, movieId, objectName) => {
        e.preventDefault();
        // navigate("/campaigns-detail", {
        navigate(`/campaigns-detail/${objectName}`, {
            state: {
                'movieId': movieId,
                'campaignId': Number(campaignId),
            }
        });
    }
    useEffect(() => {

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        if (windowSize.innerWidth < 767) {
            var settings_ = {
                dots: false,
                infinite: true,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 4000,
                slidesToShow: 1,
                slidesToScroll: 1,
            };
            setsettings(settings_)
        } else {
            var settings__ = {
                dots: false,
                infinite: true,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 4000,
                slidesToShow: 1,
                slidesToScroll: 1,
            };
            setsettings(settings__)
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    useEffect(() => {
        setRightItem(dataItem?.[0])
        setAlltItem(dataItem)
    }, [dataItem])

    const checkValueFilter = (films, id) => {
        // if (films?.length > 0) {
        //     const filtered = films?.filter(film => {
        //         return film.id === id
        //     });
        //     return filtered?.[0]?.master?.posterImage;
        // }
        if (filmListData?.length > 0) {
            const filtered = filmListData?.filter(film => {
                if (id?.slice(0, 2) === 'HO' && film?.id) {

                    return film?.id === id;
                }
                else {
                    if (film?.HOFilmCode) {
                        return film?.HOFilmCode === id;
                    }
                }
            });
            return filtered?.[0]?.posterImage;
        }
    }

    return (
        <>
            {rightItem?.linkedCampaigns?.length > 0 ?
                <Slider {...settings}>
                    {rightItem?.linkedCampaigns?.length > 0 &&
                        rightItem?.linkedCampaigns?.map((item, key) => {
                            return (
                                Object.keys(item)?.length &&
                                < CarouselBlock key={key} >
                                    <div className="first_parent right_slider">
                                        <div className="main_div">
                                            <>
                                                <div className="only_single_item mb-0">
                                                    <div className="head_line">
                                                        <h1 className="heading text-left textCenter">{rightItem?.headlineCampaign}</h1>
                                                    </div>
                                                    <div className="content" >
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-12 col-12 d-flex justify-content-center" key={key}>
                                                                {checkValueFilter(films, item.hOFilmcode) !== undefined &&
                                                                    <div className="content__imgBox">
                                                                        <Link to={`/campaigns-detail/${item.key}`} onClick={e => CampaingnsDetail(e, item?.id, item?.hOFilmcode, item?.key)}>
                                                                            <img src={checkValueFilter(films, item.hOFilmcode)} style={{ maxWidth: "180px" }} className="single_img" />
                                                                        </Link>
                                                                    </div>
                                                                    // :
                                                                    // <div className="placeholder_imgBox">
                                                                    //     <img src={placeholderImage} className="placeholder_img" />
                                                                    // </div>
                                                                }
                                                            </div>
                                                            <div className={`col-lg-7 col-md-7 col-sm-12 col-12 mt-3 center-text right_content_data`}>
                                                                <div className="set_div" style={{ maxWidth: '300px' }}>
                                                                    <Link to={`/campaigns-detail/${item.key}`} onClick={e => CampaingnsDetail(e, item?.id, item?.hOFilmcode, item?.key)}>
                                                                        <h3 className="single_img_title" dangerouslySetInnerHTML={{ __html: item?.headline }} />
                                                                    </Link>
                                                                    <div className="content__data">
                                                                        <p className="slide-text" dangerouslySetInnerHTML={{ __html: item?.descriptionShort }}></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </CarouselBlock>
                            );
                        })
                    }
                </Slider >
                :
                <Slider {...settings}>
                    {allItem?.length > 0 &&
                        allItem?.map((item, key) => {
                            return (
                                <CarouselBlock key={key}>
                                    <div className="first_parent">
                                        <div className="main_div">
                                            <>
                                                <div className="no_one_item">
                                                    <div className="head_line">
                                                        <h4 className="heading text-left">{item?.headlineNoCampaign}</h4>
                                                    </div>
                                                    <div className="content">
                                                        <div className="row" style={{ width: '100%' }}>
                                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 mt-sm-2 center-text`}>
                                                                <div className="content__data no_one_slide_right">
                                                                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: item?.noCampaignInfo }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </CarouselBlock>
                            );
                        })
                    }
                </Slider>
            }
        </>
    )
}

RightCarouselClass.propTypes = propTypes;
RightCarouselClass.defaultProps = defaultProps;
export default RightCarouselClass;