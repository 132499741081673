import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import UpcomingMovie from './UpcomingMovie.component';
import layoutModule from '../../../store/layout';

const {
    requestFetchUpcomingMovies,
    // requestUpcomingMovies
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getUpComingData: requestFetchUpcomingMovies,
    getLocations: requestLocations,
    // getUpcomingMovies: requestUpcomingMovies,
};

const mapStateToProps = ({ movies, layout }) => (
    {
        films: movies.up_coming_films,
        isLoading: layout.isLoading,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpcomingMovie);





