import React from "react";
import AdminLoginForm from "../../components/common/AdminLoginForm";
import { WrapperPage,RowPage } from "./styles";

const AdminLogin = () => {
    return (
        <WrapperPage>
            <RowPage>
                <AdminLoginForm />
            </RowPage>
        </WrapperPage>
    );
};

export default AdminLogin;

