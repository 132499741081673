import styled from "styled-components";

export const CampaignBtn = styled.div`
  .form-input-button {
    padding: 8px;
    background: #8c8d8d;
    color: #ffffff;
    margin-left: 0px;
    margin-top: 40px;
    border: none;
  }
  @media (max-width: 767px) {
    .form-input-button {
      margin-left: 0px;
      margin-top: 15px;
    }
  }
`;
