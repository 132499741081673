import BaseAPI from '../BaseAPI';

class Newsletter extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/newsletter';
  }

  sendNewsletterFormData(body) {
    const url = `/schoolcinemanewsletter/registration`;
    const version = '/api/v1';
    return this.post({ url, body, version });
  }
   /**
   * Remove a users from newsletter
   *
   * @param deleteuser {boolean}
   * @param email {string}
   * @return {Promise<*|Error>} 
   */
  
  sendEmailForDeregistration(deleteuser,email){
    const url = '/schoolcinemanewsletter/deregistration';
    const version = '/api/v1';
    const body = {
      deleteuser,
      email,
    }
    return this.remove({ url, body, version});
  }
  getRequestNewsLetterData(params) {
    const url = `/schoolcinemanewsletter/`;
    const version = '/api/v1';
    // console.log("version",version ,url, params);
    return this.get({url, params: { ...params }, version });
  }

  /**
   * Makes user activation
   *
   * @param status {boolean}
   * @param email {string}
   * @return {Promise<*>}
   */
  activate(status, email) {
    const url = `/schoolcinemanewsletter/update-status`;
    const version = '/api/v1';
    const body = {
      status,
      email
    };

    return this.post({ url, body, version });
  }
}

export default Newsletter;