import styled from "styled-components";
export const HeaderContainer = styled.div`
.header-subcomponent{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 4rem;
}
.header-cinemaInfo{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10px;
}
.cinema-detail ul{
    font-size: 25px;
    padding: 0px;
}
.contact-detail ul{
    font-size: 25px;
}
.right-box{
    border: 2px solid red;
    padding: 50px;
    /* width: 20%; */
    grid-column: 1/4;
    /* text-align: center; */
    display: grid;
    justify-content: center;
    align-content: center;
}
.right-box > h2 {
    text-align: center;
}
.left-box{
    border: 2px solid black;
    padding: 5px;
    /* width: 34%; */
    /* grid-column: 6/9; */
    grid-column: 8/9;
}
.left-box > p {
    font-size: 18px;
}
.left-box > ul{
    padding: 0px;
    font-size: 18px;
}
.left-box span{
    font-weight: bold;
}
.hr-tag{
    border: 1px solid black;
    opacity: 1 !important;
}
.link{
    text-decoration: underline;
    color: var(--bs-link-color);
    font-weight: 600;
}
`
export const MovieInfoContainer = styled.div`
    /* .movie-info  span{
        font-weight: bold;
    } */
    .movie-info  > ul{
        padding: 0px;
        display: grid;
         gap: 15px;
    }
    .movie-info  li{
    display: grid;
    grid-template-columns: 1fr 7fr;
    /* grid-template-columns: 150px 570px; */
    }
    .bold{
        font-weight: bold;
       font-size: 23px;
    }
    .movie-info  label{
        font-weight: bold;
        font-style: italic; 
        font-size:25px
    }
    
    .normal-text{
        font-size: 23px;
    }
    .hr-tag{
    border: 1px solid black;
    opacity: 1 !important;
}
`
export const PriceInfo = styled.div`
    /* .movie-info  span{
        font-weight: bold;
    } */
    .price-info > ul{
        padding: 0px;
        display: grid;
         gap: 15px;
    }
    .price-info li{
    display: grid;
    /* grid-template-columns: 150px 570px; */
    grid-template-columns: 1fr 2fr;
    }
    .price-info li p {
        margin-bottom: 0px;
    }
    .price-info label, .bottom_info{
        font-weight: bold;
        font-style: italic; 
        font-size:25px
    }
    .bold{
       font-weight: bold;
       font-size: 23px;
    }
    .euro{
        font-size: 35px;
    }
    .normal-text{
        font-size: 25px;
    }
   .info-das-box{
    border: 2px solid black;
    padding: 25px;
    }
    .info-das-bax, .headline{
        text-decoration: underline;
        /* margin-bottom: 2px; */
        font-weight: bold;
        font-size: 25px;
    }
    .info-das-box p{
        margin-bottom: 0px;
    }
    .info-text{
        /* font-size: 20px; */
    }
`
export const RowPage = styled.main`
    width: 100%;
    float: left;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
    .footer-info{
    padding-top: 140px;
    padding-bottom: 40px;
    }
    
    .logo img{
        width: 500px;
        opacity: 0.5;
    }
`;
export const WrapperPage = styled.main`
    max-width: 1480px;
    margin: auto;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
    padding: 20px 50px;
`;