import {
    SUCCESS_SEND_NEWSLETTER_FORM_DATA,
    SUCCESS_REGISTRATION_FORM_DATA,
    SUCCESS_DEREGISTRATION_NEWSLETTER_DATA,
    GET_NEWSLETTER_REQUEST_SUCCESS_DATA
} from './types';

const initialState = {
    newsletter:{},
    requestData: [],
}


export default function (state = initialState, {type, payload}){

    switch (type) {
        case SUCCESS_SEND_NEWSLETTER_FORM_DATA :{
            return {
                ...state,
                output : payload || [],
            }
        }    
        case SUCCESS_REGISTRATION_FORM_DATA : {
            return {
                ...state,
                // result: Object.values(payload) || [],
                result: payload || [],
            };
        }
        case SUCCESS_DEREGISTRATION_NEWSLETTER_DATA :{
            return {
                ... state,
                emailData: payload || [],
            };
        }
        case GET_NEWSLETTER_REQUEST_SUCCESS_DATA : {
            return {
                ...state,
                requestData: Object.values(payload) || [],
            };
        }
        default: {
            return state;
        }
           
    }
}