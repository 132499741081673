import {
    SEND_NEWSLETTER_FORM_DATA,
    SUCCESS_SEND_NEWSLETTER_FORM_DATA,
    ACTIVATE_REQUEST,
    SUCCESS_REGISTRATION_FORM_DATA,
    DEREGISTRATION_NEWSLETTER,
    SUCCESS_DEREGISTRATION_NEWSLETTER_DATA,
    GET_NEWSLETTER_REQUEST_DATA,
    GET_NEWSLETTER_REQUEST_SUCCESS_DATA
 } from './types'


export const requestForSendNewsletterFormData = params => ({
    type: SEND_NEWSLETTER_FORM_DATA,
    payload: { params }
});

export const successForSendNewsletterFormData = data => ({
    type: SUCCESS_SEND_NEWSLETTER_FORM_DATA,
    payload: data
});

export const activateRequest = (status, email) => ({
    type: ACTIVATE_REQUEST,
    payload: {
      status,
      email
      }
  });

  export const successRegistrationData = data => ({
    type: SUCCESS_REGISTRATION_FORM_DATA,
    payload: data
});

export const requestForDeregistrationNewsletter = (deleteuser, email) => ({
    type: DEREGISTRATION_NEWSLETTER,
    payload: {
        deleteuser,
        email
    }
});

export const successForDeregistrationNewsletter = data => ({
    type: SUCCESS_DEREGISTRATION_NEWSLETTER_DATA,
    payload: data
});
export const requestSchoolCinemaNewsLetterData = params => ({
    type: GET_NEWSLETTER_REQUEST_DATA,
    payload: { params }
});
export const successRequestSchoolCinemaNewsLetterData  = data => ({
    type: GET_NEWSLETTER_REQUEST_SUCCESS_DATA,
    payload: data
});