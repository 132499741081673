import { throwError, of, concat } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthError, ApiError } from '../services/api/errors';
import { setError, setLoader } from './layout/actions';

/**
 * Handles unknown types of app errors
 *
 * @param error {Response|Error}
 * @return {*}
 */
export const handleUnknownError = error => {
  if (!(error instanceof AuthError || error instanceof ApiError)) {
    return concat([setError(1, error.message, error), setLoader(false)]);
  }

  return throwError(error);
};

/**
 * Handles the case when auth is failed
 *
 * @param error {Error|AuthError}
 * @param type {string} - action type
 * @param payload {object} -action payload
 * @return {*}
 */
export const handleAuthError = (error) => {
  if (error instanceof AuthError) {
    return of(setLoader(false));
  }

  return throwError(error);
};

/**
 * Middleware for handling auth and unknown errors
 *
 * @param action {object} - redux action
 * @return {*}
 */
export const handleErrors = action => {
  const fns = [handleUnknownError, handleAuthError];

  return fns.map(fn => error => fn(error, action)).map(fn => catchError(fn));
};

/**
 *
 * */
export const separateErrors = responseData => {
  const valuesArray = Object.keys(responseData);
  const result = {
    data: {},
    errors: {},
    hasErrors: false
  };

  for (let i = 0; i < valuesArray.length; i += 1) {
    const currentKey = valuesArray[i];
    const currentValue = responseData[currentKey];

    if (currentValue.err) {
      result.errors[currentKey] = currentValue.err.message;
      result.hasErrors = true;
    } else {
      result.data[currentKey] = currentValue;
    }
  }

  return result;
};

export const authErrorHandleAfterManualCatch = (fn, ...errors) => {
  for (let i = 0; i < errors.length; i += 1) {
    const { err } = errors[i];

    if (err instanceof AuthError) {
      throw err;
    }
  }

  return fn(...errors);
};
