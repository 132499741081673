import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import FilmList from './FilmList.component';
import layoutModule from '../../../store/layout';

const {
    requestUpcomingMovies,
    requestStateList
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getUpcomingMovies: requestUpcomingMovies,
    getStateList: requestStateList,
    getLocations: requestLocations,
};

const mapStateToProps = ({ movies, layout }) => ({
    films: movies.films,
    upcomingDates: movies.upcomingDates,
    states: movies.states,
    shortList: movies.shortList,
    isLoading: layout.isLoading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilmList);





