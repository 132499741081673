import {
    TOP_MOVIES_REQUEST,
    MOVIES_FETCH_SUCCESS,
    UPCOMING_MOVIES_REQUEST,
    SET_UPCOMING_MOVIES,
    GET_MOVIE_DETAIL,
    MOVIES_DETAIL_FETCH_SUCCESS,
    GET_STATE_LIST,
    STATE_LIST_FETCH_SUCCESS,
    GET_DETAIL_OF_CINEMA,
    GET_DETAIL_OF_CINEMA_FETCH_SUCCESS,
    GET_FETCH_UPCOMING_MOVIES,
    GET_FETCH_UPCOMING_MOVIES_SUCCESS,
    SEND_FORM_DATA,
    SEND_CAMPAIGN_FORM_DATA,
    SEND_CAMPAIGN_OTHER_FORM_DATA,
    SUCCESS_SEND_FORM_DATA,
    SUCCESS_SEND_CAMPAIGN_FORM_DATA,
    SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA,
    CINEMAS_LIST_REQUEST,
    SUCCESS_CINEMAS_LIST,
    REQUEST_ARCHIVE_MOVIES,
    SET_ARCHIVE_MOVIES,
    REQUEST_CAMPAINGS_LIST,
    GET_REQUEST_CAMPAINGS_LIST,
    REQUEST_SCHOOL_LANDING_PAGE,
    GET_REQUEST_SCHOOL_LANDING_PAGE,
    REQUEST_LEFT_SLIDER,
    GET_REQUEST_LEFT_SLIDER,
    REQUEST_RIGHT_SLIDER,
    GET_REQUEST_RIGHT_SLIDER,
    REQUEST_CAMPAINGNS,
    REQUEST_CAMPAINGNS_SUCCESS,
    REQUEST_CAMPAINGNS_LIST,
    REQUEST_CAMPAINGNS_LIST_SUCCESS,
    REQUEST_SCHOOL_TOP_MOVIE,
    REQUEST_SCHOOL_TOP_MOVIE_SUCCESS,
    REQUEST_SCHOOL_TOP_FILM_TIPS,
    REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS,
    REQUEST_CINEMA_DATA,
    REQUEST_CINEMA_DATA_SUCCESS,
    REQUEST_POSTER_DATA,
    REQUEST_POSTER_DATA_SUCCESS,
    REQUEST_ABOUT_INFO_DATA,
    REQUEST_ABOUT_INFO_DATA_SUCCESS,
    TOP_MOVIES_REQUEST_LOCATION,
    MOVIES_FETCH_SUCCESS_LOCATION,
    GET_CINEMA_REQUEST_DATA,
    GET_CINEMA_CAMPAIGN_REQUEST_DATA,
    GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA,
    GET_CINEMA_REQUEST_SUCCESS_DATA,
    GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA,
    GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA,
} from './types';

export const requestTopMovies = params => ({
    type: TOP_MOVIES_REQUEST,
    payload: { params }
});
export const successMoviesRequest = data => ({
    type: MOVIES_FETCH_SUCCESS,
    payload: data
});
export const requestTopMoviesLocation = params => ({
    type: TOP_MOVIES_REQUEST_LOCATION,
    payload: { params }
});
export const successMoviesRequestLocation = data => ({
    type: MOVIES_FETCH_SUCCESS_LOCATION,
    payload: data
});
export const requestSchoolCinemaData = params => ({
    type: GET_CINEMA_REQUEST_DATA,
    payload: { params }
});
export const successRequestSchoolCinemaData = data => ({
    type: GET_CINEMA_REQUEST_SUCCESS_DATA,
    payload: data
});
export const requestSchoolCinemaCampaignData = params => ({
    type: GET_CINEMA_CAMPAIGN_REQUEST_DATA,
    payload: { params }
});
export const requestSchoolCinemaCampaignOtherData = params => ({
    type: GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA,
    payload: { params }
});
export const successRequestSchoolCinemaCampaignData = data => ({
    type: GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA,
    payload: data
});
export const successRequestSchoolCinemaCampaignOtherData = data => ({
    type: GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA,
    payload: data
});
export const requestUpcomingMovies = params => ({
    type: UPCOMING_MOVIES_REQUEST,
    payload: { params }
});
export const setUpcomingMovies = data => ({
    type: SET_UPCOMING_MOVIES,
    payload: data
});
export const requestMovieDetail = params => ({
    type: GET_MOVIE_DETAIL,
    payload: { params }
});
export const successMovieDetailFetch = data => ({
    type: MOVIES_DETAIL_FETCH_SUCCESS,
    payload: data
});
export const requestStateList = params => ({
    type: GET_STATE_LIST,
    payload: { params }
});
export const successStateList = ({ data, shortList }) => ({
    type: STATE_LIST_FETCH_SUCCESS,
    payload: { data, shortList }
});
export const requestDetailOfCinema = params => ({
    type: GET_DETAIL_OF_CINEMA,
    payload: { params }
});
export const successDetailOfCinema = ({ data }) => ({
    type: GET_DETAIL_OF_CINEMA_FETCH_SUCCESS,
    payload: { data }
});
export const requestFetchUpcomingMovies = params => ({
    type: GET_FETCH_UPCOMING_MOVIES,
    payload: { params }
});
export const successFetchUpcomingMoviesData = ({ data }) => ({
    type: GET_FETCH_UPCOMING_MOVIES_SUCCESS,
    payload: { data }
});
export const requestForSendFormData = params => ({
    type: SEND_FORM_DATA,
    payload: { params }
});
export const requestForSendCampaignFormData = params => ({
    type: SEND_CAMPAIGN_FORM_DATA,
    payload: { params }
});
export const requestForSendCampaignOtherFormData = params => ({
    type: SEND_CAMPAIGN_OTHER_FORM_DATA,
    payload: { params }
});
export const successForSendFormData = data => ({
    type: SUCCESS_SEND_FORM_DATA,
    payload: data
});
export const successForSendCampaignFormData = data => ({
    type: SUCCESS_SEND_CAMPAIGN_FORM_DATA,
    payload: data
});
export const successForSendCampaignOtherFormData = data => ({
    type: SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA,
    payload: data
});
export const requestCinemasList = params => (
    {
        type: CINEMAS_LIST_REQUEST,
        payload: { params }
    });
export const successCinemasList = data => (
    {
        type: SUCCESS_CINEMAS_LIST,
        payload: data
    });
export const requestArchiveMovies = params => ({
    type: REQUEST_ARCHIVE_MOVIES,
    payload: { params }
});
export const setArchiveMovies = data => ({
    type: SET_ARCHIVE_MOVIES,
    payload: data
});
export const requestCampaingsList = () => ({
    type: REQUEST_CAMPAINGS_LIST,
    payload: {}
});
export const setCampaingsList = data => ({
    type: GET_REQUEST_CAMPAINGS_LIST,
    payload: data
});
export const getSliderData = () => ({
    type: REQUEST_LEFT_SLIDER,
    payload: {}
});
export const setLeftSlider = data => ({
    type: GET_REQUEST_LEFT_SLIDER,
    payload: data
});
export const getRightSliderData = () => ({
    type: REQUEST_RIGHT_SLIDER,
    payload: {}
});
export const setRightSlider = data => ({
    type: GET_REQUEST_RIGHT_SLIDER,
    payload: data
});
export const requestCampaingn = () => ({
    type: REQUEST_CAMPAINGNS,
    payload: {}
});
export const setCompaingnsData = data => ({
    type: REQUEST_CAMPAINGNS_SUCCESS,
    payload: data
});
export const requestCampaignsList = (params) => ({
    type: REQUEST_CAMPAINGNS_LIST,
    payload: { params }
});
export const setCompaingnsDataList = data => ({
    type: REQUEST_CAMPAINGNS_LIST_SUCCESS,
    payload: data
});
export const getSchoolTopMovies = () => ({
    type: REQUEST_SCHOOL_TOP_MOVIE,
    payload: {}
});
export const setSchoolTopList = data => ({
    type: REQUEST_SCHOOL_TOP_MOVIE_SUCCESS,
    payload: data
});
export const getTopFilmTips = () => ({
    type: REQUEST_SCHOOL_TOP_FILM_TIPS,
    payload: {}
});
export const setTopFilmTipsList = data => ({
    type: REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS,
    payload: data
});
export const requestCinemaData = () => ({
    type: REQUEST_CINEMA_DATA,
    payload: {}
});
export const setCinemaData = data => ({
    type: REQUEST_CINEMA_DATA_SUCCESS,
    payload: data
});
export const requestPosterData = () => ({
    type: REQUEST_POSTER_DATA,
    payload: {}
});
export const setPosterData = data => ({
    type: REQUEST_POSTER_DATA_SUCCESS,
    payload: data
});
export const requestAboutInfo = () => ({
    type: REQUEST_ABOUT_INFO_DATA,
    payload: {}
});
export const successAbountInfoRequest = data => ({
    type: REQUEST_ABOUT_INFO_DATA_SUCCESS,
    payload: data
});
export const requestSchoolLandingPage = params => ({
    type: REQUEST_SCHOOL_LANDING_PAGE,
    payload: { params }
});
export const setSchoolLandingPage = data => ({
    type: GET_REQUEST_SCHOOL_LANDING_PAGE,
    payload: data
});
export const getSchoolLandingPage = () => ({
    type: REQUEST_SCHOOL_LANDING_PAGE,
    payload: {}
});