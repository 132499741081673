import {
    MOVIES_FETCH_SUCCESS,
    SET_UPCOMING_MOVIES,
    MOVIES_DETAIL_FETCH_SUCCESS,
    STATE_LIST_FETCH_SUCCESS,
    GET_DETAIL_OF_CINEMA_FETCH_SUCCESS,
    GET_FETCH_UPCOMING_MOVIES_SUCCESS,
    SUCCESS_SEND_FORM_DATA,
    SUCCESS_SEND_CAMPAIGN_FORM_DATA,
    SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA,
    SUCCESS_CINEMAS_LIST,
    SET_ARCHIVE_MOVIES,
    GET_REQUEST_CAMPAINGS_LIST,
    GET_REQUEST_SCHOOL_LANDING_PAGE,
    GET_REQUEST_LEFT_SLIDER,
    GET_REQUEST_RIGHT_SLIDER,
    REQUEST_CAMPAINGNS_SUCCESS,
    REQUEST_CAMPAINGNS_LIST_SUCCESS,
    REQUEST_SCHOOL_TOP_MOVIE_SUCCESS,
    REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS,
    REQUEST_CINEMA_DATA_SUCCESS,
    REQUEST_POSTER_DATA_SUCCESS,
    REQUEST_ABOUT_INFO_DATA_SUCCESS,
    MOVIES_FETCH_SUCCESS_LOCATION,
    GET_CINEMA_REQUEST_SUCCESS_DATA,
    GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA,
    GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA
} from './types';

const initialState = {
    films: [],
    filmsDetail: [],
    filmsLocation: [],
    up_coming_films: [],
    films_archive: [],
    partner: [],
    dates: [],
    states: [],
    list: [],
    leftSlider: [],
    rightSlider: [],
    data: [],
    dataList: [],
    contact: [],
    info: [],
    impressum: [],
    requestData: [],
    landingPageData: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case MOVIES_FETCH_SUCCESS: {
            return {
                ...state,
                films: Object.values(payload) || [],
            };
        }
        case MOVIES_FETCH_SUCCESS_LOCATION: {
            return {
                ...state,
                filmsLocation: Object.values(payload) || [],
            };
        }

        case GET_CINEMA_REQUEST_SUCCESS_DATA: {
            return {
                ...state,
                requestData: Object.values(payload) || [],
            };
        }
        case GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA: {
            return {
                ...state,
                requestData: Object.values(payload) || [],
            };
        }
        case GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA: {
            return {
                ...state,
                requestData: Object.values(payload) || [],
            };
        }
        case SET_UPCOMING_MOVIES: {
            return {
                ...state,
                films: Object.values(payload) || [],
            };
        }
        case SET_ARCHIVE_MOVIES: {
            return {
                ...state,
                films_archive: Object.values(payload) || [],
            };
        }
        case MOVIES_DETAIL_FETCH_SUCCESS: {
            return {
                ...state,
                filmsDetail: payload || [],
            };
        }
        case STATE_LIST_FETCH_SUCCESS: {
            return {
                ...state,
                states: Object.values(payload.data) || [],
                shortList: payload.shortList || []
            };
        }
        case GET_DETAIL_OF_CINEMA_FETCH_SUCCESS: {
            return {
                ...state,
                details: payload || [],
            };
        }
        case GET_FETCH_UPCOMING_MOVIES_SUCCESS: {
            return {
                ...state,
                up_coming_films: Object.values(payload.data) || [],
            };
        }
        case SUCCESS_SEND_FORM_DATA: {
            return {
                ...state,
                output: payload || [],
            };
        }
        case SUCCESS_SEND_CAMPAIGN_FORM_DATA: {
            return {
                ...state,
                output: payload || [],
            };
        }
        case SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA: {
            return {
                ...state,
                output: payload || [],
            };
        }
        case SUCCESS_CINEMAS_LIST: {
            return {
                ...state,
                partner: Object.values(payload) || [],
            };
        }
        case GET_REQUEST_CAMPAINGS_LIST: {
            return {
                ...state,
                list: payload.data || [],
            };
        }
        case GET_REQUEST_SCHOOL_LANDING_PAGE: {
            return {
                ...state,
                landingPageData: payload.data || [],
            };
        }
        case GET_REQUEST_LEFT_SLIDER: {
            return {
                ...state,
                leftSlider: payload.data || [],
            };
        }
        case GET_REQUEST_RIGHT_SLIDER: {
            return {
                ...state,
                rightSlider: payload.data || [],
            };
        }
        case REQUEST_CAMPAINGNS_SUCCESS: {
            return {
                ...state,
                data: payload.data || [],
            };
        }
        case REQUEST_CAMPAINGNS_LIST_SUCCESS: {
            return {
                ...state,
                dataList: payload?.data || [],
            };
        }
        case REQUEST_SCHOOL_TOP_MOVIE_SUCCESS: {
            return {
                ...state,
                data: payload.data || [],
            };
        }
        case REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS: {
            return {
                ...state,
                data: payload.data || [],
            };
        }
        case REQUEST_CINEMA_DATA_SUCCESS: {
            return {
                ...state,
                data: payload.data || [],
            };
        }
        case REQUEST_POSTER_DATA_SUCCESS: {
            return {
                ...state,
                data: payload.data || [],
            };
        }
        case REQUEST_ABOUT_INFO_DATA_SUCCESS: {
            return {
                ...state,
                contact: payload.contact.data || [],
                info: payload.info.data || [],
                impressum: payload.impressum.data || [],
            };
        }

        default: {
            return state;
        }
    }
}
