import { connect } from 'react-redux';
import newsletterModule from '../../../store/newsletter';
import NewsLetterRegistration from './NewsLetterRegistration.component';


const {
    activateRequest,
} = newsletterModule.actions;
const mapDispatchToProps = {
    activate: activateRequest,
};

const mapStateToProps = ({newsletter}) => (
    {
        output: newsletter.output,
        result : newsletter.result,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsLetterRegistration);





