import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import logo_apollo from '../../../assets/media/logos/logo_apollo.png';
import logo_cf from '../../../assets/media/logos/logo_cf.png';
import logo_cineplexx from '../../../assets/media/logos/logo_cineplexx.png';
import logo_imax from '../../../assets/media/logos/logo_imax.png';
import logo_kitz from '../../../assets/media/logos/logo_kitz.png';
import logo_village from '../../../assets/media/logos/logo_village.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const [t] = useTranslation();
    const location = useLocation();

    const FooterSection = styled.footer`
        width: 100%;
        float: left;

        .footer {
            background-color: #e9e9e9;
            padding: 20px;
        }
        div{
            padding:55px 0;
            position:relative;
            /* max-width:1280px; */
            max-width:${location.pathname === '/admin-detail' ? '100%' : '1280px'};
            margin:0 auto;
            padding: 0;
        }

        h3{
            text-align:center;
            margin-bottom:15px;
        }
        @media (max-width: 635px) {
            .options{
                 position: unset !important;
                 display: flex !important;
                 justify-content: center;
             }

            }
        }
        .options{
            position:absolute;
            top:5px;
            right:5px;
            display:flex;
            list-style:none;
            gap:12px;

            &__link {
                text-transform: capitalize;
            }
        }


        .partners{
            display:flex;
            justify-content:space-between;
            align-items:center;
            margin:0 auto;
        }

        .partners li img{
            height: 55px;
            width: 100px;
            object-fit: contain;
        }
        ul {
            padding: 0;
        }
    `

    const H2Wrapper = styled.div`
        position: relative;
        left: 0;
        text-align: center;
        padding: 0;
        h2.page-subline-transparent {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: black;
            background-color: transparent;
            padding: 10px 0px 10px 0px;
            display: inline-block;
        }
    `;

    const FooterOption = [
        {
            name: 'sitemap',
            link: '/about/sitemap'
        },
        {
            name: 'impressum',
            link: '/about/impressum'
        },
    ]

    return (
        <div className='container-fluid'>
            <FooterSection>
                <div className="row footer">
                    <div className="col-lg-12 pt-0 pb-0">
                        <H2Wrapper>
                            <h2 className="page-subline-transparent">{t('common.footer')}</h2>
                        </H2Wrapper>
                        <ul className='options'>
                            {FooterOption.map((option) => {
                                return (
                                    <li key={option?.name}>
                                        <Link to={option.link} className='options__link'>{option.name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className='partners row mt-3'>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_apollo} /></Link></li>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_cf} /></Link></li>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_cineplexx} /></Link></li>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_imax} /></Link></li>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_kitz} /></Link></li>
                            <li className='col-lg-2 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center'><Link to="/about/partner"><img src={logo_village} /></Link></li>
                        </ul>
                    </div>
                </div>
            </FooterSection>
        </div>
    )
}

export default Footer



