import styled from 'styled-components';

export const CarouselBlock = styled.div.attrs({
    className: "carousel-block"
})`
    height: 360px;
    background: linear-gradient(180deg, #C9A491 0%, rgba(243, 206, 151, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    .carousel-block__images {
        display: flex;
        width: 384px;
        justify-content: center;
        align-items: center;
        padding: 17px;
        background: #ffffff;
        .image {
            width: 80px;
            height: 116px;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .carousel-root {
        outline: none;
        width: 100%;
        float: left;
        display: block;
    }
    .carousel-block__footer {
        // width: 384px;
        padding: 13px 10px;
        width: 59.5%;
        background: #ffffff;
        margin-top: 10px;
        h3 {
            text-align: left;
            font-weight: 700;
            font-size: 28px;
            line-height: 35px;
            color: #000000;
            margin: 0 0 2px 0;
        }
        .subtitle {
            display: flex;
            justify-content: space-between;
            h4 {
                text-align: left;
                font-weight: 400;
                font-size: 15px;
                line-height: 17px;
                color: #000000;
                margin: 0;
            }
            .moreBtn {
                background: #E5E5E5;
                border: 1px solid #000000;
                padding: 9px 7px 9px 8px; 
                font-family: 'Arvo';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 16px;
                white-space: nowrap;
                color: #000000;
                width: fit-content;
            }
        }
    }
    @media (max-width: 1201px) {
        .carousel-block__footer {
            width: 100%;
        }
    }
    @media (max-width: 991px) {
        .carousel-block__footer h3 {
            font-size: 25px;
        }
        .carousel-block__footer .subtitle .moreBtn {
            font-size: 12px;
        }
    }
    @media (max-width: 767px) {
        .carousel-block__footer h3 {
            font-size: 22px;
        }
        .carousel-block__footer .subtitle .moreBtn {
            font-size: 11px;
        }
        .carousel-block__footer .subtitle h4 {
            margin-bottom: 15px;
        }

    }
`;

export const CarouselBlockRIght = styled.div.attrs({
    className: "carousel-block"
})`
    // width: 640px;
    height: 360px;
    background: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    @media (max-width: 1201px) {
        width: 100%;
    }
    @media (max-width: 767px) {
        width: 100%;
    }`;

export const ArrowButton = styled.button`
    background: #E6E5E6;
    border: 2px solid #111111;

    &:hover {
        background: #f58220;
    }

    @media screen and (min-width: 1201px): {
        top: calc(50% - 5px);
        left: 55px;
    }`;

export const Slide = styled.div`
    width:100%;
    height:100%;
    padding:18px;
    overflow:hidden;
    display:flex;
    position:relative;
    .heading{
        font-family: "Arvo",Arial,Verdana;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
        width: 100% !important;
        text-transform: capitalize;
        max-width: 93%;
        margin: 0 0 0 auto;
    }
    .slide-text p, .slide-text strong{
        font-family: "Arvo",Arial,Verdana;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
    }
    .content__data{
        .slide-text{
        // font-family: 'Arvo';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        color: #000000;
        }
    }
    .content__imgBox{
        width: 160px;
        height: 200px;
        img{
            width:50%;
        }
    }
    .head_line {
        width: 100%;
        float: left;
    }
.content{
    display:flex;
    margin-top:13px;
    justify-content:flex-start;
    align-items:flex-starts;
    gap:16px;
    padding:0 25px;
    width: 100%;
    float: left;
    max-width: 550px;
    position: unset;
    margin: 0 auto;
    margin-top: 20px;
    .row{
        width: 100%;
        @media (max-width: 767px) {
            .center-text{
                display:flex;
                justify-content: center;
            }
        }
        img{
            width:100%;
            height:100%;
            // object-fit:contain;
        }
    }
    @media (max-width: 480px) {
        &__imgBox{
            margin: 0 auto;
        }
    }

    &__data{
        max-width:350px;
        text-align:left;

        h4{
            font-family: "Arvo",Arial,Verdana;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 17px;    
            color: #FF661F;
            text-transform: capitalize;
        }
        p{
            margin-top:14px;
            font-size:14px;
            font-weight:medium;
        }
    }

}
@media (max-width: 1201px) {
    h4{
        font-size: 18px;
        word-break: break-word;
    }
}
@media (max-width: 991px) {
    .content__data h4 {
        font-size: 16px;
    }
    .left_mobile_response {
        padding: 0;
    }
}
@media (max-width: 767px) {
    .content__imgBox{
        width: 90px;
        height: auto;
        margin-bottom: 10px;
    }
    .content__data{
        max-width: 270px !important;
        text-align: center !important;
        margin: 0 auto !important;
    }
    .row {
        margin: 0;
    }
    .mobile_response {
        margin-top: 10px;
    }
    .head_line .heading{
        text-align: center;
        margin: auto;
    }
    .subtitle h4 p {
        margin-bottom: 0;
    }
    .subtitle h4{
        margin-bottom: 10px;
        display: block;
    }
}
@media (max-width: 361px) {
    .content__imgBox{
        width: 50%;;
    }
    .content .row{
        width: 100% !important;
        position: unset !important;
        left: 0% !important;
        top: 0% !important;
        transform: translate(0px, 0px) !important;
    }
    .content__data h4 {
        font-size: 15px !important;
        margin-top: 20px;
    }
    .content__data .slide-text {
        font-size: 13px;
    }
}
`;

export const LeftButton = styled.div`
button {
    position: absolute;
    z-index: 2;
    top: calc(50% - 25px);
    width: 30px;
    height: 30px;
    cursor: pointer;
    left: 0px;
}
.slick-prev {
    left: 0px !important;
}
@media (max-width: 1201px) {
    button.left_button {
        top: calc(50% - 12px);
        left: 10px;
    }
}
@media (max-width: 991px) {
    button {
        width: 25px;
        height: 25px;
        left: 10px;
    } 
}
`;

export const RightButton = styled.div`
button {
    position: absolute;
    z-index: 2;
    top: calc(50% - 25px);
    width: 30px;
    height: 30px;
    cursor: pointer;
    right: 0px;
}

@media (max-width: 1201px) {
    button.right_button {
        top: calc(50% - 12px);
        right: 10px;
    }
}
@media (max-width: 991px) {
    button {
        width: 25px;
        height: 25px;
        right: 10px;
    } 
}
`;
export const SlideCard = styled.div` `;
export const HomePage = styled.main`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    width: 100%;
    float: left;
    .main-overview {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 10px;
    }
    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 17.5%;
        margin: 1% 2% 1% 0;
        vertical-align: top;
        background-color: #ffffff;
        cursor: pointer;
    }
    @media (max-width: 1201px and min-width: 481px) {
        .poster-item {
            width: 20%;
            margin: 0px;
        }
    }
    @media (max-width: 480px) {
        .poster-item {
            width: 50%;
            margin: 0px;
        }
    }
    .figure__caption{
        font-size: 12px;
        font-family: "Arvo",Arial,Verdana;
    }
    h1.overview {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        font-weight: 400;
        color: black;
        margin-left: 20px;
    }
    .poster-item img {
        width: 100%;
        height: auto;
        display: block;
    }
    .poster-title {
        padding: 5px;
        text-align: center;
        background-color: #e9e9e9;
    }
    .home_page .row .custom_col {
        width: 20%;
    }
    @media (max-width: 767px) {
        .home_page .row .custom_col {
            width: 33.33%;
        }
    }
    @media (max-width: 481px) {
        .home_page .row .custom_col {
            width: 50%;
        }
    }
`;

export const HomeCarousels = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    float: left;
    .slick-slider {
        height: 100%;
    }
    @media (max-width: 1199px) {
        .container{
            max-width: 100%;
        }
    }
    @media (max-width: 361px) {
        .container .row{
            width: 100%;
            margin-right: 0px !important;
            margin-left: 0px !important;
        }
        .carousel-block__footer .subtitle .moreBtn {
            font-size: 11px;
        }
    }
`;

export const ErrorPage = styled.div`
    // height: 100vh;
    margin: 100px 0px;
`;

export const RowPage = styled.main`
    width: 100%;
    float: left;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
`;
export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;