import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    RightList,
} from '../../../containers/upComingMovie/styles';
import { useTranslation } from 'react-i18next';
import CardUI from '../../Card/CardUI';
import Loader from '../../Loader';
import { PosterWrapper } from '../../../containers/aktuelle/styles';
import { Pagination } from 'react-pagination-bar';
import 'react-tooltip/dist/react-tooltip.css'
import { format } from 'date-fns';

const propTypes = {
    films: PropTypes.array.isRequired,
    getUpComingData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    // getUpcomingMovies: PropTypes.func.isRequired
};

const UpcomingMovie = ({
    getUpComingData, films, isLoading,
    // getUpcomingMovies 
}) => {
    const [t] = useTranslation();
    const [LanguageValue] = useState([]);
    const [Films_, setFilms_] = useState([]);
    const [allTime, setAllTime] = useState(films);
    const [currentPage, setCurrentPage] = useState(1);
    const pagePostsLimit = 20;

    const country = process.env.REACT_APP_COUNTRY;

    const UpdateFilmStatLanguage = (LanguageValue) => {
        setFilms_([]);
        films?.length > 0 &&
            films?.map((film) => {
                film?.master?.technologies[0]?.map((arr) => {
                    if (LanguageValue.indexOf(arr) > -1) {
                        setFilms_(Films_ => [...Films_, film])
                    }
                })
            })
        setFilms_(Films_)
    }

    useEffect(() => {
        getUpComingData()
        // getUpcomingMovies()
    }, []);

    useEffect(() => {
        setAllTime(films)
    }, [films]);

    useEffect(() => {
        UpdateFilmStatLanguage(LanguageValue);
    }, [LanguageValue])

    const formatDate = (dateStr, country) => {
        const dateObj = new Date(dateStr);
        if (country === 'aut') {
            // return format(dateObj, 'yyyy-MM-dd');
            return format(dateObj, 'dd.MM.yyyy');
        }
        return format(dateObj, 'dd-MM-yyyy');
    };

    return (
        <>

            <RightList>
                <section className='right_section'>
                    <div className='container'>
                        <PosterWrapper>
                            <div className="text-wrapper poster_title">
                                <h2 className="page-subline-white">{t('movies.coming-movie')}</h2>
                            </div>
                        </PosterWrapper>
                    </div>
                </section>
                <section className='right_section mt-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-11 col-md-11 col-12'>
                                <div className='row'>
                                    {allTime?.length > 0 ?
                                        allTime?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                            ?.map((film, key) => (
                                                <div className='col-lg-3 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center' key={key}>
                                                    {/* <CardUI film={film} startDate={film?.master?.startDate} ></CardUI> */}
                                                    <CardUI film={film} startDate={formatDate(film?.master?.startDate, country)} ></CardUI>
                                                </div>
                                            ))
                                        : <h2>{t('common.no_data')}</h2>
                                    }
                                </div>
                                <div className='row'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={allTime?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </RightList>
            {isLoading && <Loader />}
        </>

    )
}

UpcomingMovie.propTypes = propTypes;

export default UpcomingMovie;

