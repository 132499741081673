import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import layout from "./layout";
import movies from "./movies";
import newsletter from "./newsletter";
import search from "./search";

export const epics = combineEpics(
  ...Object.values(layout.epics),
  ...Object.values(movies.epics),
  ...Object.values(newsletter.epics),
  ...Object.values(search.epics)
);

export const reducers = combineReducers({
  [layout.NAME]: layout.reducer,
  [movies.NAME]: movies.reducer,
  [newsletter.NAME]: newsletter.reducer,
  [search.NAME]: search.reducer,
});
