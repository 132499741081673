import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import FilmGrid from './FilmCard.component';
import layoutModule from '../../../store/layout';

const { requestTopMovies, requestFetchUpcomingMovies } = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getTopMovies: requestTopMovies,
    getLocations: requestLocations,
    getUpComingData: requestFetchUpcomingMovies,
};

const mapStateToProps = ({ layout, movies }) => {
    return ({
        films: movies.films,
        upcoming_films: movies.up_coming_films,
        isLoading: layout.isLoading,
    })
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilmGrid);



