import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import FilmDetail from './FilmDetail.component';
import layoutModule from '../../../store/layout';
import searchModule from "../../../store/search";

const {
    requestMovieDetail,
    requestArchiveMovies,
    requestFetchUpcomingMovies,
    successMovieDetailFetch
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const { searchRequest } = searchModule.actions;

const mapDispatchToProps = {
    findMovie: searchRequest,
    getArchiveMovies: requestArchiveMovies,
    getUpComingData: requestFetchUpcomingMovies,
    getMovieDetail: requestMovieDetail,
    getLocations: requestLocations,
    successMovieDetailFetch
};

const mapStateToProps = ({ movies, layout }) => ({
    films: movies,
    isLoading: layout.isLoading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilmDetail);





