import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { TextWrapper, SideBar, RightList } from '../../../containers/contact/styles';
import { Link, useParams } from 'react-router-dom';
// import placeImage from '../../../assets/media/place_holder/error.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../Loader';
import { TeaserImage } from '../../../containers/contact/styles';
import Banner from '../../../assets/media/banner/header_kontakt.jpg';

const propTypes = {
    getCinemas: PropTypes.func.isRequired,
    partner: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getAboutInfo: PropTypes.func.isRequired,
    contact: PropTypes.array.isRequired,
    info: PropTypes.array.isRequired,
    impressum: PropTypes.array.isRequired
};

const Contact = ({ getCinemas, partner, isLoading, getAboutInfo, contact, info, impressum }) => {
    const params = useParams()
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const [Type, setType] = useState(params.type);
    const [contactSet, setContact] = useState(contact?.[0])
    const [infoSet, setInfo] = useState(info?.[0])
    const [impressumData, setImpressum] = useState(impressum?.[0])

    const ChangeUrl = async (e, id) => {
        e.preventDefault();
        navigate("/aktuelle-filme", { state: id });
    }

    const onPartner = async (e) => {
        e.preventDefault()
        getCinemas()
    }

    useEffect(() => {
        getCinemas()
    }, [Type])

    useEffect(() => {
        setContact(contact?.[0])
        setInfo(info?.[0])
        setImpressum(impressum?.[0])
    }, [contact, info, impressum])

    useEffect(() => {
        setType(params.type)
    }, [params])

    useEffect(() => {
        getCinemas()
        getAboutInfo()
    }, [])


    return (
        <>
            <TeaserImage>
                <h1 className="page-headline">{t('common.contact')}</h1>
                <img src={Banner} style={{ width: '100%' }} />
            </TeaserImage>
            <SideBar>
                <Link to="/about/contact" className={`contact_links ${Type === "contact" ? "active" : ""}`} onClick={() => setType('contact')}>
                    <p>{t('contact.contact')}</p>
                </Link>
                <Link to="/about/info" className={`contact_links ${Type === "info" ? "active" : ""}`} onClick={() => setType('info')}>
                    <p>{t('contact.about')}</p>
                </Link>
                <Link to="/about/partner" className={`contact_links ${Type === "partner" ? "active" : ""}`} onClick={() => { setType('partner'), onPartner }}>
                    <p>{t('contact.partner')}</p>
                </Link>
                <Link to="/about/sitemap" className={`contact_links ${Type === "sitemap" ? "active" : ""}`} onClick={() => setType('sitemap')}>
                    <p>{t('contact.sitemap')}</p>
                </Link>
                <Link to="/about/impressum" className={`contact_links ${Type === "impressum" ? "active" : ""}`} onClick={() => setType('impressum')}>
                    <p>{t('contact.imprint')}</p>
                </Link>
            </SideBar>
            <RightList>
                <div className='row'>
                    <TextWrapper>
                        {Type === 'contact' ?
                            <>
                                <p className="copytext" dangerouslySetInnerHTML={{ __html: i18n?.language === "en" ? contactSet?.contactinfoEN : contactSet?.contactinfo }} />
                            </>
                            : Type === 'info' ?
                                <>
                                    <p className="copytext" dangerouslySetInnerHTML={{ __html: i18n?.language === "en" ? infoSet?.aboutinfoEN : infoSet?.aboutInfo }} />
                                </>
                                : Type === 'partner' ?
                                    <>
                                        <h2 className="page-subline">{t('contact.partner')}</h2>
                                        {partner?.length > 0 &&
                                            partner?.map((film, key) => (
                                                <div className="partner-item" key={key}>
                                                    <div className="partner-image">
                                                        <img src={film?.image} />
                                                    </div>
                                                    <div className="partner-text">
                                                        <p className="partner-titel">{film?.name}</p>
                                                        <p className="partner-copytext">
                                                            {film?.address1}<br></br>
                                                            {film?.address2}<br></br>
                                                        </p>
                                                    </div>
                                                    <div className="partner-buttons">
                                                        <Link to="/aktuelle-filme" onClick={e => ChangeUrl(e, film.id)} className="flat-button flat-button-innertext flat-button-partner">
                                                            <p> {t('contact.cinema_program')} </p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                    : Type === 'sitemap' ?
                                        <>
                                            <h2 className="page-subline"> {t('contact.sitemap')}</h2>
                                            <ul className="sitemap-list">
                                                <li className="sitemap-list-item"><Link to="/">{t('navbar.home')}</Link></li>
                                                <li className="sitemap-list-item"><Link to="/aktuelle-filme">{t('navbar.current')}</Link></li>
                                                <li className="sitemap-list-item"><Link to="/upcoming">{t('navbar.upcoming')}</Link></li>
                                                <li className="sitemap-list-item">{t('navbar.archiv')}</li>
                                                <ul className="sitemap-sublist">
                                                    <li className="sitemap-sublist-item">
                                                        <Link className="sublistlink" to="/film-archive">{t('navbar.film-archiv')}</Link>
                                                    </li>
                                                </ul>
                                                <li className="sitemap-list-item"><Link to="/anfragen">{t('navbar.movie-request')}</Link></li>
                                                <li className="sitemap-list-item"><Link to="/campaigns">{t('navbar.campaigns')}</Link></li>
                                                <li className="sitemap-list-item"><Link to="/about/contact">{t('navbar.contact')}</Link></li>
                                                <ul className="sitemap-sublist">
                                                    <li className="sitemap-sublist-item"><Link to="/about/contact" className="sublistlink" onClick={() => setType('about')}>{t('contact.contact')}</Link></li>
                                                    <li className="sitemap-sublist-item"><Link to="/about/partner" className="sublistlink" onClick={() => setType('partner')}>{t('contact.partner')}</Link></li>
                                                    <li className="sitemap-sublist-item"><Link to="/about/sitemap" className="sublistlink" onClick={() => setType('sitemap')}>{t('contact.sitemap')}</Link></li>
                                                    <li className="sitemap-sublist-item"><Link to="/about/impressum" className="sublistlink" onClick={() => setType('impressum')}>{t('contact.imprint')}</Link></li>
                                                </ul>
                                            </ul>
                                        </>
                                        : Type === 'impressum' ?
                                            <>
                                                <p className="copytext" dangerouslySetInnerHTML={{ __html: i18n?.language === "en" ? impressumData?.impressumEN : impressumData?.impressum }} />
                                                <a className="flat-button flat-button-innertext flat-button-searchresult" href={impressumData?.pDFTermsofUse} target="_blank" rel="noreferrer"><p>{impressumData?.pDFToDescription}</p></a>
                                                <a className="flat-button flat-button-innertext flat-button-searchresult" href={impressumData?.pDFHouseRule} target="_blank" rel="noreferrer"><p>{impressumData?.pDFHRDescription}</p></a>
                                            </>
                                            : console.log()
                        }
                    </TextWrapper>
                </div >
            </RightList >
            {isLoading && <Loader />}
        </>
    )
}

Contact.propTypes = propTypes;

export default Contact;

