import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    TeaserImage,
    SchulaktionenTopleft,
    CscDefault,
    SchulaktionenTopright,
    NlFormwrapper,
    SchoolactionFormwrapper,
    ToastMsg
} from '../../../containers/newsLetter/styles';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Form, TextBox } from 'react-form-elements';
import Select from 'react-select';
import Banner from '../../../assets/media/banner/header_newsletter.jpg';
import { useTranslation } from 'react-i18next';

const propTypes = {
    getPosterData: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    sendNewsletterFormData: PropTypes.func.isRequired,
    deregistrationRequest: PropTypes.func.isRequired,
    output: PropTypes.func,
    emailData: PropTypes.func,
};

const NewsLetter = ({ getPosterData, data, sendNewsletterFormData, output, deregistrationRequest, emailData }) => {
    const [t, i18next] = useTranslation();
    const [defaultForm, setdefaultForm] = useState("subscribe");
    const [FormError, setFormError] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [dataSet, setDataSet] = useState(data?.[0]);
    const [formData, updateFormData] = useState({});
    const errors = {};

    const techCompanies = [
        { value: t('common.title_mr'), label: t('common.title_mr') },
        { value: t('common.title_ms'), label: t('common.title_ms') },
        { value: t('common.title_no_information'), label: t('common.title_no_information') },
    ]
    const group = [
        { value: t('common.group_1'), label: t('common.group_1') },
        { value: t('common.group_2'), label: t('common.group_2') },
        { value: t('common.group_3'), label: t('common.group_3') },
        { value: t('common.group_4'), label: t('common.group_4') },

    ]
    useEffect(() => {
        getPosterData()
    }, []);
    // console.log("output",output)
    // console.log("emailData",emailData)
    useEffect(() => {
        if (output !== undefined) {
            toast.success(t('newsletter.confirmation_email'), {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast_msg_sucess_link'
            });
            setTimeout(() => {
                window.location.reload();
                setBtnDisable(false);
            }, 2000);
        }
    }, [output])
    useEffect(() => {
        if (emailData) {
            toast.success(t('newsletter.unsubscribe_confirmation'), {
                position: toast.POSITION.TOP_CENTER,
                className: 'toast-message_suces'
            });
            setTimeout(() => {
                window.location.reload();
                setBtnDisable(false);
            }, 2000);
        }
    }, [emailData])
    const handleChange = async (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    }
    const handleSalutation = async (change) => {
        updateFormData({
            ...formData,
            'salutation': change.value
        });
    }
    const handleGroup = async (change) => {
        updateFormData({
            ...formData,
            'schoolgroup': change.value
        });
    }
    // console.log("data",data);    
    const hasFormSubmit = (e) => {
        e.preventDefault();
        // if (formData.title === null || formData.title === undefined || formData.title === "") {
        //     errors.title = t('inquiry.title_error');
        // }
        if (formData.salutation === null || formData.salutation === undefined || formData.salutation === "") {
            errors.salutation = t('inquiry.title_error');
        }
        if (formData.firstname === null || formData.firstname === undefined || formData.firstname === "") {
            errors.firstname = t('inquiry.firstname_error')
        }
        if (formData.lastname === null || formData.lastname === undefined || formData.lastname === "") {
            errors.lastname = t('inquiry.lastname_error')
        }
        // if (formData.address === null || formData.address === undefined || formData.address === "") {
        //     errors.address = t('inquiry.address_error')
        // }
        if (formData.postcode === null || formData.postcode === undefined || formData.postcode === "") {
            errors.postcode = t('inquiry.zip_error')
        }
        // if (formData.location === null || formData.location === undefined || formData.location === "") {
        //     errors.location = t('inquiry.location_error')
        // }
        if (formData.schoolname === null || formData.schoolname === undefined || formData.schoolname === "") {
            errors.schoolname = t('inquiry.nameofschool_error')
        }
        // if (formData.telephone === null || formData.telephone === undefined || formData.telephone === "") {
        //     errors.telephone = t('inquiry.phone_error')
        // }
        if (formData.schoolgroup === null || formData.schoolgroup === undefined || formData.schoolgroup === "") {
            errors.schoolgroup = t('inquiry.schoolgroup_error')
        }
        if (formData.email === null || formData.email === undefined || formData.email === "") {
            errors.email = t('inquiry.mail_error')
        }
        setFormError(errors);
        if (Object.keys(errors)?.length === 0) {
            // console.log("submit forData", formData);
            sendNewsletterFormData(formData);
            setBtnDisable(true);
        } else {
            setFormError(errors);
        }
    }

    const hasEmailSubmit = (e) => {
        e.preventDefault();
        if (formData.email === null || formData.email === undefined || formData.email === "") {
            errors.email_unsubscribe = t('inquiry.mail_error')
        }
        setFormError(errors);
        if (Object.keys(errors)?.length === 0) {
            // console.log("submitEamilforData emailData",formData, emailData); 
            deregistrationRequest(false, formData.email);
            setBtnDisable(true);
        } else {
            setFormError(errors);
        }
    }
    const GoesToForm = (e, type) => {
        e.preventDefault();
        setdefaultForm(type)
    }

    useEffect(() => {
        if (data != undefined || data?.length > 0) {
            setDataSet(data[0])
        }
    }, [data]);
    return (
        <>
            <div className='row'>
                <TeaserImage>
                    <h1 className="page-headline">{i18next?.language === "en" ? dataSet?.headline1EN : dataSet?.headline1}</h1>
                    <img src={Banner} style={{ width: '100%' }} />
                </TeaserImage>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-12'>
                        <SchulaktionenTopleft>
                            <CscDefault>
                                <div className='w-100'>
                                    <Link className={"flat-button flat-button-details mb-2 " + ((defaultForm === "subscribe") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "subscribe")}>
                                        <p className="m-0">{t('newsletter.subscribe')}</p>
                                    </Link>
                                </div>
                                <div className='w-100'>
                                    <Link className={"flat-button flat-button-details " + ((defaultForm === "unsubscribe") ? "active" : "")} to="#" onClick={e => GoesToForm(e, "unsubscribe")}>
                                        <p className="m-0">{t('newsletter.unsubscribe')}</p>
                                    </Link>
                                </div>
                            </CscDefault>
                        </SchulaktionenTopleft>
                    </div>
                    <div className='col-lg-8 col-md-8 col-12 pt-3'>
                        <SchulaktionenTopright>
                            {defaultForm === "subscribe" ?
                                <>
                                    <h2 className="page-subline">{i18next?.language === "en" ? dataSet?.headline2EN : dataSet?.headline2}</h2>
                                    <p className="copytext" id="subscribeInput" dangerouslySetInnerHTML={{ __html: i18next?.language === "en" ? dataSet?.descriptionEN : dataSet?.description }} />
                                    <NlFormwrapper>
                                        <Form id="form_container" name="form_sub">
                                            <SchoolactionFormwrapper>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('inquiry.title_')}
                                                            name="title"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="title"
                                                            value={formData.title}
                                                        />
                                                        <p className='error m-0'>{FormError.title}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <div className='form-input'>
                                                            <label>{t('newsletter.salutation')} *</label>
                                                            <Select options={techCompanies}
                                                                className="select_drop"
                                                                name="salutation"
                                                                onChange={handleSalutation}
                                                                id="salutation"
                                                            />
                                                            <p className='error m-0'>{FormError.salutation}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('inquiry.first_name')}
                                                            name="firstname"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="firstname"
                                                            value={formData.firstname}
                                                        />
                                                        <p className='error m-0'>{FormError.firstname}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={` ${t('newsletter.surname')} *`}
                                                            name="lastname"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="lastname"
                                                            value={formData.lastname}
                                                        />
                                                        <p className='error m-0'>{FormError.lastname}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('newsletter.address')}
                                                            name="address"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="address"
                                                            value={formData.address}
                                                        />
                                                        <p className='error m-0'>{FormError.address}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label="PLZ *"
                                                            name="postcode"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="postcode"
                                                            value={formData.postcode}
                                                        />
                                                        <p className='error m-0'>{FormError.postcode}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label="Ort"
                                                            name="location"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="location"
                                                            value={formData.location}
                                                        />
                                                        <p className='error m-0'>{FormError.location}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={` ${t('newsletter.school')} *`}
                                                            name="schoolname"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="schoolname"
                                                            value={formData.schoolname}
                                                        />
                                                        <p className='error m-0'>{FormError.schoolname}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('newsletter.telephone')}
                                                            name="telephone"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="telephone"
                                                            value={formData.telephone}
                                                        />
                                                        <p className='error m-0'>{FormError.telephone}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <div className='form-input'>
                                                            <label>{` ${t('newsletter.group')} *`}</label>
                                                            <Select options={group}
                                                                className="select_drop"
                                                                name="schoolgroup"
                                                                onChange={handleGroup}
                                                                id="schoolgroup"
                                                            />
                                                            <p className='error m-0'>{FormError.schoolgroup}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('inquiry.email_address')}
                                                            name="email"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="email"
                                                            value={formData.email}
                                                        />
                                                        <p className='error m-0'>{FormError.email}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <div className='form-input mobile_response'>
                                                            <label className='w-100 mb-3'></label>
                                                            <input className="form-input-button" onClick={hasFormSubmit} disabled={btnDisable} type="submit" id="subscribeSubmitButton" value={t('newsletter.subscribe')} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-100 mobile_response'>
                                                    <span>{`* ${t('inquiry.required_fields')}`}</span>
                                                </div>
                                            </SchoolactionFormwrapper>
                                        </Form>
                                    </NlFormwrapper>
                                </>
                                :
                                <>
                                    <h2 className="page-subline">{t('newsletter.unsubscribe')}</h2>
                                    <p className="copytext" id="subscribeInput">
                                        {t('newsletter.unsubscribe_first')} <b> {t('newsletter.unsubscribe_first_')} </b> {t('newsletter.unsubscribe_first__')}
                                    </p>
                                    <p>{t('newsletter.unsubscribe_second')} <b>{t('newsletter.unsubscribe_second_')}</b> {t('newsletter.unsubscribe_second__')} <span style={{ color: "#FF661F" }}><b>{t('newsletter.unsubscribe')}</b></span>.</p>
                                    <NlFormwrapper>
                                        <Form id="form_container"
                                            name="form_sub"
                                        >
                                            <SchoolactionFormwrapper>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <TextBox
                                                            label={t('inquiry.email_address')}
                                                            name="email"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            id="email"
                                                            value={formData.email}
                                                        />
                                                        <p className='error m-0'>{FormError.email_unsubscribe}</p>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-xs-12 col-sm-12 col-12'>
                                                        <div className='form-input mobile_response'>
                                                            <label className='w-100 mb-3'></label>
                                                            <input className="form-input-button" onClick={hasEmailSubmit} disabled={btnDisable} type="submit" id="subscribeSubmitButton" value={t('inquiry.send')} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-100 mobile_response'>
                                                    <span>{`* ${t('inquiry.required_fields')}`}</span>
                                                </div>
                                            </SchoolactionFormwrapper>
                                        </Form>
                                    </NlFormwrapper>
                                </>
                            }
                        </SchulaktionenTopright>
                    </div>
                </div>
            </div>
            <ToastMsg>
                <ToastContainer />
            </ToastMsg>
        </>

    )
}

NewsLetter.propTypes = propTypes;

export default NewsLetter;

