import {
    mergeMap,
    startWith,
    concatMap,
} from "rxjs/operators";
import {
    forkJoin,
    from
} from 'rxjs';
import { ofType } from 'redux-observable';
import {
    setLoader,
    setErrorApi,
    separateErrors,
    authErrorHandleAfterManualCatch,
    handleErrors
} from './deps';
import {
    successMoviesRequest,
    setUpcomingMovies,
    successMovieDetailFetch,
    successStateList,
    successDetailOfCinema,
    successFetchUpcomingMoviesData,
    successForSendFormData,
    successForSendCampaignFormData,
    successForSendCampaignOtherFormData,
    successCinemasList,
    setArchiveMovies,
    setCampaingsList,
    setLeftSlider,
    setRightSlider,
    setCompaingnsData,
    setCompaingnsDataList,
    setSchoolTopList,
    setTopFilmTipsList,
    setCinemaData,
    setPosterData,
    successAbountInfoRequest,
    successMoviesRequestLocation,
    successRequestSchoolCinemaData,
    successRequestSchoolCinemaCampaignData,
    successRequestSchoolCinemaCampaignOtherData,
    setSchoolLandingPage
} from './actions';
import {
    TOP_MOVIES_REQUEST,
    UPCOMING_MOVIES_REQUEST,
    GET_MOVIE_DETAIL,
    GET_STATE_LIST,
    GET_DETAIL_OF_CINEMA,
    GET_FETCH_UPCOMING_MOVIES,
    SEND_FORM_DATA,
    SEND_CAMPAIGN_FORM_DATA,
    SEND_CAMPAIGN_OTHER_FORM_DATA,
    CINEMAS_LIST_REQUEST,
    REQUEST_ARCHIVE_MOVIES,
    REQUEST_CAMPAINGS_LIST,
    REQUEST_SCHOOL_LANDING_PAGE,
    REQUEST_LEFT_SLIDER,
    REQUEST_RIGHT_SLIDER,
    REQUEST_CAMPAINGNS,
    REQUEST_CAMPAINGNS_LIST,
    REQUEST_SCHOOL_TOP_MOVIE,
    REQUEST_SCHOOL_TOP_FILM_TIPS,
    REQUEST_CINEMA_DATA,
    REQUEST_POSTER_DATA,
    REQUEST_ABOUT_INFO_DATA,
    TOP_MOVIES_REQUEST_LOCATION,
    GET_CINEMA_REQUEST_DATA,
    GET_CINEMA_CAMPAIGN_REQUEST_DATA,
    GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA,
} from './types';

function fetchTopMovies($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(TOP_MOVIES_REQUEST),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.fetchTop(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successMoviesRequest(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchTopMoviesLocation($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(TOP_MOVIES_REQUEST_LOCATION),
        mergeMap(action => {
            // const { params } = action.payload;
            return from($api.fetchTopOnlyLocation())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successMoviesRequestLocation(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function getSchoolCinemaRequestData($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_CINEMA_REQUEST_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            // console.log("params-----",params);
            return from($api.getRequestData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successRequestSchoolCinemaData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}
function getSchoolCinemaCampaignRequestData($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_CINEMA_CAMPAIGN_REQUEST_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            // console.log("params-----",params);
            return from($api.getCampaignRequestData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successRequestSchoolCinemaCampaignData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}
function getSchoolCinemaCampaignOtherRequestData($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            // console.log("params-----",params);
            return from($api.getCampaignOtherRequestData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successRequestSchoolCinemaCampaignOtherData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchUpcomingMovies($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(UPCOMING_MOVIES_REQUEST),
        mergeMap(action => {
            return from($api.fetchTopp())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setUpcomingMovies(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchMovieDetailSuccess($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_MOVIE_DETAIL),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.fetchMovieDetails(params.id))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successMovieDetailFetch(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchStateList($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_STATE_LIST),
        mergeMap(action => {
            return forkJoin(
                $api.fetchLocationsList().catch(err => ({ err })),
                $api.fetchCinemaShortList().catch(err => ({ err })),
                authErrorHandleAfterManualCatch.bind(null, (data, shortList) => ({
                    data,
                    shortList
                }))
            ).pipe(
                concatMap(res => {
                    const { data, errors, hasErrors } = separateErrors(res);

                    return [
                        successStateList(data),
                        setErrorApi(hasErrors ? errors : null),
                        setLoader(false)
                    ];
                }),

                ...handleErrors(action),

                startWith(setLoader(true))
            );
        })
    );
}

function fetchDetailOfCinema($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_DETAIL_OF_CINEMA),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.fetchGetDetailOfCinema(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successDetailOfCinema(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchUpcomingMoviesData($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(GET_FETCH_UPCOMING_MOVIES),
        mergeMap(action => {
            return from($api.fetchUpcoming())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successFetchUpcomingMoviesData({ data }),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function sendFormDataFunction($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(SEND_FORM_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.sendFormData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successForSendFormData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}
function sendCampaignFormDataFunction($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(SEND_CAMPAIGN_FORM_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.sendCampainFormData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successForSendCampaignFormData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}
function sendCampaignOtherFormDataFunction($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(SEND_CAMPAIGN_OTHER_FORM_DATA),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.sendCampainOtherFormData(params))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successForSendCampaignOtherFormData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function getCinemasList($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(CINEMAS_LIST_REQUEST),
        mergeMap(action => {
            // const { params } = action.payload;
            // return from($api.fetchCinemaList(params))
            return from($api.fetchCinemaList())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            successCinemasList(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchArchiveMovies($action, $state, { api }) {
    const $api = api.getModuleByName('movies');
    return $action.pipe(
        ofType(REQUEST_ARCHIVE_MOVIES),
        mergeMap(action => {
            return from($api.fetchTopFilmArchive())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setArchiveMovies(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchCampaingsList($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_CAMPAINGS_LIST),
        mergeMap(action => {
            return from($api.fetchCampaings())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setCampaingsList(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchSchoolLandingPageDataList($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_SCHOOL_LANDING_PAGE),
        mergeMap(action => {
            return from($api.fetchSchoolLandingPageData())
                .pipe(
                    concatMap(res => {
                        console.log('res', res)
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setSchoolLandingPage(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchLeftSlider($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_LEFT_SLIDER),
        mergeMap(action => {
            return from($api.fetchLeft())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setLeftSlider(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchRightSlider($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_RIGHT_SLIDER),
        mergeMap(action => {
            return from($api.fetchRight())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setRightSlider(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchCampaigns($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_CAMPAINGNS),
        mergeMap(action => {
            return from($api.fetchCampaingns())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setCompaingnsData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchCampaignsList($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_CAMPAINGNS_LIST),
        mergeMap(action => {
            const { params } = action.payload;
            return from($api.fetchCampaingnsList(params?.id))
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setCompaingnsDataList(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchSchoolTopList($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_SCHOOL_TOP_MOVIE),
        mergeMap(action => {
            return from($api.fetchSchoolTopList())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setSchoolTopList(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchTopFilmTipsList($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_SCHOOL_TOP_FILM_TIPS),
        mergeMap(action => {
            return from($api.fetchTopFilmTips())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setTopFilmTipsList(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchCinemaData($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_CINEMA_DATA),
        mergeMap(action => {
            return from($api.fetchCinemaData())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setCinemaData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchPosterData($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_POSTER_DATA),
        mergeMap(action => {
            return from($api.fetchPosterDataD())
                .pipe(
                    concatMap(res => {
                        const { data, errors, hasErrors } = separateErrors(res);
                        return [
                            setPosterData(data),
                            setErrorApi(hasErrors ? errors : null),
                            setLoader(false)
                        ];
                    }),

                    ...handleErrors(action),

                    startWith(setLoader(true))
                );
        })
    );
}

function fetchAboutData($action, $state, { api }) {
    const $api = api.getModuleByName('campaings');
    return $action.pipe(
        ofType(REQUEST_ABOUT_INFO_DATA),
        mergeMap(action => {
            return forkJoin(
                $api.fetchContactData().catch(err => ({ err })),
                $api.fetchAboutInfoData().catch(err => ({ err })),
                $api.fetchImpressumData().catch(err => ({ err })),
                authErrorHandleAfterManualCatch.bind(null, (contact, info, impressum) => ({ contact, info, impressum }))
            ).pipe(
                concatMap(res => {
                    const { data, errors, hasErrors } = separateErrors(res);

                    return [
                        successAbountInfoRequest(data),
                        setErrorApi(hasErrors ? errors : null),
                        setLoader(false)
                    ];
                }),

                ...handleErrors(action),

                startWith(setLoader(true))
            );
        })
    );
}


export {
    fetchTopMovies,
    fetchUpcomingMovies,
    fetchMovieDetailSuccess,
    fetchStateList,
    fetchDetailOfCinema,
    fetchUpcomingMoviesData,
    sendFormDataFunction,
    sendCampaignFormDataFunction,
    sendCampaignOtherFormDataFunction,
    getCinemasList,
    fetchArchiveMovies,
    fetchCampaingsList,
    fetchSchoolLandingPageDataList,
    fetchLeftSlider,
    fetchRightSlider,
    fetchCampaigns,
    fetchCampaignsList,
    fetchSchoolTopList,
    fetchTopFilmTipsList,
    fetchCinemaData,
    fetchPosterData,
    fetchAboutData,
    fetchTopMoviesLocation,
    getSchoolCinemaRequestData,
    getSchoolCinemaCampaignRequestData,
    getSchoolCinemaCampaignOtherRequestData,
};
