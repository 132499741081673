import styled from 'styled-components';

export const TeaserImage = styled.div`
    width: 100%;
    float: left;
`;

export const SchulaktionenTopleft = styled.div`
    background-color: #e9e9e9;
    min-height: 400px;
    text-align: left;
    padding: 20px;
    height: 100%;
    `;

export const CscDefault = styled.div`
        .flat-button-details {
        display: block;
        text-align: center;
        line-height: 32px;
        padding: 0 10px 0 10px;
        margin: 0px auto;
        color: black;
        background-color: #e9e9e9;
        border: 1px solid #000000;
        cursor: pointer;
        pointer-events: auto;
        margin-left: 50%;
        width: 50%;
        padding: 5px 0 5px 0;
        }
        .flat-button p {
            position: relative;
            display: inline-block;
            font-family: "Arvo",Arial,Verdana;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 12px;
            font-weight: 700;
            vertical-align: top;
        }
    `;

export const SchulaktionenTopright = styled.div`
    min-height: 400px;
    background-color: #ffffff;
    padding-bottom: 50px;
        h2.page-subline {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #ff7500;
        }
        p.copytext, div.copytext {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: black;
            margin-top: 20px;
        }   
    `;

export const NlFormwrapper = styled.div`
    // padding: 10px 20px 20px 20px;
`;

export const SchoolactionFormwrapper = styled.div`
        max-width: 80%;
        .select_drop {
            margin-bottom: 10px;
            border: 1px solid #000;
            color: #000;
        }
        .form-input input, textarea.form-input{
            border: 1px solid #000000;
            width: 100%;
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 18px;
            line-height: 40px;
            font-weight: 400;
            color: black;
            padding-left: 10px;
            outline: none;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        label {
            font-size: 12px;
            margin-top: 10px;
        }
        .form-label-pflicht {
            text-align: right;
            font-size: 12px;
            line-height: 18px;
            width: 500px;
            margin-top: 10px;
        }
        .form-input-button {
            border: 1px solid #000000;
            height: 40px;
            text-transform: uppercase;
            font-family: "Arvo",Arial,Verdana;
            font-size: 12px;
            line-height: 36px;
            font-weight: 700;
            color: black;
            padding: 0 20px 0 20px;
            background-color: #e9e9e9;
            cursor: pointer;
            pointer-events: auto;
            text-align: center;
            outline: none;
            border-radius: 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        .rfe-radiogroup__row{
            position:relative;
        }
        .radio_btn .rfe-radiogroup__row input{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        .radio_btn .rfe-radiogroup__row label{
            padding-left:20px;
            margin-top:0;
        }
        .react-time-picker {
            display: inline-block;
            padding: 0;
            border: 0;
            width: 100%;
        }
        .react-time-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: 1px solid #000;
            padding: 7px;
        }
        .react-time-picker__wrapper input {
            border: none !important;
        } 
        input#subscribeSubmitButton {
            width: 60%;
        }       
    `;

export const RowPage = styled.main`
    width: 100%;
    float: left;
    background: #e9e9e97

    .dropdown_index {
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px;    
    }`;

export const SideBar = styled.main`
padding: 20px 40px 20px 40px;
width: 25%;
float:left;
background-color: #e9e9e9;
min-height: 100vh;
@media (max-width: 768px) {
    width:100%;
    float:none;
}
h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
}
@media (max-width: 991px) {
    padding: 20px;
    h3 {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    width:100%;
    float: none;
}
`;

export const RightList = styled.main`
    /* width: 75%; */
    width: 100%;
    float:right;
    padding: 30px 7px;
    background-color: #ffffff;
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/overview_bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media (max-width: 768px) {
        width:100%;
        float:none;
    }
    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 25%;
        vertical-align: top;
    }
    @media (max-width: 767px) {
        .row {
            margin: 0;
        }
    }
`;

export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;

export const CheckBoxes = styled.div`
width: 100%;
float: left;
`;

export const CheckBoxeDiv = styled.div`
    position: relative;
    width: 70%;
    height: 15px;
    cursor: pointer;
    float: left;
    margin: 10px 0 5px 0;
    padding: 3px 0 0 0;
    display: flex;
    .selection {
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 20px;
        padding-left: 10px;
        color: black;
        width: 100%;
    }
`;

export const CardLI = styled.li`
vertical-align: top;
list-style: none;
display: inline-block;
outline: 0;

&:hover{
    .figure__caption{
        background:gray;
        color:#fff;
        font-size: 12px;
        font-family: "Arvo",Arial,Verdana;
    }
}

.poster-title {
    padding: 5px;
    text-align: center;
    background-color: #e9e9e9;
}

.filmtitel, .startdatum {
    position: relative;
    text-transform: none;
    font-family: "Arvo",Arial,Verdana;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: black;
    white-space: normal;
    margin-bottom: 0px;
}
.startdatum {
    font-weight: 700;
}

.figure{
    overflow:hidden;

    &__link{
        text-decoration:none;
        display:flex;
        flex-direction:column;

        img{
            transition : all 0.5s;
        }
    }
    &__caption{
        transition : all 0.5s;
        position:relative;
        z-index;2;
        color:black;
        background-color:lightgray;
        text-align:center;
        text-decoration:none;
    }

    &:hover{
        img{
            scale:1.05;
        }
    }
}
`;


