import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { CarouselBlock } from "../../containers/aktuelle/styles";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// import placeholderImage from '../../assets/media/place_holder/slider_placeholder.png';
import greyImage from '../../assets/media/place_holder/grey.jpg';
import { useNavigate } from "react-router-dom";

const propTypes = {
    dataItem: PropTypes.array.isRequired,
    films: PropTypes.array.isRequired,
    filmListData: PropTypes.array.isRequired,
};

const defaultProps = {
    films: []
};

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
function LeftCarouselClass({
    dataItem,
    films,
    filmListData
}) {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [settings, setsettings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        if (windowSize.innerWidth < 767) {
            var settings_ = {
                dots: false,
                infinite: true,
                speed: 500,
                autoplaySpeed: 4000,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                autoplay: true
            };
            setsettings(settings_)
        } else {
            var settings__ = {
                dots: false,
                infinite: true,
                speed: 500,
                autoplaySpeed: 4000,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true
            };
            setsettings(settings__)
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    const checkValueFilter = (films, id) => {
        // if (films?.length > 0) {
        //     const filtered = films?.filter(film => {
        //         return film.id === id
        //     });
        //     return filtered?.[0]?.master?.posterImage;
        // }
        if (filmListData?.length > 0) {
            // console.log("film>>>>", filmListData)
            const filtered = filmListData?.filter(film => {
                if (id?.slice(0, 2) === 'HO' && film?.id) {
                    return film?.id === id;
                }
                else {
                    if (film?.HOFilmCode) {
                        return film?.HOFilmCode === id;
                    }
                }
            });
            return filtered?.[0]?.posterImage;
        }
    }

    const SchoolTipsMovies = (e, array, link) => {
        e.preventDefault();
        let stringA = JSON.stringify(array);
        let aaa = link.split('/')
        let slug = `${aaa[3]}/${aaa[4]}`
        if (aaa[3] === 'sonderaktion') {
            navigate(slug, { state: { movieId: stringA } });
        }
        else {
            navigate(aaa[3], { state: { movieId: stringA } });
        }
    }
    return (
        <>
            <Slider {...settings}>
                {dataItem.length > 0 &&
                    dataItem.map((item, key) => (
                        <CarouselBlock key={key}>
                            <div className="first_parent" style={{ backgroundImage: `url(${item?.background !== null ? item?.background : greyImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '418px' }}>
                                <div className="main_div">
                                    {item?.linkedMovies?.length > 1 ?
                                        <>
                                            <div className="carousel-block__images">
                                                {
                                                    item?.linkedMovies?.length > 0 &&
                                                    item?.linkedMovies?.map((part, key) => {
                                                        return (
                                                            <div className="multi_img_div" key={key} >
                                                                {/* <img src={checkValueFilter(films, part.HOFilmCode)} className="single_img" /> */}
                                                                <img src={checkValueFilter(films, part.HOFilmCode)} className={item?.linkedMovies?.length >= 5 ? "single_img_more" : "single_img"} />
                                                            </div>
                                                        )
                                                    })
                                                    // : <img src={placeholderImage} className="single_img" key={key} />
                                                }
                                            </div>
                                            <div className="carousel-block__footer">
                                                <h3>{`${item?.headline1}`}</h3>
                                                <div className="subtitle">
                                                    {/* <h4 dangerouslySetInnerHTML={{ __html: item?.alternativeText }}></h4> */}
                                                    <h4 className="content_slider">{`${item?.description}`}</h4>
                                                    {item?.link !== null &&
                                                        <div className="button_right">
                                                            <Link
                                                                to={item?.link}
                                                                onClick={e => SchoolTipsMovies(e, item?.linkedMovies, item?.link)}
                                                                className="moreBtn">MEHR INFOS</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : item?.linkedMovies?.length < 1 ?
                                            <div className="no_one_item">
                                                <div className="head_line">
                                                    <h1 className="heading text-left">{item?.headline1}</h1>
                                                </div>
                                                <div className="content">
                                                    <div className="row" style={{ width: '100%' }}>
                                                        {
                                                            item?.linkedMovies?.length > 0 ?
                                                                item?.linkedMovies?.map((part, key) => {
                                                                    return (
                                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center" key={key}>
                                                                            <div className="content__imgBox">
                                                                                <img src={checkValueFilter(films, part.HOFilmCode)} className="single_img" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : console.log()
                                                        }
                                                        <div className={`${item?.linkedMovies?.length > 0 ? "col-lg-8 col-md-8 " : "col-lg-12 col-md-12 "}col-sm-12 col-12 mt-sm-2 center-text`} >
                                                            <div className="content__data">
                                                                <h4 className="mb-0">{item?.description}</h4>
                                                                {/* <p className="slide-text" dangerouslySetInnerHTML={{ __html: item?.alternativeText }}></p> */}
                                                            </div>
                                                        </div>
                                                        <div className="subtitle">
                                                            {item?.link !== null &&
                                                                <div className="w-100 text-center">
                                                                    <Link
                                                                        // to={checkValue(item?.link)}
                                                                        to="#"
                                                                        onClick={e => SchoolTipsMovies(e, item?.linkedMovies, item?.link)}
                                                                        className="moreBtn">MEHR INFOS</Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="only_single_item">
                                                <div className="head_line">
                                                    <h1 className="heading text-left">{item?.headline1}</h1>
                                                </div>
                                                <div className="content">
                                                    <div className="row" style={{ width: '100%' }}>
                                                        {
                                                            item?.linkedMovies?.length > 0 ?
                                                                item?.linkedMovies?.map((part, key) => {
                                                                    return (
                                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-center" key={key}>
                                                                            <div className="content__imgBox">
                                                                                <img src={checkValueFilter(films, part.HOFilmCode)} className="single_img" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : console.log()
                                                        }
                                                        <div className={`${item?.linkedMovies?.length > 0 ? "col-lg-8 col-md-8 " : "col-lg-12 col-md-12 "}col-sm-12 col-12 mt-sm-2 center-text`} >
                                                            <div className="content__data">
                                                                <h4 className="mb-0">{item?.description}</h4>
                                                                {/* <p className="slide-text" dangerouslySetInnerHTML={{ __html: item?.alternativeText }}></p> */}
                                                            </div>
                                                        </div>
                                                        <div className="subtitle">
                                                            {item?.link !== null &&
                                                                <div className="w-100 text-center">
                                                                    <Link
                                                                        // to={checkValue(item?.link)}
                                                                        to="#"
                                                                        onClick={e => SchoolTipsMovies(e, item?.linkedMovies, item?.link)}
                                                                        className="moreBtn">MEHR INFOS</Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    }
                                </div>
                            </div>
                        </CarouselBlock>
                    ))
                }
            </Slider>
        </>
    )
}

LeftCarouselClass.propTypes = propTypes;
LeftCarouselClass.defaultProps = defaultProps;
export default LeftCarouselClass;