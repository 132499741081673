import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    PosterWrapper,
    SideBar,
    RightList
} from '../../../containers/aktuelle/styles';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import AgeSidebar from '../../Sidebar/AgeSidebar';
import CardUI from '../../Card/CardUI';
import FormInput from '../../FormInput';
import Loader from '../../Loader';
// import { Pagination } from 'react-pagination-bar';
import 'react-pagination-bar/dist/index.css'

const propTypes = {
    films: PropTypes.array.isRequired,
    getUpcomingMovies: PropTypes.func.isRequired,
    getStateList: PropTypes.func.isRequired,
    states: PropTypes.array.isRequired,
    shortList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const FilmList = ({ getUpcomingMovies, films, getStateList, states, shortList, isLoading }) => {

    const [t] = useTranslation();

    const location = useLocation();
    const [State, setState] = useState([]);
    const [state_ID, setstate_ID] = useState(location.state);
    const [Cinema, setCinema] = useState([]);
    const [IsShow, setIsShow] = useState(false);
    const [isFirst, setIsFirst] = useState(false);
    const [Films_, setFilms_] = useState([]);
    const [CheckBoxValue, setCheckBoxValue] = useState([]);
    const [LanguageValue] = useState([]);
    const [SelectedState, setSelectedState] = useState("");
    const [allTime, setAllTime] = useState(films);
    const Direct = films;

    // const [currentPage, setCurrentPage] = useState(1);
    // const pagePostsLimit = 20;

    const handleState = (change, type) => {
        var id = change;
        if (type === "state") {
            setCinema([])
            states?.map((element) => {
                if (element.id === id) {
                    setSelectedState(element.name)
                    shortList?.map((items) => {
                        if (element.items.indexOf(Number(items.id)) > -1) {
                            // console.log(items.id)
                            let item_val = {
                                'label': items.name,
                                'value': items.id,
                            };
                            setCinema(Cinema => [...Cinema, item_val])
                        }
                    })
                }
            })
            setIsFirst(true)
        } else if (type === "cinema") {
            const cinema_id = change.value;
            setstate_ID(change.value)
            let newArray = [];
            films?.length > 0 &&
                films?.map((film) => {
                    if (film?.master?.cinemaIds.indexOf(cinema_id) > -1) {
                        newArray.push(film)
                    }
                })
            setIsShow(true)
            setAllTime(newArray)
        }
    }


    const UpdateFilmState = (CheckBoxValue) => {
        let newArray = [];

        if (CheckBoxValue.length > 0 && CheckBoxValue.indexOf("all") === -1) {
            films?.length > 0 &&
                films?.map((film) => {
                    if (film?.master?.allStateRatings !== null) {
                        const ddd = film?.master?.allStateRatings;
                        if (ddd) {
                            const data = JSON.parse(ddd);
                            let uniqueAuthors = data?.stateFsk?.filter((valuedata, index, self) =>
                                index === self.findIndex((t) => (
                                    t?.fsk?.value === valuedata?.fsk?.value
                                ))
                            )
                            uniqueAuthors.map(item => {
                                if (item?.fsk) {
                                    const ageValue = item?.fsk?.value;
                                    if (ageValue !== 0 && CheckBoxValue.indexOf(ageValue) > -1) {
                                        if (!newArray.includes(film)) {
                                            newArray.push(film)
                                        }
                                    }
                                    else if (ageValue <= 0 && CheckBoxValue.indexOf("unrestricted") > -1) {
                                        if (!newArray.includes(film)) {
                                            newArray.push(film)
                                        }
                                    }


                                }
                            })

                        }
                    }

                })
            setAllTime(newArray)
        }
        else if (CheckBoxValue.indexOf("all") > -1) {
            setAllTime(films)
        }
        else {
            setAllTime(films)
        }

    }

    const UpdateFilmStatLanguage = (LanguageValue) => {
        if (LanguageValue.length > 0) {
            let filmArray = [];
            if (Films_.length > 0) {
                Films_?.length > 0 &&
                    Films_?.map((film) => {
                        film?.master?.technologies[0].map((arr) => {
                            if (LanguageValue.indexOf(arr) > -1) {
                                filmArray.push(film)
                            }
                        })
                    })
            } else {
                films?.length > 0 &&
                    films?.map((film) => {
                        film?.master?.technologies[0].map((arr) => {
                            if (LanguageValue.indexOf(arr) > -1) {
                                filmArray.push(film)
                            }
                        })
                    })
            }
            setAllTime(filmArray)
        } else {
            setAllTime(Direct);
        }
    }

    useEffect(() => {
        UpdateFilmStatLanguage(LanguageValue);
    }, [LanguageValue])

    useEffect(() => {
        if (states.length > 0) {
            states?.map((element) => {
                if (state_ID !== null) {
                    if (element.items.includes(Number(state_ID))) {
                        setSelectedState(element.name);
                    }
                }
                let state_val = {
                    'label': element.name,
                    'value': element.id,
                };
                setState(State => [...State, state_val])
            })
        }
        if (state_ID !== null) {
            states?.map((element) => {
                if (element.id === state_ID) {
                    shortList?.map((items) => {
                        if (element.items.indexOf(Number(items.id)) > -1) {
                            let item_val = {
                                'label': items.name,
                                'value': items.id,
                            };
                            setCinema(Cinema => [...Cinema, item_val])
                        }
                    })
                }
            })
        }
    }, [states])

    useEffect(() => {
        if (state_ID !== 0 && state_ID !== null) {
            const cinema_id = state_ID;
            films?.length > 0 &&
                films?.map((film) => {
                    if (film?.master?.cinemaIds.indexOf(cinema_id) > -1) {
                        setFilms_(Films_ => [...Films_, film])
                    }
                })
            setIsShow(true)
        }
        setAllTime(films)
    }, [films]);

    useEffect(() => {
        UpdateFilmState(CheckBoxValue);
    }, [CheckBoxValue])

    const customStyles = {
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#ff7400" : null,
                color: "#000"
            };
        }
    };

    useEffect(() => {
        getStateList();
        getUpcomingMovies();
    }, []);

    return (
        <>
            <SideBar>
                <>
                    <h3>{t('movies.sidebar-title')}</h3>
                    <FormInput
                        type={"select"}
                        option={State}
                        className="select_drop mb-3"
                        onChange={e => handleState(e.value, "state")}
                        name="state"
                        styles={customStyles}
                        values={State.filter(obj => obj.label === SelectedState)}
                        noOptionsMessage={() => t('common.no_data_here')}
                    />
                    {isFirst &&
                        <FormInput
                            type={"select"}
                            option={Cinema}
                            className="select_drop"
                            onChange={e => handleState(e, "cinema")}
                            name="state"
                            styles={customStyles}
                            values={Cinema.filter(obj => obj.value === state_ID)}
                            noOptionsMessage={() => t('common.no_data_here')}
                        />
                    }
                    <hr></hr>
                    {
                        IsShow &&
                        <>
                            <h3>{t('movies.age_rating')}</h3>
                            <AgeSidebar setCheckBoxValue={setCheckBoxValue} CheckBoxValue={CheckBoxValue}></AgeSidebar>
                            <div className="clearfix"></div>
                        </>
                    }
                </>
            </SideBar>
            <RightList>
                <section className='right_section'>
                    <div className='container'>
                        <PosterWrapper>
                            <div className="text-wrapper poster_title">
                                <h2 className="page-subline-white">{t('movies.current-movie-title')}</h2>
                            </div>
                        </PosterWrapper>
                    </div>
                </section>
                <section className='right_section mt-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-11 col-md-11 col-12'>
                                <div className='row'>
                                    {allTime?.length > 0 ?
                                        // allTime?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                        allTime?.map((film, key) => (
                                            <div className='col-lg-3 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center' key={key}>
                                                <CardUI film={film} startDate={film?.master?.startDate} ></CardUI>
                                            </div>
                                        ))
                                        : <h2>{t('common.no_data')}</h2>
                                    }
                                </div>
                                {/* <div className='row'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={allTime?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </RightList>
            {isLoading && <Loader />}
        </>

    )
}

FilmList.propTypes = propTypes;

export default FilmList;