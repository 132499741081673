const movies = {
  pattern: '/movies',
  name: 'Movies',
  tracked: true
};

export default {
  fallback: movies,

  /**
   * Movies
   */
  movies,
  movieDetails: {
    pattern: '/movies/:id',
    name: 'Movie Details',
    tracked: true
  },

  /**
   * Custom Start
   */
  start: {
    pattern: '/movies?category=upcoming&date=all',
    name: 'Coming Soon',
    tracked: true
  },

  /**
   * Cinemas
   */
  cinemas: {
    pattern: '/cinemas',
    name: 'Cinemas',
    tracked: true
  },
  cinemaDetails: {
    pattern: '/cinemas/:id',
    name: 'Cinema Details',
    tracked: true
  },

  /**
   * Auth
   */
  login: {
    pattern: '/sign-in',
    name: 'Login',
    tracked: true
  },
  registration: {
    pattern: '/sign-up',
    name: 'Registration',
    tracked: true
  },
  resetPassword: {
    pattern: '/password-reset',
    name: 'Reset Password',
    tracked: true
  },

  /**
   * Search
   */
  search: {
    pattern: '/search',
    name: 'Search',
    tracked: true
  },

  /**
   * Info
   */
  info: {
    pattern: '/info',
    name: 'Info'
  },
  impressum: {
    pattern: '/info/impressum',
    name: 'Impressum',
    tracked: true
  },
  legal: {
    pattern: '/info/legal-info',
    name: 'Legal Info',
    tracked: true
  },
  b2b: {
    pattern: '/info/b2b',
    name: 'B2B',
    tracked: true
  },
  priceList: {
    pattern: '/info/price-list/:id',
    name: 'Price List',
    tracked: true
  },
  bonusCardInfo: {
    pattern: '/info/bonus-card',
    name: 'Bonus Card',
    tracked: true
  },

  /**
   * Purchase
   */
  purchase: {
    pattern: '/purchase',
    name: 'Purchase'
  },
  wizard: {
    pattern: '/purchase/wizard/:movieCinemaId',
    name: 'Wizard'
  },
  stepTickets: {
    pattern: '/purchase/wizard/:movieCinemaId/tickets',
    name: 'Purchase Ticket Selection',
    tracked: true
  },
  stepSeats: {
    pattern: '/purchase/wizard/:movieCinemaId/seats',
    name: 'Purchase Seats Selection',
    tracked: true
  },
  stepCheckout: {
    pattern: '/purchase/wizard/:movieCinemaId/checkout',
    name: 'Purchase Checkout',
    tracked: true
  },

  paymentSuccess: {
    pattern:
      process.env.REACT_APP_COUNTRY === 'bih' || process.env.REACT_APP_COUNTRY === 'bil'
        ? '/purchase/payment/success/:userSessionId'
        : '/purchase/payment-success/:userSessionId',
    name: 'Payment Success',
    tracked: true
  },
  paymentFailure: {
    pattern:
      process.env.REACT_APP_COUNTRY === 'bih' || process.env.REACT_APP_COUNTRY === 'bil'
        ? '/purchase/payment/error/:id'
        : '/purchase/payment-failure/:id',
    name: 'Payment Failure',
    tracked: true
  },
  reservationSuccess: {
    pattern: '/purchase/reservation-success/:userSessionId',
    name: 'Reservation Success',
    tracked: true
  },

  /**
   * Account
   */
  account: {
    pattern: '/account',
    name: 'My Account'
  },
  user: {
    pattern: '/account/user',
    name: 'My Account Personal Data',
    tracked: true
  },
  userEdit: {
    pattern: '/account/user/edit',
    name: 'My Account Edit Personal Data',
    tracked: true
  },
  userChangePassword: {
    pattern: '/account/user/change-password',
    name: 'My Account Change Password',
    tracked: true
  },
  tickets: {
    pattern: '/account/tickets',
    name: 'My Account My Tickets',
    tracked: true
  },
  ticketsBought: {
    pattern: '/account/tickets/bought/:cinemaId/:bookingId',
    name: 'My Account Ticket Details',
    tracked: true
  },
  ticketsReserved: {
    pattern: '/account/tickets/reserved/:cinemaId/:bookingId',
    name: 'My Account Reservation Details',
    tracked: true
  },
  history: {
    pattern: '/account/history',
    name: 'My Account History',
    tracked: true
  },
  bonusCard: {
    pattern: '/account/bonus-card',
    name: 'My Account Bonus Card',
    tracked: true
  },
  giftCard: {
    pattern: '/account/gift-card',
    name: 'My Account Gift Card',
    tracked: true
  },

  /**
   * Events
   */
  events: {
    pattern: '/events',
    name: 'Events',
    tracked: true
  },
  eventDetails: {
    pattern: '/events/:id',
    name: 'Event Details',
    tracked: true
  },
  fixedEventDetails: {
    pattern: '/events/306',
    name: 'Event Details',
    tracked: true
  },

  /**
   * Others
   */
  error: {
    pattern: '/error',
    name: 'Error'
  },
  no_internet: {
    pattern: '/no-internet',
    name: 'No Internet'
  },
  style: {
    pattern: '/style'
  },
  cookies: {
    pattern: '/pdfs/cookie_policy-:lang.pdf'
  }
};
