import base from './base';

const hexs = {
  hex1: '#de1619',
  hex2: '#be0003',
  hex4: '#191919',
  hex5: '#2d2d2d',
  hex6: '#fff',
  hex7: '#a1a1a1',
  hex8: '#888',
  hex9: '#323232',
  hex10: '#242424',
  hex11: '#4b4b4b',
  hex12: '#222222',
  hex13: '#666666',
  hex14: '#E0D9CC',
  hex15: '#D32C2A'
};

const theme = {
  color: {
    accent: hexs.hex1,
    accentDark: hexs.hex2,
    bgPrimary: hexs.hex4,
    bgSecondary: hexs.hex5,
    textPrimary: hexs.hex6,
    textSecondary: hexs.hex7,
    borderLight: hexs.hex8,
    borderDark: hexs.hex9,
    labelColor: hexs.hex8,
    svgInput: hexs.hex8,
    bgPopup: hexs.hex10,
    borderPopup: hexs.hex11,
    black: hexs.hex12,
    grey: hexs.hex13,
    bgFamilyClub: hexs.hex14,
    bgRed: hexs.hex15,

    rgbaWhite(a) {
      return `rgba(255, 255, 255, ${a})`;
    },
    rgbaAccent(a) {
      return `rgba(222, 22, 25, ${a})`;
    },
    rgbaBlack(a) {
      return `rgba(0, 0, 0, ${a})`;
    }
  },

  indent: {
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '36px',
    xxl: '48px'
  },

  breakPoints: {
    xl: {
      width: '1200px',
      header: '64px'
    },
    md: {
      // <!-- desktop
      width: '992px',
      header: '64px'
    },
    sm: {
      // <!-- tablet
      width: '740px',
      header: '48px',
      mobileNav: '52px'
    },
    xs: {
      width: '576px'
    }
  }
};

export const sublistItemInner = `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.indent.s} ${theme.indent.m};
  font: 400 1.8rem/1.5 BrandonGrotesque, sans-serif;
  text-transform: capitalize;
  color: ${theme.color.textPrimary};
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  cursor: pointer;
  
  &._active {
    color: ${theme.color.accent};
    pointer-events: none;
  }

  &:hover {
    color: ${theme.color.accent};
    background-color: ${theme.color.rgbaWhite('.05')};
  }
`;

export const inputGeneric = `
  display: flex;
  align-items: center;
  height: 2.223em;
  font-size: 1.8rem;
  border: 1px solid ${theme.color.rgbaWhite('.4')};
  border-radius: 1.2em;
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
`;

export const linkNormal = `
  color: ${theme.color.textSecondary};
  transition: color .1s ease-out;
  
  &:hover {
    color: ${theme.color.textPrimary};
  }
  
  &._active {
    pointer-events: none;
    color: ${theme.color.textPrimary};
  }
`;

export const withChevron = `
  & > ._with-chevron {
    position: relative;
    padding-right: 20px;
    cursor: default;

    &::after {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border: 1px solid ${theme.color.borderLight};
      border-width: 0.15em 0.15em 0 0;
      position: absolute;
      top: 0.25em;
      right: 0;
      transform: rotate(135deg);
      transition: transform .18s ease-out, border-color .2s ease-out;
    }
  }
`;

export const withChevronHover = `
  &::after {
    border-color: ${theme.color.rgbaWhite('.8')};
    transform: rotate(315deg) translate(-4px, 4px);
  }
`;

export const withLinkUnderlined = `
  text-decoration: underline;
  
  @media screen and (min-width: ${theme.breakPoints.md.width}) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const baseCss = base(theme);

export default theme;


