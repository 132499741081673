import styled from 'styled-components';

export const CarouselBlock = styled.div.attrs({
    className: "carousel-block"
})`
    width: 640px;
    // height: 360px;
    // background: linear-gradient(180deg, #C9A491 0%, rgba(243, 206, 151, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .carousel-block__images {
        display: flex;
        // width: 384px;
        min-height: 150px;
        margin-top:70px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        // background: #ffffffab;
        background: #fff;
        .image {
            width: 80px;
            height: 116px;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
        img.single_img {
            // width: 100%;
            width:60%;
            // margin: 0px 5px;
        }
        img.single_img_more {
            width: 100%;
             height: 120px;
             object-fit: contain;
            // margin: 0px 5px;
        }
        .multi_img_div {
            margin: 0px 5px;
        }
        .multi_img_div:last-child {
            margin-right: 0px; 
        }
        .multi_img_div:first-child {
            margin-left: 0px;
        }
        // img.single_img:first-child{
        //     margin-left: 0px;
        // }
        // img.single_img:last-child {
        //     margin-right: 0px;
        // }
        @media (max-width: 1399px) {
            // img.single_img {
            //     height: 100px;
            // }
        }
        @media (max-width: 991px) {
            .image {
                width: 70px;
            }
        }
    }
    .subtitle {
        width: 100%;
        display: flow-root;
    }
    h4.content_slider {
        float: left;
        max-width: 70%;
    }
    .button_right {
        float: right;
    }
    .first_parent{
        
        padding:10px;
        height: 100%;
        // min-height: 360px;
        min-height: 418px;
    }   
    
    .right_slider{
        background : rgb(211, 211, 211);
    }
    .moreBtn {
        border: 1px solid #000000;
        font-size: 14px;
        padding: 7px;
    }
    .moreBtn:hover {
        color: #fff;
        background: #ff7400;
        border-color: #ff7400;
    }
    .main_div {
        // width: 384px;
        width: 80%;
        margin: 0 auto;
        // transform: translate(-50%, -50%);
        // top: 50%;
        // left: 50%;
        // position: relative;

        @media (max-width: 767px) {
            width: 320px;
        }
    }
    .carousel-block__footer {
        // width: 384px;
        padding: 13px 10px;
        background: #ffffff;
        margin-top: 10px;
        h3 {
            text-align: left;
            font-weight: 700;
            font-size: 28px;
            line-height: 35px;
            color: #000000;
            margin: 0 0 2px 0;
            text-transform: uppercase;
            font-family: "Arvo",Arial,Verdana;
        }
        .subtitle {
            // display: flex;
            // justify-content: space-between;
            h4 {
                text-align: left;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #000000;
                margin: 0 0 10px 0;
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .heading {
        font-family: "Arvo",Arial,Verdana;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
        width: 100% !important;
        text-transform: capitalize;
    }
    .content__data h4 {
        font-family: "Arvo",Arial,Verdana;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #FF661F;
        text-transform: capitalize;
    }
    .slide-text p{
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0px;
    } 
    .slide-text strong {
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
    }
    // .only_single_item {
    //     margin: 7.5% 0px;
    // }
    .only_single_item .subtitle {
        margin-top: 20px;
    }
    .only_single_item .content__data {
        min-height: 250px;
        max-height: 250px;
        // min-height: 279px;
        // max-height: 279px;
    }
    .no_one_item .content__data {
        min-height: 250px;
        max-height: 250px;
        // min-height: 279px;
        // max-height: 279px;
    }
    .only_single_item .subtitle .text_right {
        text-align: right;
    }
    .no_one_item {
        margin: 10% 0px;
    }
    .no_one_item .subtitle {
        margin-top: 6px;
    }
    .content__imgBox {
        margin-top: 15px;
    }
    .placeholder_imgBox, .placeholder_imgBox img.placeholder_img {
        width: 100%;
    }
    // .right_slider .only_single_item{
    //     margin: 10% 0px;
    // }
    .no_one_item h4.heading.text-left {
        color: #ff7400;
    }
    @media (max-width: 1399px) {
        .only_single_item {
            margin: 10% 0px;
        }
        .carousel-block__footer {
            margin-top: 14px;
        }
        .no_one_item .subtitle {
            margin-top: 21px;
        }
        .no_one_item {
            margin: 10% 0px;
        }
        .only_single_item .content__imgBox img.single_img {
            width: 100%;
        }
    }
    @media (max-width: 1199px) {
        .no_one_item {
            margin: 13% 0px;
        }
        .only_single_item .subtitle {
            margin-top: 10px;
        }
        .only_single_item {
            margin: 14% 0px;
        }
        .carousel-block__footer {
            margin-top: 40px;
        }
        .no_one_item .subtitle {
            margin-top: 18px;
        }
        .only_single_item .content__imgBox img.single_img {
            width: 100%;
        }
    }
    @media (max-width: 1100px) {
        .no_one_item {
            margin: 15% 0px;
        }
        .only_single_item {
            margin: 16% 0px;
        }
        .only_single_item {
            margin: 16% 0px;
        }
        .only_single_item .subtitle {
            margin-top: 11px;
        }
        .right_slider .only_single_item{
            margin: 20% 0px;
        }
    }
    @media (max-width: 1024px){
        .no_one_item {
            margin: 15% 0px;
        }
        .right_slider .only_single_item{
            margin: 0% 0px;
        }
    }
    @media (max-width: 991px) {
        .right_slider .main_div {
            width: 100%;
        }
        .head_line .textCenter {
            text-align: center;
        }
        .main_div {
            width: 330px;
        }
        .carousel-block__footer h3 {
            font-size: 24px;
        }
        .only_single_item {
            margin: 4% 0px;
        }
        .only_single_item .subtitle {
            margin-top:20px;
        }
        .no_one_item {
            margin: 3% 0px;
        }
        .no_one_item .subtitle {
            margin-top: 20px;
        }
        .carousel-block__images {
            min-height: 250px;
        }
        .carousel-block__footer {
            margin-top: 15px;
        }
        .heading {
            text-align: center;
        }
        .no_one_item h4.mb-0 {
            text-align: center;
        }
    }
    @media (min-width: 1399px) {
        .right_slider .main_div {
            width: 100%;
            padding:20px;
        }
    }

    @media (max-width: 769px) {
        .carousel-block__images img.single_img {
            height: auto;
            width: 70px;
        }
        .carousel-block__footer {
            margin-top: 33px;
        }
        .no_one_item {
            margin: 0% 0px;
        }
        .only_single_item {
            margin: 0% 0px;
        }
        .content__data h4 {
            max-width: 100%;
            margin: 0 auto;
        }
        .carousel-block__footer {
            margin-top: 20px;
        }
        .carousel-block__images {
            min-height: 225px;
            margin-top: 22px;
        }
        .right_slider .main_div {
            width: 100%;
        }
        .right_content_data .set_div {
            max-width: 90%;
            margin: 0 auto;
        }
    }
    @media (max-width: 767px) {
        .right_slider .main_div {
            width: 100%;
        }
        .right_content_data {
            margin-top: 40px !important;
        }
        .carousel-block__footer{
            text-align: center;
            // padding: 5px 10px;
            padding: 0px 4px;
            float: left;
            width: 100%;
        }
        .carousel-block__footer h3 {
            font-size: 24px;
            text-align: center;
        }
        h4{
            text-align: center !important;
        }
        .subtitle h4 p{
            margin-bottom: 0;
        }
        .subtitle {
            display: unset !important;
        }
        .moreBtn {
            margin: 10px 0px;
            padding: 5px;
            display: inline-block;
        }
        .content__imgBox img.single_img {
            height: 70px;
        }
        .carousel-block__images {
            min-height: 245px;
        }
        .only_single_item .content__imgBox img.single_img {
            height: 200px;
        }
        .only_single_item .content__data, .no_one_item .content__data {
            min-height: 200px;
            max-height: 200px;
        }
        .first_parent {
            padding: 10px;
            display: flex !important;
            align-items: center !important;
        }
        .carousel-block__images img.single_img {
            width: 110px;
        }
        .no_one_slide_right {
            min-height: unset !important;
            max-height: unset !important;
        }
    }
    @media (max-width: 540px) {
        .carousel-block__images {
            min-height: 190px;
        }
        .no_one_item .subtitle {
            margin-top: 23px;
        }
        .only_single_item .subtitle .text_right {
            text-align: center;
        }
        .carousel-block__images img.single_img {
            width: 100%;
        }
    }
    @media (max-width: 441px) {
        height: auto;
        .main_div {
            width: 100% !important;
            height: 100%;
            // min-height: 300px;      
        }
        // .carousel-block__images img.single_img {
        //     width: 60px;
        //     height: auto;
        // }
        .first_parent {
            display: flex !important;
            align-items: center !important;
        }
        .content__imgBox img.single_img {
            height: 150px;
        }
        .content__data h4 {
            font-size: 18px;
            line-height: 26px;
        }
        .slide-text strong{
            font-weight: 600;
        }
       .slide-text p, .slide-text strong {
            font-size: 14px !important;
            text-align: center;
        }
        .content__imgBox {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .no_one_item {
            margin: 3% 0px;
        }
        .no_one_item .subtitle {
            margin-top: 20px;
        }
        .only_single_item.mb-0 h3.single_img_title {
            font-size: 14px;
            text-transform: lowercase;
            text-align: center;
        }
        .right_content_data {
            max-width: 80%;
            margin: 0 auto;
        }
    }
    @media (max-width: 414px) {
        .only_single_item .content__data, .no_one_item .content__data {
            min-height: 248px;
            max-height: 248px;
        }
    }
    @media (max-width: 393px) {
        .only_single_item .content__data, .no_one_item .content__data {
            min-height: 230px;
            max-height: 230px;
        }
    }
    @media (max-width: 375px) {
        .only_single_item .content__data, .no_one_item .content__data {
            min-height: 222px;
            max-height: 222px;
        }
    }
    @media (max-width: 361px) {
        .no_one_item .subtitle {
            margin-top: 24px;
        }
        .only_single_item .content__data, .no_one_item .content__data {
            min-height: 222px;
            max-height: 222px;
        }
    }
    h3.single_img_title {
        font-family: "Arvo",Arial,Verdana;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        color: #FF661F;
        // text-transform: capitalize;
    }
    .content__data p.slide-text p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
    }
    // h1.heading {
    //     font-family: "Arvo",Arial,Verdana;
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 22px;
    //     line-height: 30px;
    //     color: #FF661F;
    // }
`;

export const CarouselBlockRIght = styled.div.attrs({
    className: "carousel-block"
})`
    width: 640px;
    height: 360px;
    // background: lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 441px) {
        height: auto;
    }
`;

export const ArrowButton = styled.button`
    background: #E6E5E6;
    border: 2px solid #111111;

    &:hover {
        background: #f58220;
    }
`;

export const CardLI = styled.li`
vertical-align: top;
list-style: none;
display: inline-block;
outline: 0;
width: 100%;

.figure__caption{
    background:gray;
    color:#fff;
    font-size: 12px;
    // font-family: "Arvo",Arial,Verdana;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

figure.figure{
    width: 100%;
}
figure.figure svg.svg-preloader {
    display: none;
}
.poster-title {
    padding: 5px;
    text-align: center;
    background-color: #e9e9e9;
}

.filmtitel, .startdatum {
    position: relative;
    text-transform: none;
    // font-family: "Arvo",Arial,Verdana;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: black;
    white-space: normal;
    margin-bottom: 0px;
}
.startdatum {
    font-weight: 700;
}

.figure{
    overflow:hidden;

    &__link{
        text-decoration:none;
        display:flex;
        flex-direction:column;

        img{
            transition : all 0.5s;
        }
    }
    &__caption{
        transition : all 0.5s;
        position:relative;
        z-index:2;
        color:black;
        background-color:lightgray;
        text-align:center;
        text-decoration:none;
    }

    &:hover{
        img{
            scale:1.05;
        }
    }
}
`;

export const PosterWrapper = styled.li`
list-style: none;
margin-bottom: 25px;
div.poster_title {
    background: white;
    padding: 10px;
    display:inline-block;
}
h2.page-subline-white {
    text-transform: none;
    // font-family: "Arvo",Arial,Verdana;
    font-size: 22px;
    line-height: 0px;
    font-weight: 500;
    color: black;
    background-color: #ffffff;
    // padding: 10px 20px 10px 20px;
    display: inline-block;
    margin: 0;
}
.sub-text {
    font-size: 14px;
    margin-bottom: 0px;
    // font-family: "Arvo",Arial,Verdana;
}
`;

export const SideBar = styled.main`
    padding: 20px 40px 20px 40px;
    width: 25%;
    float:left;
    background-color: #e9e9e9;
    h3 {
        // font-family: 'Arvo';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
    }
    .clearfix:after {
        clear: both;
        display: table;
        content: "";
    }
    @media (max-width: 991px) {
        padding: 20px;
        width:30%;
        h3 {
            font-size: 18px;
        }
    }
    @media (max-width: 767px) {
        width:100%;
        float: none;
    }
`;

export const RightList = styled.main`
    width: 75%;
    float:right;
    @media (max-width: 991px) {
        width:70%;
    }
    @media (max-width: 768px) {
        h2.page-subline-white {
            font-size: 20px;
        }
    }
    padding: 30px 7px;
    background-color: #ffffff;
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/overview_bg.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    section.right_section {
        width: 100%;
        float: left;
    }
    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 25%;
        vertical-align: top;
    }
    @media (max-width: 767px) {
        width:100%;
        float: none;
        .row {
            margin: 0;
        }
    }
`;

export const CheckBoxes = styled.div`
width: 100%;
float: left;
`;
export const CheckBoxeDiv = styled.div`
    position: relative;
    width: 70%;
    height: 15px;
    cursor: pointer;
    float: left;
    margin: 10px 0 5px 0;
    padding: 3px 0 0 0;
    display: flex;
    .selection {
        // font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 20px;
        padding-left: 10px;
        color: black;
        width: 100%;
    }
    .add-space{
        padding: 0px 4px;
    }
`;

export const RowPage = styled.main`
    width: 100%;
    float: left;
    background-color: #e9e9e9;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px;    
}`;

export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;
