import React from 'react';
import { Wrapper } from './styles';
import gif from '../../assets/media/orange.gif';

const Loader = () => {
  return (
    <Wrapper>
      <img src={gif} className="loader_class" />
    </Wrapper>
  )
}

export default Loader;
