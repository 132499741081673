import styled from 'styled-components';

export const RowPage = styled.main`
    width: 100%;
    float: left;
    background: #e9e9e9;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px; 
    }
`;
export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;
export const SideBar = styled.main`
    // padding: 20px 40px 20px 40px;
    padding: 20px 0px 20px 40px;
    width: 25%;
    float:left;
    background-color: #e9e9e9;
    @media (max-width: 767px) {
        padding: 10px;
        width: 30%;
    }
    @media (max-width: 480px) {
        padding: 10px;
        width: 100%;
    }
    a.contact_links {
        position: relative;
        float: right;
        min-width: 200px;
        width: 50%;
        margin: 10px 20px 0 0;
        padding: 5px 0 5px 0;
        background-color: #e9e9e9;
        border: 1px solid #000000;
        text-align: center;
    }
    a.contact_links.active {
        color: #fff;
        background: #ff7400;
        border-color: #ff7400;
    }
    @media (max-width: 1201px) {
        a.contact_links {
            min-width: 125px;
            width: 100%;
            margin: 10px 0px 0 0;
        }
    }
    @media (max-width: 480px) {
        a.contact_links {
            min-width: 0px;
            width: 100%;
            margin: 10px 0px 0 0;
        }
    }
    a.contact_links p {
        display: inline-block;
        font-family: "Arvo",Arial,Verdana;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 0px;
        padding: 10px;
    }
`;
export const RightList = styled.main`
    width: 75%;
    float:right;
    padding: 20px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    @media (max-width: 767px) {
        padding: 10px;
        width: 70%;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
    .poster-item {
        position: relative;
        display: inline-block;
        left: 0;
        width: 25%;
        vertical-align: top;
    }
`;
export const TeaserImage = styled.div`
    width: 100%;
    float: left;
    position: relative;
    h1.page-headline {
        position: absolute;
        top: 10%;
        left: 10%;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
        @media (max-width: 481px) {
            top: 5%;
            left: 5%;
        }
    }
`;
export const TextWrapper = styled.div`
    padding: 20px;
    width: 75%;
    h2 span, h2.page-subline {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ff7500;
    }
    p.copytext, div.copytext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin-top: 20px;
    }
    a.textlink {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #ff7500;
        text-decoration: underline;
    }
    .partner-item {
        position: relative;
        width: 100%;
        display: inline-block;
        margin-top: 20px;
        // padding-bottom: 0px;
        border-bottom: 1px solid #cccccc;
    }
    .partner-image {
        position: relative;
        width: 30%;
        display: inline-block;
        float: left;
    }
    .partner-image img {
        width: 100%;
        height: auto;
        display: block;
    }
    .partner-text {
        position: relative;
        width: 37%;
        display: inline-block;
        float: left;
        padding: 10px 10px 10px 20px;
        text-align: left;
        vertical-align: top;
    }
    .partner-titel {
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        white-space: normal;
    }
    .partner-copytext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        white-space: normal;
        vertical-align: top;
        margin-top: 10px;
    }
    .partner-buttons {
        position: absolute;
        bottom: 30px;
        display: inline-block;
        float: left;
        text-align: left;
        width: 100%;
    }
    .flat-button-partner {
        // min-width: 160px;
        // color: #ffffff;
        // background: #a8a8a8;
        // border: 1px solid #a8a8a8;
        // display: block;
        // text-align: center;
        // padding: 10px 0px;
        position: relative;
        text-align: center;
        margin: 0px auto;
        color: black;
        background-color: #a8a8a8;
        display: -ms-inline-flexbox;
        margin-right: 10px;
    }
    .flat-button-partner p {
        margin-bottom: 0px;
    }
    ul.sitemap-list {
        margin: 20px 0 20px 0;
        list-style: none;
        padding: 0;
    }
    li.sitemap-list-item, li.sitemap-sublist-item {
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 16px;
        line-height: 30px;
        margin: 0;
        padding: 0;
    }
    li.sitemap-list-item a.listlink, li.sitemap-sublist-item a.listlink {
        font-weight: 400;
        color: black;
        text-decoration: none;
    }
    ul.sitemap-sublist {
        margin: 0 0 5px 20px;
        list-style: none;
        padding: 0;
    }
    li.sitemap-sublist-item {
        text-transform: none;
        font-size: 14px;
        line-height: 26px;
    }
    li.sitemap-sublist-item a.sublistlink {
        font-weight: 400;
        color: #ff7500;
        text-decoration: none;
    }
    h3 {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin-top: 20px;
    }
    p.imprinttext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: black;
        margin-top: 20px;
    }
    .flat-button-innertext {
        color: #ffffff;
        background-color: #a8a8a8;
        display: block;
    }
    .flat-button {
        position: relative;
        display: block;
        text-align: center;
        line-height: 32px;
        padding: 0 10px 0 10px;
        margin: 0px auto;
        color: black;
        background-color: #e9e9e9;
        cursor: pointer;
        pointer-events: auto;
        display: inline-flex;
        margin-right: 10px;
    }
    .flat-button-innertext p {
        margin-bottom: 0px;
    }
    .flat-button:hover, .flat-button-active {
        text-decoration: none;
        color: #ffffff;
        background-color: #ff7500;
        // border: 1px solid #ff7500;
    }
    p.imprinttext {
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: black;
        margin-top: 20px;
    }
    p.imprinttext b {
        text-transform: uppercase;
        font-weight: 700;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 5px;
        .partner-text {
            width: 70%;
        }
        .partner-text{
            padding: 0px 0px 10px 10px;
        }
        .partner-titel{
            font-size: 16px;
            margin-bottom: 5px;
        }
        .partner-buttons{
            width: 100%;
            position: unset;
            text-align: center;
        }
        .flat-button {
            font-size: 13px;
            width: 160px;
            margin-bottom: 10px;
        }
    }
`;
