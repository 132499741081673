import { format, differenceInCalendarDays } from 'date-fns';

export const getDisplayNameHOC = Component =>
  Component.displayName || Component.name || 'Component';

export const getPreValidationError = (field, translate) =>
  field.meta.error && field.meta.dirty && translate(...field.meta.error);

export const getPreValidationDropdownError = (field, translate) =>
  field.meta.error && field.meta.touched && translate(...field.meta.error);

export const getPostValidationError = (errors, fieldName, translate) =>
  errors && fieldName && translate(errors[fieldName]);

export const isFormDisabled = ({
  pristine,
  submitting,
  invalid,
  dirtySinceLastSubmit,
  hasSubmitErrors,
  hasValidationErrors
}) =>
  pristine ||
  submitting ||
  (!dirtySinceLastSubmit && hasSubmitErrors) ||
  (!hasSubmitErrors && invalid) ||
  hasValidationErrors;

export const dateValidator = date =>
  !!date &&
  new Date(date).toString() !== 'Invalid Date' &&
  /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/g.test(date);

/* eslint-disable no-param-reassign */
export const arrayToFlat = arr => {
  return Array.isArray(arr)
    ? arr.reduce((flatten, item) => {
      flatten = flatten.concat(Array.isArray(item) ? arrayToFlat(item) : [item]);

      return flatten;
    }, [])
    : [];
};
/* eslint-enable no-param-reassign */

export const sessionsReducer = (sessions, getDateWithOffset, isMovieDetails = false) => {
  /* eslint-disable no-param-reassign */
  return sessions.reduce(
    (cinemasWithTickets, currentSession) => {
      const { id, cinemaId, cinemaName, screenName, showtime, status } = currentSession;

      let parseShowTime = showtime;

      if (showtime.substring(19, 20) === '+' || showtime.substring(19, 20) === '-') {
        parseShowTime = showtime.substring(0, 19);
      }
      const showTimeDate = new Date(parseShowTime);
      const showTime = format(showTimeDate, 'HH:mm');

      const technologies = arrayToFlat(currentSession.technologies).join(', ');
      const ticket = {
        sessionId: id,
        screenName,
        showTime,
        technologies,
        status
      };

      if (!isMovieDetails) {
        cinemasWithTickets.push(ticket);

        return cinemasWithTickets;
      }

      if (!cinemasWithTickets[cinemaId]) {
        cinemasWithTickets[cinemaId] = {
          cinemaId,
          cinemaName,
          tickets: [ticket]
        };
      } else {
        cinemasWithTickets[cinemaId].tickets.push(ticket);
      }

      return cinemasWithTickets;
    },

    isMovieDetails ? {} : []
  );
  /* eslint-enable no-param-reassign */
};

export const onHoverBehavior = {
  onClick(e) {
    e.currentTarget.classList.add('_hover');
  },

  onMouseEnter(e) {
    e.currentTarget.classList.add('_hover');
  },

  onMouseLeave(e) {
    e.currentTarget.classList.remove('_hover');
  }
};

export const onClickBehavior = {
  onClick(e) {
    e.stopPropagation();
    e.currentTarget.parentNode.parentNode.classList.remove('_hover');
  }
};

export const getFormattedDateTickets = (dateToFormat = null, t, getDateWithOffset) => {
  const date = getDateWithOffset(dateToFormat);
  const now = getDateWithOffset();
  const daysDiff = differenceInCalendarDays(date, now);

  let dayOfTheWeek = t('show_long_day', { date });

  if (daysDiff === 0) {
    dayOfTheWeek = t('common.today');
  } else if (daysDiff === 1) {
    dayOfTheWeek = t('common.tomorrow');
  }

  const shortMonth = t(`common.short_month_${`0${date.getMonth() + 1}`.slice(-2)}`);

  const dayToShow = daysDiff === 0 ? dayOfTheWeek : `${date.getDate()} ${shortMonth}`;

  const hours = format(date, 'HH:mm');
  const result = `${dayToShow}, ${hours}`;

  return result.replace(new RegExp(String.fromCharCode(8206), 'g'), '');
};

export const getSortedAlphaNum = () => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  return [...Array(10).keys(), ...alphabet];
}