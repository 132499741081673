import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { reducers, epics } from './store';

const INITIAL_STATE = {};

export default function configureStore(config,
   api
    ) {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      api
    }
  });

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    INITIAL_STATE,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(epics);

  return store;
}
