import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import TopSchoolMovies from './TopFilmTips.component';

const {
    getTopFilmTips,
    requestTopMovies,
    requestMovieDetail,
} = movieModule.actions;

const mapDispatchToProps = {
    fetchTopFilmTips: getTopFilmTips,
    getTopMovies: requestTopMovies,
    getMovieDetail: requestMovieDetail,
};

const mapStateToProps = ({ movies }) => (
    {
        movies: movies.data,
        films: movies.filmsDetail,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopSchoolMovies);