import styled from 'styled-components';

export const TeaserImage = styled.div`
    width: 100%;
    float: left;
    position: relative;
    h1.page-headline {
        position: absolute;
        top: 10%;
        left: 10%;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
        @media (max-width: 481px) {
            top: 5%;
            left: 5%;
        }
    }
`;

export const SchulaktionenTopleft = styled.div`
    background-color: #e9e9e9;
    min-height: 400px;
    text-align: left;
    padding: 20px;
    height: 100%;
    @media (max-width: 767px) {
        min-height: auto;
    }`;

export const SchulaktionenTopright = styled.div`
    min-height: 400px;
    background-color: #ffffff;
    padding-bottom: 50px;
        h2.schulaktionen-anmeldung {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: black;
            margin: 20px 0 0 20px;
        } 
        @media (max-width: 767px) {
            .row {
                margin: 0;
            }
        }   
    `;

export const CscDefault = styled.div`
    float: right;
    max-width: 250px;
        h2.page-subline {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #ff7500;
        }
        .schulaktionen-copytext-sidebar-last {
            border-style: none;
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: black;
            white-space: normal;
            padding: 10px 0 10px 0;
            border-bottom: 1px solid #cccccc;
        }
        @media (max-width: 767px) {
            max-width: 100%;
        }
    `;

export const SchoolactionFormwrapper = styled.div`
        padding: 0px 17px 20px 0px;
        max-width: 500px;
        .select_drop {
            margin-bottom: 10px;
            border: 1px solid #000;
            color: #000;
        }
        .css-1s2u09g-control {
            border: none;
        }
        .form-input input, textarea.form-input{
            border: 1px solid #000000;
            width: 100%;
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 18px;
            line-height: 40px;
            font-weight: 400;
            color: black;
            padding-left: 10px;
            outline: none;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        label {
            font-size: 12px;
            // margin-top: 10px;
            // text-transform: capitalize;
        }
        .diff_div label {
            margin: 0px;
        }
        .tooltip{
            background-color: "yellow";
        }
        label.form-label {
            display: none;
        }
        legend {
            text-transform: capitalize;
        }
        .info{
            background-color: gray;
        }
        p.error {
            color: #f00;
            font-size: 12px;
        }
        .form-label-pflicht {
            text-align: right;
            font-size: 12px;
            line-height: 18px;
            width: 500px;
            margin-top: 10px;
        }
        .form-input-button {
            border: 1px solid #000000;
            height: 40px;
            text-transform: uppercase;
            font-family: "Arvo",Arial,Verdana;
            font-size: 12px;
            line-height: 36px;
            font-weight: 700;
            color: black;
            padding: 0 20px 0 20px;
            background-color: #e9e9e9;
            cursor: pointer;
            pointer-events: auto;
            text-align: center;
            outline: none;
            border-radius: 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        .rfe-radiogroup__row{
            position:relative;
        }
        .radio_btn .rfe-radiogroup__row input{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        .radio_btn .rfe-radiogroup__row label{
            padding-left:20px;
            margin-top:0;
        }
        .react-datepicker-wrapper {
            display: block;
        }
        .react-time-picker {
            display: inline-block;
            padding: 0;
            border: 0;
            width: 100%;
        }
        .react-time-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: 1px solid #000;
            padding: 7px;
        }
        .react-time-picker__wrapper input {
            border: none !important;
        }  
        div#companion input[type="radio"]:nth-child(2), div#subscribe input[type="radio"]:nth-child(2) {
            margin-left: 50px;
        }
        div#companion input[type="radio"], div#subscribe input[type="radio"] {
            margin-right: 5px;
        }  
        div#cinemabuffet input[type="radio"]:nth-child(2), div#subscribe input[type="radio"]:nth-child(2) {
            margin-left: 50px;
        }
        div#cinemabuffet input[type="radio"], div#subscribe input[type="radio"] {
            margin-right: 5px;
        }  
        h2 {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: black;
            margin: 40px 0 20px;
        } 
        span.rc-time-picker.time_picker_rc {
            display: block;
        }
        span.rc-time-picker.time_picker_rc input#time {
            line-height: 32px;
            padding-left: 10px;
            height: auto;
        }   
    `;


