import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '../../../utils';
import { useConfig } from '../../hooks';
import Button from '../Button';
// import Loader from '../Loader';
import PlayerVendor from './PlayerVendor';
import { Wrapper, Error } from './styles';

const propTypes = {
  id: PropTypes.string.isRequired,
  keyFrameUrl: PropTypes.string.isRequired
};

const Player = ({ id, keyFrameUrl }) => {
  const {
    player: { jquery, url }
  } = useConfig();
  const [isLoaded, setLoaded] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [isError, setError] = useState(false);

  const videoLoadedCb = useCallback(setLoader.bind(null, false), []);

  const setErrorCb = useCallback(() => {
    setLoader(false);
    setError(true);
  }, []);

  const retryCb = useCallback(() => {
    setError(false);
    setLoader(true);
    setLoaded(false);
  }, []);

  /**
   * Load Vendor Player Scripts
   */
  useEffect(() => {
    if (isLoader && !window.uvp_loaded) {
      loadScript(jquery)
        .then(loadScript.bind(null, url))
        .then(() => setLoaded(true))
        .catch(setErrorCb);
    }

    if (isLoader && window.uvp_loaded) {
      setLoaded(true);
    }
  }, [url, jquery, isLoader]);

  return (
    <Wrapper>
      {isLoader ? (
        <div className="b-vendor-video__thumb">
          <img src={keyFrameUrl} className="b-vendor-video__thumb-img" alt="" />
        </div>
      ) : null}

      {isLoaded ? (
        <PlayerVendor isHidden={isLoader} id={id} onVideoLoaded={videoLoadedCb} onError={setErrorCb} />
      ) : null}

      {isError ? (
        <Error>
          <h5 className="b-vendor-video__error-title">message</h5>
          <Button isDefault onClick={retryCb}>
            play
          </Button>
        </Error>
      ) : null}

      {/* {isLoader ? <Loader /> : null} */}
    </Wrapper>
  );
};

Player.propTypes = propTypes;
export default Player;
