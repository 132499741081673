import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import Contact from './Contact.component';
import layoutModule from '../../../store/layout';

const {
    requestCinemasList,
    requestAboutInfo
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    getCinemas: requestCinemasList,
    getLocations: requestLocations,
    getAboutInfo: requestAboutInfo,
};

const mapStateToProps = ({ movies, layout }) => (
    {
        partner: movies.partner,
        isLoading: layout.isLoading,
        contact: movies.contact,
        info: movies.info,
        impressum: movies.impressum,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);





