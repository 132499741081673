import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './app/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <I18nextProvider i18n={i18n}>
     <App />
    </I18nextProvider>
);