import styled from 'styled-components';

export const TeaserImage = styled.div`
    width: 100%;
    float: left;
    position: relative;
    h1.page-headline {
        position: absolute;
        top: 10%;
        left: 10%;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
        @media (max-width: 481px) {
            top: 5%;
            left: 5%;
        }
    }
`;
export const SchulaktionenTopleft = styled.div`
    background-color: #e9e9e9;
    min-height: 400px;
    text-align: left;
    padding: 20px;
    height: 100%;
    @media (max-width: 767px) {
        min-height: 100px;
        margin: 15px 0px;
        height: auto;
    }`;

export const CscDefault = styled.div`
        .flat-button-details {
            display: block;
            text-align: center;
            line-height: 32px;
            padding: 0 10px 0 10px;
            margin: 0px auto;
            color: black;
            background-color: #e9e9e9;
            border: 1px solid #000000;
            cursor: pointer;
            pointer-events: auto;
            margin-left: 50%;
            width: 50%;
            padding: 5px 0 5px 0;
            @media (max-width: 991px) {
                margin: 0 auto;
                width: 70%;
            }
            @media (max-width: 767px) {
                margin: 0 auto;
            }
            @media (max-width: 480px) {
                margin-left: 0px;
                width: 100%;
            }
        }
        .flat-button p {
            position: relative;
            display: inline-block;
            font-family: "Arvo",Arial,Verdana;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 12px;
            font-weight: 700;
            vertical-align: top;
        }
        @media (max-width: 480px) {
            .flat-button p {
                font-size: 10px;
            }
        }
        a.flat-button.flat-button-details.active {
            background: #ff7400;
            color: #fff;
            border-color: #ff7400;
        }
    `;

export const SchulaktionenTopright = styled.div`
    min-height: 400px;
    background-color: #ffffff;
    padding-bottom: 50px;
        h2.page-subline {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #ff7500;
        }
        p.copytext, div.copytext {
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: black;
            margin-top: 20px;
        }   
        p.error{
            color:rgb(255, 0, 0);            
            font-size: 12px;
        }
    `;

export const NlFormwrapper = styled.div`
    // padding: 10px 20px 20px 20px;
    .css-1s2u09g-control {
        border: none;
    }
`;

export const SchoolactionFormwrapper = styled.div`
        max-width: 80%;
        .select_drop {
            margin-bottom: 10px;
            border: 1px solid #000;
            color: #000;
        }
        .form-input input, textarea.form-input{
            border: 1px solid #000000;
            width: 100%;
            text-transform: none;
            font-family: "Arvo",Arial,Verdana;
            font-size: 18px;
            line-height: 40px;
            font-weight: 400;
            color: black;
            padding-left: 10px;
            outline: none;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        label {
            font-size: 12px;
            margin-top: 10px;
        }
        .form-label-pflicht {
            text-align: right;
            font-size: 12px;
            line-height: 18px;
            width: 500px;
            margin-top: 10px;
        }
        .form-input-button {
            border: 1px solid #000000;
            height: 40px;
            text-transform: uppercase;
            font-family: "Arvo",Arial,Verdana;
            font-size: 12px;
            line-height: 36px;
            font-weight: 700;
            color: black;
            padding: 0 20px 0 20px;
            background-color: #e9e9e9;
            cursor: pointer;
            pointer-events: auto;
            text-align: center;
            outline: none;
            border-radius: 0;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        .rfe-radiogroup__row{
            position:relative;
        }
        .radio_btn .rfe-radiogroup__row input{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }
        .radio_btn .rfe-radiogroup__row label{
            padding-left:20px;
            margin-top:0;
        }
        .react-time-picker {
            display: inline-block;
            padding: 0;
            border: 0;
            width: 100%;
        }
        .react-time-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: 1px solid #000;
            padding: 7px;
        }
        .react-time-picker__wrapper input {
            border: none !important;
        } 
        input#subscribeSubmitButton {
            width: 60%;
        }  
        @media (max-width: 991px) {
            max-width: 100%;
        }
        @media (max-width: 767px) {
            max-width: 100%;
            .mobile_response {
                text-align: center;
            }
        }
    `;
export const ToastMsg = styled.div`
    .toast-message {
    width: 24vw;
    /* padding: 30px 20px; */
}
    .toast-message_suces{   
    width: 22vw;
    /* padding: 30px 20px; */
}
.toast_msg_sucess_link{
    width: 42vw;
}
`



