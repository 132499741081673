import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export default styled(({ isPrimary, isDefault, sm, ...props }) => {
  if (props.to) {
    return <NavLink {...props} />;
  }

  console.log(isPrimary)
  console.log(isDefault)
  console.log(sm)

  if (props.href) {
    const { children, ...rest } = props;

    return (
      <a href="/" {...rest}>
        {children}
      </a>
    );
  }

  return <button type="button" {...props} />;
})`
  min-width: 115px;
  font: ${({ sm }) => (sm ? '500' : '700')} 1.4rem/1 BrandonGrotesque, sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textPrimary};
  border-radius: 2em;

  ${({ isDefault, disabled, sm }) =>
    isDefault &&
    !disabled &&
    css`
      padding: ${sm ? '0.43em 1.1em' : '0.645em 1.15em'};
      border: 2px solid ${({ theme }) => theme.color.rgbaWhite('.5')};
      transition: background-color 0.2s ease-out, border-color 0.2s ease-out,
        box-shadow 0.2s ease-out;
      &:hover {
        color: ${({ theme }) => theme.color.bgPrimary};
        border-color: transparent;
        background-color: ${({ theme }) => theme.color.rgbaWhite('.8')};
        box-shadow: 0 3px 16px 0 ${({ theme }) => theme.color.rgbaWhite('.16')};
      }
    `}

  ${({ isPrimary, disabled, sm }) =>
    isPrimary &&
    !disabled &&
    css`
      padding: ${sm ? '0.5em 1.1em' : '0.79em 1.15em'};
      background-color: ${({ theme }) => theme.color.accent};
      box-shadow: 0 3px 16px 0 ${({ theme }) => theme.color.rgbaAccent('.16')};
      transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;
      &:hover {
        background-color: ${({ theme }) => theme.color.accentDark};
        box-shadow: 0 3px 16px 0 ${({ theme }) => theme.color.rgbaAccent('.5')};
      }
    `}

  ${({ isDefault, disabled, sm }) =>
    isDefault &&
    disabled &&
    css`
      padding: ${sm ? '0.5em 1.1em' : '0.79em 1.15em'};
      border: 2px solid ${({ theme }) => theme.color.rgbaWhite('.05')};
      color: ${({ theme }) => theme.color.rgbaWhite('.2')};
    `}

  ${({ isPrimary, disabled, sm }) =>
    isPrimary &&
    disabled &&
    css`
      padding: ${sm ? '0.5em 1.1em' : '0.79em 1.15em'};
      background-color: ${({ theme }) => theme.color.rgbaWhite('.05')};
      color: ${({ theme }) => theme.color.rgbaWhite('.2')};
    `}
`;
