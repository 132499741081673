import PropTypes from 'prop-types';
import React from "react";
import { Form } from "react-bootstrap";
import Select from 'react-select';
import {
    TextBox
} from 'react-form-elements';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    RadioGroup,
    Radio
} from 'react-radio-group'
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import de from 'date-fns/locale/de';

registerLocale('de', de)

const propTypes = {
    type: PropTypes.string,
    option: PropTypes.array,
    label: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    films: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.number,
    styles: PropTypes.object,
    onError: PropTypes.string,
    values: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    selected: PropTypes.array,
    radioOption: PropTypes.array,
    autoComplete: PropTypes.string
};

function FormInput({ type, option, label, className, id, onChange, name, styles, onError, value, selected, radioOption, rows, values, noOptionsMessage, autoComplete }) {
    return (
        <>
            {type !== "checkbox" && label && <Form.Label></Form.Label>}

            {(() => {
                switch (type) {
                    case "password":
                        return (
                            console.log("password")
                        );
                    case "select":
                        return (
                            <>
                                <Select options={option} className={className} value={values} onChange={onChange} name={name} styles={styles} noOptionsMessage={noOptionsMessage} />
                                <p className='error'>{onError}</p>
                            </>
                        );
                    case "text":
                        return (
                            <>
                                <TextBox label={label} name={name} className={className} onChange={onChange} value={value} id={id} />
                                <p className='error'>{onError}</p>
                            </>
                        );
                    case "textarea":
                        return (
                            <>
                                <div className='form-input'>
                                    <label>{label}</label>
                                    <textarea className="form-input"
                                        onChange={onChange}
                                        id={id}
                                        name={name}
                                        rows={rows}></textarea>
                                    <p className='error'>{onError}</p>
                                </div>
                            </>
                        );
                    case "date":
                        return (
                            <>
                                <DatePicker
                                    selected={selected}
                                    className={className}
                                    name={name}
                                    id={id}
                                    onChange={onChange}
                                    placeholderText='TT/MM/JJJJ'
                                    autoComplete={autoComplete}
                                    locale={de}
                                    dateFormat='dd/MM/yyyy'
                                    minDate={new Date()}
                                />
                                <p className='error'>{onError}</p>
                            </>
                        );
                    case "time":
                        return (
                            <>
                                <TimePicker
                                    showSecond={false}
                                    onChange={onChange}
                                    name={name}
                                    id={id}
                                    className={`${className} time_picker_rc`}
                                    // use12Hours
                                    hideDisabledOptions
                                    placeholder='hh:mm'
                                // locale="en-US"
                                />
                                <p className='error'>{onError}</p>
                            </>
                        );
                    case "radio":
                        return (
                            <>
                                <RadioGroup
                                    selectedValue={selected}
                                    onChange={onChange}
                                    name={name}
                                    id={id}
                                >
                                    {
                                        radioOption.length > 0 && radioOption.map((item, key) => {
                                            return (
                                                <>
                                                    <Radio value={item.value} key={key} />{item.label}
                                                </>
                                            );
                                        })
                                    }
                                </RadioGroup>
                                <p className='error'>{onError}</p>
                            </>
                        );
                    case "checkbox":
                        return (
                            console.log("checkbox")
                        );

                    case "file":
                        return (
                            console.log("file")
                        );

                    case "creatableSelect":
                        return (
                            console.log("creatableSelect")
                        );

                    case "groupCheckbox":
                        return (
                            console.log("groupCheckbox")
                        );

                    default:
                        return (
                            console.log("default input")
                        );
                }
            })()}
        </>
    );
}

FormInput.propTypes = propTypes;
export default FormInput;
