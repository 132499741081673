import React,{ useEffect} from 'react';
import PropTypes from 'prop-types';
import {  useLocation, useNavigate   } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { ToastMsg } from '../../../containers/newsLetter/styles';
import { useTranslation } from 'react-i18next';

const propTypes = {
    deactivate: PropTypes.func.isRequired,
    // result: PropTypes.func.isRequired
};
const NewsLetterDeRegistration = ({deactivate}) =>{ 

        const[t] = useTranslation();
        const search = useLocation().search;
        const searchParams = new URLSearchParams(search);
        const email = searchParams.get('email');
        const navigate = useNavigate();

        // console.log("result",result)

        useEffect(()=>{
            if(search.length){
                deactivate(true,email);
                    toast.success(t('newsletter.newsletter_successfully_unsubscribe'), {
                        position: toast.POSITION.TOP_CENTER,
                        className: 'toast-message_suces'
                    });
                setTimeout(() => {
                    navigate('/')
                },5000);
                } 
        },[search])
    return(
        <div>
        <h1 style={{textAlign:"center"}}>Newsletter Abmeldung</h1>
        <ToastMsg>
        <ToastContainer/>
        </ToastMsg>
        </div>
    )
}

NewsLetterDeRegistration.propTypes = propTypes;

export default NewsLetterDeRegistration;