import BaseAPI from '../BaseAPI';

class Movies extends BaseAPI {
  constructor(props) {
    super(props);

    this.baseUrl = '/movies';
  }

  /**
   * Finds a movie by its title
   *
   * @param body {Object}
   * @param body.title {string}
   * @param body.technology {Array}
   * @param body.version {Array}
   * @param params {Object}
   * @param params.date {string}
   * @param params.location {string}
   * @returns {Promise<*|Error>}
   */
  find(body, params) {
    const url = `${this.baseUrl}/search/advanced`;

    return this.post({ url, body, params });
  }

  /**
   * Gets filters of category and versions for the advanced search
   * *
   * @returns {Promise<*|Error>}
   */
  fetchFilters() {
    const url = `${this.baseUrl}/filters/list`;

    return this.get({ url });
  }

  /**
   * Gets a top movies list
   *
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchTop(params) {
    const url = `${this.baseUrl}/schoolcinema`;
    const version = '/api/v1';
    const location = this.getLocation();

    return this.get({ url, params: { ...params, location }, version });
  }

  fetchTopOnlyLocation() {
    const url = `${this.baseUrl}/schoolcinema`;
    const version = '/api/v1';
    const location = this.getLocation();

    return this.get({ url, params: { location }, version });
  }

  fetchTopp() {
    const url = `${this.baseUrl}/schoolcinema`;
    const version = '/api/v1';

    return this.get({ url, version });
  }
  fetchTopFilmArchive() {
    const url = `/moviesarchiv/schoolcinema`;
    const version = '/api/v1';

    return this.get({ url, version });
  }

  /**
   * Gets a now playing movies list
   *
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchNowPlaying(params = {}) {
    const url = `${this.baseUrl}`;
    const version = '/api/v1';
    const location = this.getLocation();

    return this.get({ url, params: { ...params, location }, version });
  }

  /**
   * Gets an upcoming movies list
   *
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchUpcoming() {
    const url = `${this.baseUrl}/schoolcinema/coming-soon`;
    const version = '/api/v1';
    return this.get({ url, version });
  }

  sendFormData(body) {
    const url = `/schoolcinema/request`;
    const version = '/api/v1';
    return this.post({ url, body, version });
  }

  sendCampainFormData(body) {
    const url = `/schoolcinemacampaign/request`;
    const version = '/api/v1';
    return this.post({ url, body, version });
  }
  sendCampainOtherFormData(body) {
    const url = `/schoolcinemacampaignother/request`;
    const version = '/api/v1';
    return this.post({ url, body, version });
  }
  getRequestData(params) {
    const url = `/schoolcinema/request`;
    const version = '/api/v1';
    // console.log("version",version ,url, params);
    return this.get({ url, params: { ...params }, version });
  }
  getCampaignRequestData(params) {
    const url = `/schoolcinemacampaign/request`;
    const version = '/api/v1';
    // console.log("version",version ,url, params);
    return this.get({ url, params: { ...params }, version });
  }
  getCampaignOtherRequestData(params) {
    const url = `/schoolcinemacampaignother/request`;
    const version = '/api/v1';
    return this.get({ url, params: { ...params }, version });
  }


  /**
   * Gets a movie details by the id
   *
   * @param movieId {string}
   * @returns {Promise<*|Error>}
   */
  fetchMovieDetails(movieId) {
    const url = `${this.baseUrl}/${movieId}`;

    return this.get({ url });
  }

  /**
   * Gets a sessions list for a certain movie
   *
   * @param movieId {string}
   * @param params {Object}
   * @param params.date {string | undefined}
   * @returns {Promise<*|Error>}
   */
  fetchGroupedSessions(movieId, params) {
    const url = `${this.baseUrl}/${movieId}/sessions`;
    const version = '/api/v1';
    const location = this.getLocation();

    return this.get({ url, params: { ...params, location }, version });
  }

  /**
   * Gets sessions list for a certain movie for all dates
   *
   * @param movieId {string}
   * @returns {Promise<*|Error>}
   */
  fetchAllDatesSessions(movieId) {
    const url = `${this.baseUrl}/${movieId}/sessions`;
    const version = '/api/v3';
    const location = this.getLocation();

    return this.get({ url, params: { location }, version });
  }

  /**
   * Gets a months list for the upcoming movies dates filter
   *
   * @param params {Object}
   * @param params.comingSoon {boolean}
   * @returns {Promise<*|Error>}
   */
  fetchMonthsList(params) {
    const url = `${this.baseUrl}/filters/months/list`;
    const version = '/api/v1';

    return this.get({ url, params, version });
  }

  /**
   * Provides a list of dates for a filter in the movies and cinemas pages
   *
   * @param params {Object}
   * @param params.cinemaId {string | undefined}
   * @param params.top {boolean | undefined}
   * @param params.id {string | undefined} - movie id
   * @param params.location {string | null}
   * @return {Promise<*|Error>}
   */
  fetchDatesList(params = {}) {
    const url = `${this.baseUrl}/filters/dates/list`;
    const version = '/api/v1';
    const location = params.location || this.getLocation();
    const p = JSON.parse(JSON.stringify(params));

    return this.get({ url, params: { ...p, location }, version });
  }

  /**
   * Gets a list of locations
   *
   * @returns {Promise<*|Error>}
   */
  fetchLocations() {
    const url = `/locations`;
    return this.get({ url });
  }

  fetchLocationsList() {
    const url = `/locations`;
    const version = '/api/v1';
    return this.get({ url, version });
  }

  fetchCinemaShortList() {
    const url = `/cinemas/list/short`;
    const version = '/api/v1';
    return this.get({ url, version });
  }

  fetchCinemaList() {
    const url = `/cinemas`;
    const version = '/api/v1';
    return this.get({ url, version });
  }

  fetchGetDetailOfCinema(cinemaID) {
    const url = `/cinemas/${cinemaID}`;
    const version = '/api/v1';
    return this.get({ url, params: { cinemaID }, version });
  }
}

export default Movies;
