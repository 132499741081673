import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import Home from "./Home.component";
import layoutModule from '../../../store/layout';

const { getSliderData, getRightSliderData, requestTopMoviesLocation, requestMovieDetail } = movieModule.actions;
const { requestLocations } = layoutModule.actions;

const mapDispatchToProps = {
    fetchLeftSlider: getSliderData,
    fetchRightSlider: getRightSliderData,
    getLocations: requestLocations,
    getTopMovies: requestTopMoviesLocation,
    getMovieDetail: requestMovieDetail,
};

const mapStateToProps = ({ layout, movies }) => (
    {
        leftSlider: movies.leftSlider,
        rightSlider: movies.rightSlider,
        isLoading: layout.isLoading,
        filmsLocation: movies?.filmsLocation,
        films: movies?.filmsDetail,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);