import React from "react";
import { HomePage, ErrorPage } from "../home/styles";
// import {
//     HomePage
// } from './styles';
// import HomeContainer from "../../components/common/Home/Home.container";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../../assets/css/custom.css";
import Page404 from '../../assets/media/404.png';

const PageNotFound = () => {

    return (
        <HomePage>
            <ErrorPage>
                <div className="row">
                    <div className="text-center">
                        <img src={Page404} />
                    </div>
                </div>
            </ErrorPage>
        </HomePage >
    );
};

export default PageNotFound;