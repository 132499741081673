import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import FilmSubDetail from './FilmSubDetail.component';
import layoutModule from '../../../store/layout';
import newsletterModule from '../../../store/newsletter';

const {
    requestMovieDetail,
    requestStateList,
    requestDetailOfCinema,
    requestTopMovies,
    requestForSendFormData,
    requestForSendCampaignFormData,
    requestForSendCampaignOtherFormData,
    requestCinemaData,
    requestFetchUpcomingMovies,
    requestArchiveMovies
} = movieModule.actions;
const { requestLocations } = layoutModule.actions;
const {
    requestForSendNewsletterFormData,
} = newsletterModule.actions;

const mapDispatchToProps = {
    getMovieDetail: requestMovieDetail,
    getStateList: requestStateList,
    getDetailOfCinema: requestDetailOfCinema,
    getTopMovies: requestTopMovies,
    sendFormData: requestForSendFormData,
    sendCampainFormData: requestForSendCampaignFormData,
    sendCampainOtherFormData: requestForSendCampaignOtherFormData,
    getLocations: requestLocations,
    getCinemaData: requestCinemaData,
    sendNewsletterFormData: requestForSendNewsletterFormData,
    getUpComingData: requestFetchUpcomingMovies,
    getArchiveMovies: requestArchiveMovies,
};

const mapStateToProps = ({ movies, details, layout }) => ({
    states: movies.states,
    shortList: movies.shortList,
    details: details,
    films: movies.filmsDetail,
    filmsList: movies.films,
    output: movies.output,
    isLoading: layout.isLoading,
    data: movies.data
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilmSubDetail);





