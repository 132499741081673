import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { CampaignImage } from '../../../containers/campaigns/styles';
import { CampaignsDetailBtn } from '../../../containers/campaigns/styles';
import { CampaignBtn } from '../../../containers/campaigns/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from '../../../assets/media/banner/header_schulaktionen.jpg';
// import CampaignDummy from '../../../assets/media/campaign-dummy.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

const propTypes = {
  output: PropTypes.func.isRequired,
  getCampaingsList: PropTypes.func.isRequired,
  // campaingsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchCampaignsList: PropTypes.func,
  dataList: PropTypes.array,
  getTopMovies: PropTypes.func.isRequired,
  getMovieDetail: PropTypes.func.isRequired,
  films: PropTypes.array.isRequired,
  filmsList: PropTypes.array.isRequired,
  getCinemas: PropTypes.func.isRequired,
  // partner: PropTypes.array.isRequired,
};

const CampaignsDetail = ({
  output,
  getCampaingsList,
  // campaingsList,
  getCinemas,
  // partner,
  isLoading,
  fetchCampaignsList,
  dataList,
  getMovieDetail,
  getTopMovies,
  filmsList,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();
  console.log('filmsList == :', filmsList);
  const [movieNames, setMovieName] = useState('');
  const [technologies, setTechnologies] = useState('');
  const movieID = location?.state?.movieId;
  console.log('movieID', movieID);
  const campaignId = location?.state?.campaignId;
  // const getMovieID = sessionStorage.getItem('id');
  const movieName = location?.state?.movieName
    ? location?.state?.movieName
    : movieNames;
  const cinemaId = location?.state?.cinemaIds;
  const technologie = location?.state?.technologie
    ? location?.state?.technologie
    : technologies;
  const [cdataList, setDataList] = useState();
  const [compaignsImage, setCompaignsImage] = useState();
  const [ageRating, setAgeRating] = useState();
  const [filmsData, setFilmsData] = useState([filmsList]);
  console.log('filmsList :', filmsList);
  console.log('filmsData :', filmsData);

  const current = new Date();
  const date = `${current.getFullYear()}-${current.getMonth() + 1
    }-${current.getDate()}`;
  useEffect(() => {
    if (output !== undefined) {
      toast.success('Request Send Successful', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [output]);
  useEffect(() => {
    // setDataList(dataList[0])
    const filteredData = dataList?.filter((item) => {
      if (campaignId) {
        return item?.id === campaignId;
      } else {
        return item;
      }
    });
    setDataList(filteredData);
  }, [dataList]);

  useEffect(() => {
    const filtered =
      !(filmsData?.[0]?.message === 'Not Found') &&
      filmsData.length > 0 &&
      filmsData?.filter((film) => {
        // return film.id === movieID
        // return film.HOFilmCode === movieID
        if (film?.HOFilmCode?.slice(0, 2) === 'HO') {
          return film?.id === movieID;
        } else if (movieID === undefined) {
          return film?.id;
        } else {
          return film?.HOFilmCode == movieID;
        }
      });
    if (
      filtered?.[0]?.posterImage !== undefined &&
      filtered?.[0]?.posterImage !== null
    ) {
      setCompaignsImage(filtered?.[0]?.posterImage);
      setAgeRating(
        filtered?.[0]?.rating
          ? filtered?.[0]?.rating
          : filtered?.[0]?.ratingDescription
      );
    }
    // else {
    //     setCompaignsImage(CampaignDummy)
    // }
    if (
      filtered?.[0]?.id?.slice(0, 2) === 'HO' &&
      filtered?.[0]?.id == movieID
    ) {
      console.log(
        'filtered?.[0]?.titleCalculatedIF',
        filtered?.[0]?.titleCalculated
      );
      setMovieName(filtered?.[0]?.titleCalculated);
      setTechnologies(filtered?.[0]?.technologies[0]);
    } else {
      setMovieName(filtered?.[0]?.titleCalculated);
      setTechnologies(filtered?.[0]?.technologies?.[0]);
    }
  }, [filmsData, technologies, movieNames]);
  // }, [films])
  const ChangeUrl = async (e, id, cinemaIds) => {
    e.preventDefault();
    localStorage.setItem('id', id);
    localStorage.setItem('state', cinemaIds);
    // navigate("/movie-detail", { state: { 'id': id, 'state': cinemaIds } });
    navigate(
      `/film-details/${filmsList?.shortURL
        ? filmsList?.shortURL
        : filmsList?.titleCalculated?.replaceAll(' ', '-')
      }`,
      { state: { id: id, state: cinemaIds } }
    );
  };
  const ChangeUrlForCampaign = async (
    e,
    startDate,
    endDate,
    movieName,
    cinemaName,
    technologie,
    dataList
  ) => {
    e.preventDefault();
    localStorage.setItem('endDate', endDate);
    localStorage.setItem('startDate', startDate);
    navigate('/anfragen', {
      state: {
        startDate: startDate,
        endDate: endDate,
        cinemaName: cinemaName,
        movieName: movieName,
        technologie: technologie,
        dataList: dataList,
      },
    });
  };

  useEffect(() => {
    getCampaingsList();
    // fetchCampaignsList();
    fetchCampaignsList({ id: location?.pathname?.split('/')[2] });
    getCinemas();
    getTopMovies({ date: date });
  }, []);

  useEffect(() => {
    // if (filmsData?.[0]?.message !== 'Not Found' && dataList.length > 0) {
    if (dataList?.[0]?.hOFilmcode && dataList.length > 0) {
      // console.log("dataList?.[0]?.hOFilmcode", dataList?.[0]?.hOFilmcode)
      getMovieDetail({ id: dataList?.[0]?.hOFilmcode });
    }
    //     getMovieDetail({ id: movieID || getMovieID });
  }, [dataList]);

  useEffect(() => {
    console.log('filmsList', filmsList);
    setFilmsData([filmsList]);
  }, [filmsList]);

  return (
    <>
      <div className="row" style={{ margin: '0' }}>
        <CampaignImage>
          <img src={Banner} style={{ width: '100%', marginLeft: 2 }} />
        </CampaignImage>
        <div className="row mt-4 mb-2" style={{ width: '100%' }}>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
            <div className="w-100">
              <img
                src={compaignsImage}
                style={{ width: '80%', marginBottom: '9px' }}
              />
              <CampaignsDetailBtn>
                <Link
                  to={`/film-details/${filmsList?.shortURL
                    ? filmsList?.shortURL
                    : filmsList?.titleCalculated?.replaceAll(' ', '-')}`}
                  onClick={(e) => ChangeUrl(e, movieID, cinemaId)}
                  type="submit"
                  className="form-input-button w-100"
                >
                  {t('campaigns.more_film_info')}
                </Link>
              </CampaignsDetailBtn>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 gap-2">
            <h2>{cdataList?.[0]?.headline}</h2>
            <div className="button_class mb-4 mt-4">
              <button type="button" className="age_button">
                <span>{t('movies.age_rating')}:</span>
                <br></br>
                {/* <b>from 12 years</b> */}
                <span>
                  {' '}
                  <b>{ageRating}</b>
                </span>
              </button>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: cdataList?.[0]?.desctoptionLong,
              }}
            />
          </div>
        </div>

        <div style={{ background: '#F5F5F5' }}>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
              <h3
                style={{
                  fontWeight: '700',
                  fontSize: '18px',
                  marginTop: '15px',
                  color: '#FF661F',
                }}
              >
                {t('campaigns.session')}
              </h3>
            </div>
            {/* <FormInput
                            type={"select"}
                            option={dateData}
                            className="select_drop"
                            styles={customStyles}
                        /> */}
            {/* <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 ' style={{ background: "white", minHeight: "2.5rem" }}>
                        </div> */}
            <div
              className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 d-flex align-items-center"
              style={{ background: 'white', minHeight: '2.5rem' }}
            ></div>
          </div>
          {cdataList?.[0]?.sessionData?.length > 0 &&
            cdataList?.[0]?.sessionData?.map((item, key) => {
              // console.log("item---", item)
              // const filtered = partner.length > 0 && partner.filter(part => {
              //     return Number(part.id) === item?.cinemaID
              // });
              return (
                <div
                  className="row"
                  key={key}
                  style={{
                    alignItems: 'center',
                    borderBottom: '1px solid #000',
                  }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12">
                    {item?.cinemaID?.cinemaName}
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <p
                          className="m-0"
                          style={{
                            fontFamily: 'Arvo',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textAlign: 'justify',
                            color: '#000000',
                          }}
                        >
                          {/* {item?.sessionDate} */}
                          {/* {moment(item?.sessionDate).format('DD.MM.YYYY HH:mm')} */}
                          {moment(item?.sessionDate, moment.ISO_8601, true).isValid() ?
                            moment(item?.sessionDate).format('DD.MM.YYYY\xa0\xa0\xa0\xa0HH:mm')
                            : moment(item?.sessionDate, "DD.MM.YYYY HH:mm").format('DD.MM.YYYY\xa0\xa0\xa0\xa0HH:mm')} Uhr
                        </p>
                        <p className="m-0">
                          {t('campaigns.regi_date')}:{' '}
                          {/* {item?.finalRegistrationDate} */}
                          {moment(item?.finalRegistrationDate, moment.ISO_8601, true).isValid() ?
                            moment(item?.finalRegistrationDate).format('DD.MM.YYYY')
                            : moment(item?.finalRegistrationDate, "DD.MM.YYYY HH:mm").format('DD.MM.YYYY')}
                          {/* {moment(item?.finalRegistrationDate, "DD.MM.YYYY HH:mm").format('DD.MM.YYYY')} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 d-flex justify-content-end">
                    <CampaignBtn>
                      <Link
                        className="flat-button flat-button-details"
                        to='/anfragen'
                        onClick={(e) =>
                          ChangeUrlForCampaign(
                            e,
                            item?.sessionDate,
                            item?.finalRegistrationDate,
                            movieName,
                            item?.cinemaID?.cinemaName,
                            technologie,
                            cdataList?.[0]
                          )
                        }
                      >
                        <button
                          type="submit"
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                          className="form-input-button"
                        >
                          ANMELDUNG
                        </button>
                      </Link>
                    </CampaignBtn>
                  </div>
                </div>
              );
            })}
        </div>
        <p
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: cdataList?.[0]?.description }}
        />
        <ToastContainer />
      </div>
      {isLoading && <Loader />}
    </>
  );
};

CampaignsDetail.propTypes = propTypes;

export default CampaignsDetail;
