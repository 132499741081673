import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ContactContainer from "../../components/common/Contact";
import { RowPage, WrapperPage } from "./styles";

const ContactPage = () => {
    return (
        <WrapperPage>
            <RowPage>
                <ContactContainer />
            </RowPage>
        </WrapperPage>
    );
};

export default ContactPage;



