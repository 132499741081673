import styled from 'styled-components';

export const RowPage = styled.main`
    width: 100%;
    float: left;
    background-color: #e9e9e9;
    .dropdown_index{
        z-index: 99999;
        display: block;
        line-height: 40px;
        padding: 12px;
        cursor: pointer;
        border: 1px solid #000000;
        width: 100%;
        background: #e9e9e9;
        border-radius: 3px;    
}`;

export const WrapperPage = styled.main`
    max-width: 1280px;
    margin: auto;
    width: 100%;
    height: 100vh;
`;


export const AdminLoginWrapper = styled.div`
    /* padding: 120px 0; */
    padding: 50px 0;
    .adminLogin_heading{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .adminLogin_wrapper{
        display: grid;
        justify-content: right;
    }
    .admin_filter_List{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .admin_input_field{
        margin-left: 10px;
    }
    .heading{
        font-size: 10px;
        padding-right: 5px;
    }
    .admin_data{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    /* .admin_table thead tr td{
        font-size: 13px;
    } */
    .admin_table thead, .admin_table td{
        font-size: 13px;
        /* width: 100%; */
    }
    .form-input{
        display: flex;
        align-items: center;
    }
    @media (max-width: 991px) {
        padding: 10px;
    }
    @media (max-width: 1024px) {
        /* padding: 10px; */
        .admin_data{
        display: table-caption;
        margin-left: 0px;
    }
    .admin_filter_List{
        display: table-caption;
    }
    .admin_export_button{
        margin-top: 10px;
    margin-left: 10px;
    }
    }

    
    @media (max-width: 768px) {
        padding: 10px;
        .admin_filter_List{
        display: table-caption;
    }
    .admin_input_field{
        margin-top: 10px;
    }
    .admin_data{
        display: table-caption;
        margin-left: 0px;
    }
    .admin_export_button{
        margin-top: 10px;
    margin-left: 10px;
    }
    .pagination{
        
    }
    }
`;

export const TabBtnWrapper = styled.div`
    @media (min-width:769px){
        display: flex;
        align-items: center;
        justify-content: center;

    }
    /* width: 100%; */
    gap: 50px;
         .flat-button-details {
            display: block;
            text-align: center;
            line-height: 24px;
            /* padding: 0 10px 0 10px; */
            /* margin: 0px auto; */
            color: black;
            background-color: #e9e9e9;
            border: 1px solid #000000;
            cursor: pointer;
            pointer-events: auto;
            /* margin-left: 50%; */
            width: 100%;
            padding: 5px;
            @media (max-width: 991px) {
                margin: 0 auto;
                width: 70%;
            }
            @media (max-width: 767px) {
                margin: 0 auto;
            }
            @media (max-width: 480px) {
                margin-left: 0px;
                width: 100%;
            }
        }
        .flat-button p {
            position: relative;
            display: inline-block;
            font-family: "Arvo",Arial,Verdana;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 12px;
            font-weight: 700;
            vertical-align: top;
        }
        @media (max-width: 480px) {
            .flat-button p {
                font-size: 10px;
            }
        }
        a.flat-button.flat-button-details.active {
            background: #ff7400;
            color: #fff;
            border-color: #ff7400;
        }
`;