import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from "react-router-dom";
import infoPNG from '../../../../app/assets/media/info.png';

import {
    DetailsHeader,
    DetailWrapper,
    DetailMainWrapper,
    DetailsPoster,
    DetailsText,
    DetailsLinkwrapper,
    DetailsLinkAnfrage,
    DetailsLinkVideo,
    DetailsLinkUnterricht,
    DetailStory,
    DetailTrailer,
    MovieGallery,
    DetailPopupContent
} from '../../../containers/movieDetail/styles';
import { Player } from '../../';
import play from '../../../assets/media/play_.png';
import { HashLink } from 'react-router-hash-link';
// import placeHolderImg from '../../../assets/media/place_holder/slider_placeholder.png';
import Modal from 'react-bootstrap/Modal';
// import SharePdf from '../../../assets/media/pdf/DerRaeuberHotzenplotz_PaedagogischesBegleitmaterial_k.pdf';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';
import ImageWithLoader from '../../ImageWithLoader';
import DetailImageWithLoader from '../../ImageWithLoader/DetailIndex';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

const propTypes = {
    films: PropTypes.array.isRequired,
    getMovieDetail: PropTypes.func.isRequired,
    getArchiveMovies: PropTypes.func.isRequired,
    getUpComingData: PropTypes.func.isRequired,
    findMovie: PropTypes.func.isRequired,
    successMovieDetailFetch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
const FilmDetail = ({ getMovieDetail, successMovieDetailFetch, getUpComingData, findMovie, getArchiveMovies, films, isLoading }) => {
    const {
        trailers,
        descriptionCalculated,
        gallery,
        EUYouthLogo
    } = films.filmsDetail;
    const location = useLocation();
    const refFilms = useRef(null);
    const isDataFetched = useRef(false);
    let state_data = location?.state;
    let getMovieId = sessionStorage.getItem('id');
    const searchedMovies = useSelector(state => state.search.movies);
    const archiveMovies = useSelector(state => state.movies.films_archive);
    const upcomingMovies = useSelector(state => state.movies.up_coming_films);
    let filteredSearchedMoives = searchedMovies.find((item) => {
        let movieName = decodeURIComponent(location?.pathname?.split("/")[2].split('.')[0]?.replaceAll("-", " "));
        return movieName === item?.titleCalculated?.replaceAll("-", " ") || movieName === item?.shortURL?.replaceAll("-", " ");
    })
    let filteredArchiveMoives = archiveMovies.find((item) => {
        let movieName = decodeURIComponent(location?.pathname?.split("/")[2].split('.')[0]?.replaceAll("-", " "));
        return movieName === item?.master?.titleCalculated?.replaceAll("-", " ") || movieName === item?.master?.shortURL?.replaceAll("-", " ");
    })
    let filteredUpcomingMoives = upcomingMovies?.find((item) => {
        let movieName = decodeURIComponent(location?.pathname?.split("/")[2].split('.')[0]?.replaceAll("-", " "));
        return movieName === item?.master?.titleCalculated?.replaceAll("-", " ") || movieName === item?.master?.shortURL?.replaceAll("-", " ");
    })
    let movieID = state_data?.id;
    const [t, i18n] = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        if (location?.search.split("=")[1] === 'show') {
            window?.location?.replace(`${location?.pathname}`)
            setShow(false);
        }
    };
    const handleShow = () => {
        window?.location?.replace(`${location?.pathname}?schoolMaterial=show`);
        // setShow(true);
    }
    useEffect(() => {
        if (location?.search.split("=")[1] === 'show') {
            setShow(true);
        }
    }, [show])
    const [trailerToShow, selectTrailer] = useState(trailers && trailers.length ? 0 : null);
    const [isModal, setModal] = useState(false);
    const openModal = useCallback(() => setModal(true), []);


    const [ratingList, setRatingList] = useState([]);
    const [lowestValue, setLowestValue] = useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [place, setPlace] = useState(windowSize.innerWidth < 767 ? "top" : "right");
    const handleTrailer = () => {
        document.getElementById("trailler").scrollIntoView({ behavior: "smooth" });
    }

    const languageContents = useMemo(
        () => ({
            title: i18n.language === 'en' || i18n.language === 'en-US' ? 'TitleEN' : 'title',
        }),
        [i18n.language]
    );
    useEffect(() => {
        successMovieDetailFetch([])
    }, [])
    const filteredMovieID = filteredSearchedMoives?.id || filteredArchiveMoives?.id || filteredUpcomingMoives?.id;
    useEffect(() => {
        const shortURL = decodeURIComponent(location?.pathname?.split("/")[2]?.split('.')[0]);

        const fetchMovieData = async () => {
            try {
                await findMovie({ shortURL });

                let filteredSearchedMovies = searchedMovies.find((item) => {
                    let movieName = shortURL?.replaceAll("-", " ");
                    return movieName === item?.titleCalculated?.replaceAll("-", " ") || movieName === item?.shortURL?.replaceAll("-", " ");
                });

                if (filteredSearchedMovies) {
                    isDataFetched.current = true;
                } else {
                    await findMovie({ title: shortURL });
                }
            } catch (error) {
                console.error("Error fetching movies:", error);
            }
        };
        if (movieID || getMovieId || filteredMovieID) {
            getMovieDetail({ id: movieID || getMovieId || filteredMovieID });
        }
        else if (!isDataFetched.current) {
            // findMovie({ shortURL: location?.pathname?.split("/")[2].split('.')[0] });
            fetchMovieData();
            getArchiveMovies();
            getUpComingData();
        }
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        if (windowSize.innerWidth < 767) {
            setPlace("top")
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [filteredMovieID]);

    useEffect(() => {
        if (!trailerToShow && trailers && trailers.length) {
            selectTrailer(0);
        }
    }, [trailers]);

    useEffect(() => {

        // 👇️ scroll to top on page load
        window.scrollTo(
            { top: 0, left: 0, behavior: 'smooth' }
            // console.log("")
        );
    }, []);
    useEffect(() => {
        if (films?.filmsDetail?.allStateRatings !== null) {
            const ddd = films?.filmsDetail?.allStateRatings;
            let newData = [];
            if (ddd) {
                const data = JSON.parse(ddd);
                // console.log("datae3333", data, data?.stateFsk)
                setRatingList(data?.stateFsk);
                if (data?.stateFsk) {
                    data?.stateFsk?.map((item) => {
                        newData.push(item?.fsk);
                    })
                }
                let result = newData.sort((a, b) => a.value - b.value)
                setLowestValue(result[0])
            }
            else {
                setLowestValue();
                setRatingList([]);
            }
        }
    }, [films?.filmsDetail]);
    return (
        <>
            <DetailsHeader>
                <div className="details-main col-lg-10 col-md-12 col-sm-12 col-xs-12 offset-lg-1">
                    <DetailWrapper>
                        <DetailMainWrapper>
                            <DetailsPoster>
                                {films?.filmsDetail?.posterImage !== undefined &&
                                    <DetailImageWithLoader src={films?.filmsDetail?.posterImage} />
                                    // : <img src={placeHolderImg} className='placeholder_img' />
                                }
                            </DetailsPoster>
                            {Object.keys(films?.filmsDetail)?.length > 0 &&
                                <DetailsText>
                                    <h1 className="details-title">{films?.filmsDetail?.titleCalculated}</h1>
                                    <h3 className="details-releasedate">{t('movie_detail.film_start')}: {moment(films?.filmsDetail?.startDate).format('DD.MM.YYYY')}</h3>
                                    <p className="details-copytext"><b>{t('movie_detail.length')}:</b> {films?.filmsDetail?.runTime} min.</p>
                                    <p className="details-copytext">
                                        <b>{t('movie_detail.regie')}:</b> {films?.filmsDetail?.director}
                                        <br></br>
                                        <b>{t('movie_detail.darsteller')}:</b>
                                        {films?.filmsDetail?.actors?.length > 0 &&
                                            films?.filmsDetail?.actors?.map((value, key) => (
                                                key === 0 ? <p key={key}>{value}</p>
                                                    : <p key={key}>{`, ${value}`}</p>
                                            ))
                                        }
                                        <br></br>
                                        <b>{t('movie_detail.genre')}:</b>
                                        {films?.filmsDetail?.genres?.length > 0 &&
                                            films?.filmsDetail?.genres?.map((value, key) => (
                                                key === 0 ?
                                                    <p key={key}>{value}</p>
                                                    :
                                                    <p key={key}>{`, ${value}`}</p>
                                            ))
                                        }
                                    </p>
                                    <div className="example-container">
                                        <button className='age_button position-relative'>
                                            <Tooltip anchorId={`my-anchor-element_`} place={"top" || place} style={{ backgroundColor: "#fff", color: "#000", zIndex: "99", textAlign: 'left', fontWeight: "600", boxShadow: '0px 0px 5px 2px #cfcfcf', opacity: 'inherit' }}>
                                                {ratingList?.length > 0
                                                    ? ratingList?.map((item, key) => (
                                                        <p key={key}>
                                                            <span className='age_title'>{t(`movie_detail_state-Id.${item?.stateId}`)} : </span>
                                                            {item?.fsk?.[languageContents.title].toLowerCase()}
                                                        </p>
                                                    ))
                                                    : t('movies.age_info')}
                                            </Tooltip>
                                            {t('movies.age_rating')}
                                            <br></br>
                                            <b>
                                                {lowestValue ? lowestValue?.[languageContents.title].toLowerCase()
                                                    :
                                                    <>
                                                        {films?.filmsDetail?.rating}
                                                    </>
                                                }
                                            </b>
                                            <img id={`my-anchor-element_`} src={infoPNG} className='info_set' />
                                        </button>
                                    </div>
                                </DetailsText>
                            }
                            <DetailsLinkwrapper>
                                <DetailsLinkAnfrage>
                                    <h3 className="details-links">{t('movie_detail.vorstellung')}</h3>
                                    <Link className="flat-button flat-button-details" to="/anfragen">
                                        <p className="m-0">{t('movie_detail.anragen')}</p>
                                    </Link>
                                </DetailsLinkAnfrage>
                                <DetailsLinkVideo>
                                    <h3 className="details-links-black">{t('movie_detail.videomaterial')}</h3>
                                    <HashLink to="#" id="trailerID" onClick={handleTrailer} className={"flat-button flat-button-details " + (trailers?.length === undefined ? 'disabled' : '')}><p className="m-0">TRAILER</p></HashLink>
                                </DetailsLinkVideo>
                                <DetailsLinkUnterricht>
                                    {
                                        films?.filmsDetail?.teachingMaterials?.length > 0 &&
                                        <>
                                            <h3 className="details-links">{t('movie_detail.unterrichtsmaterial')}</h3>
                                            <Link className="flat-button flat-button-details" to="#" onClick={handleShow} >
                                                <p className="m-0">{t('movie_detail.meterial')}</p>
                                            </Link>
                                        </>
                                    }
                                    <Modal size="xl" show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <DetailPopupContent>
                                                <h2 className="page-subline-white">{t('movie_detail.unterrichtsmaterial')}</h2>
                                                {
                                                    films?.filmsDetail?.teachingMaterials?.length > 0 && films?.filmsDetail?.teachingMaterials?.map((item, key) => (
                                                        <div className="umat-item" key={key}>
                                                            <div className="umat-item-title">
                                                                <p className='mb-0 pt-2'>
                                                                    {item?.value}
                                                                    {/* {t('movie_detail.sch_der')} */}
                                                                </p>
                                                            </div>
                                                            <div className="umat-item-button">
                                                                {/* <Link className="flat-button flat-button-umat" target="_blank" to={SharePdf}>{t('movie_detail.download')}</Link> */}
                                                                <Link className="flat-button flat-button-umat" target="_blank" to={item?.fullpath}>{t('movie_detail.download')}</Link>
                                                            </div>
                                                            <div className="umat-item-format">
                                                                <p className='mb-0 pt-2'>PDF</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </DetailPopupContent>
                                        </Modal.Body>
                                    </Modal>
                                </DetailsLinkUnterricht>
                            </DetailsLinkwrapper>
                        </DetailMainWrapper>
                    </DetailWrapper>
                </div>
            </DetailsHeader>
            <DetailStory>
                <div className="row">
                    <div className="col-lg-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 offset-lg-1 d-flex justify-content-between align-items-start">
                        <div style={{ width: '64%' }}>
                            <h2 className="page-subline-transparent story-hl">{t('movie_detail.inhalt')}</h2>
                            <p className="copytext storytext">{descriptionCalculated || "No content found!"}</p>
                        </div>
                        <div className="movie-image">
                            {EUYouthLogo &&
                                EUYouthLogo?.length > 0 &&
                                <img className='EUYlogo' src={EUYouthLogo} alt='EUYouthLogo' />
                            }
                            {gallery &&
                                gallery?.length > 0 &&
                                gallery?.[0]?.length > 0 &&
                                <ImageWithLoader src={gallery?.[0]} />
                                // : <ImageWithLoader src={placeHolderImg} />
                            }
                        </div>
                    </div>
                </div>
            </DetailStory>
            {
                trailers?.length !== undefined &&
                <DetailTrailer>
                    <div className="row" id='trailler'>
                        <div className="col-lg-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 offset-lg-1" ref={refFilms}>
                            <h2 className="page-subline-transparent">{t('movie_detail.trailer')}</h2>
                            <div className='row position-relative'>
                                {trailers?.length > 0 &&
                                    trailers?.slice(0, 1).map((value, key) => (
                                        <>
                                            {isModal ?
                                                <Player
                                                    key={key}
                                                    id={isModal ? trailers[trailerToShow].trailerKey : ''}
                                                    keyFrameUrl={isModal ? trailers[trailerToShow].keyframeUrl : ''}
                                                />
                                                :
                                                <>
                                                    <img key={key} src={value?.keyframeUrl} className='image_video_btn' />
                                                    <button type="button" className="b-trailer__btn-play" onClick={openModal} >
                                                        <img key={key} src={play} className='image_video_btn' />
                                                    </button>
                                                </>
                                            }
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </DetailTrailer>
            }
            {
                films?.filmsDetail?.gallery?.length !== undefined &&
                <MovieGallery>
                    {films?.filmsDetail?.gallery?.length > 0 &&
                        <div className="row">
                            <div className="col-lg-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 offset-lg-1" >
                                <h2 className="page-subline-transparent">{t('movie_detail.gellary')}</h2>
                                {films?.filmsDetail?.gallery &&
                                    <Carousel
                                        showThumbs={false}
                                    >
                                        {films?.filmsDetail?.gallery?.map((value, key) => (
                                            <div key={key}>
                                                <img src={value?.length && value} alt={value} />
                                            </div>
                                        ))}
                                    </Carousel>
                                }
                            </div>
                        </div>
                    }
                </MovieGallery>
            }
            {isLoading && <Loader />}
        </>

    )
}

FilmDetail.propTypes = propTypes;

export default FilmDetail;