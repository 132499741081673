export const TOP_MOVIES_REQUEST = 'movies/TOP_MOVIES_REQUEST';
export const MOVIES_FETCH_SUCCESS = 'movies/FETCH_SUCCESS';
export const UPCOMING_MOVIES_REQUEST = 'movies/UPCOMING_MOVIES_REQUEST';
export const SET_UPCOMING_MOVIES = 'movies/SET_UPCOMING_MOVIES';
export const GET_MOVIE_DETAIL = 'movies/GET_MOVIE_DETAIL';
export const MOVIES_DETAIL_FETCH_SUCCESS = 'movies/MOVIES_DETAIL_FETCH_SUCCESS';
export const GET_STATE_LIST = 'movies/GET_STATE_LIST';
export const STATE_LIST_FETCH_SUCCESS = 'movies/STATE_LIST_FETCH_SUCCESS';
export const GET_DETAIL_OF_CINEMA = 'movies/GET_DETAIL_OF_CINEMA';
export const GET_DETAIL_OF_CINEMA_FETCH_SUCCESS = 'movies/GET_DETAIL_OF_CINEMA_FETCH_SUCCESS';
export const GET_FETCH_UPCOMING_MOVIES = 'movies/GET_FETCH_UPCOMING_MOVIES';
export const GET_FETCH_UPCOMING_MOVIES_SUCCESS = 'movies/GET_FETCH_UPCOMING_MOVIES_SUCCESS';
export const SEND_FORM_DATA = 'movies/SEND_FORM_DATA';
export const SEND_CAMPAIGN_FORM_DATA = 'movies/SEND_CAMPAIGN_FORM_DATA'
export const SEND_CAMPAIGN_OTHER_FORM_DATA = 'movies/SEND_CAMPAIGN_OTHER_FORM_DATA'
export const SUCCESS_SEND_FORM_DATA = 'movies/SUCCESS_SEND_FORM_DATA';
export const SUCCESS_SEND_CAMPAIGN_FORM_DATA = 'movies/SUCCESS_SEND_CAMPAIGN_FORM_DATA';
export const SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA = 'movies/SUCCESS_SEND_CAMPAIGN_OTHER_FORM_DATA';
export const CINEMAS_LIST_REQUEST = 'movies/CINEMAS_LIST_REQUEST';
export const SUCCESS_CINEMAS_LIST = 'movies/SUCCESS_CINEMAS_LIST';
export const REQUEST_ARCHIVE_MOVIES = 'movies/REQUEST_ARCHIVE_MOVIES';
export const SET_ARCHIVE_MOVIES = 'movies/SET_ARCHIVE_MOVIES';
export const REQUEST_CAMPAINGS_LIST = 'movies/REQUEST_CAMPAINGS_LIST';
export const GET_REQUEST_CAMPAINGS_LIST = 'movies/GET_REQUEST_CAMPAINGS_LIST';

export const REQUEST_SCHOOL_LANDING_PAGE = 'movies/REQUEST_SCHOOL_LANDING_PAGE';
export const GET_REQUEST_SCHOOL_LANDING_PAGE = 'movies/GET_REQUEST_SCHOOL_LANDING_PAGE';

export const REQUEST_LEFT_SLIDER = 'movies/REQUEST_LEFT_SLIDER';
export const GET_REQUEST_LEFT_SLIDER = 'movies/GET_REQUEST_LEFT_SLIDER';
export const REQUEST_RIGHT_SLIDER = 'movies/REQUEST_RIGHT_SLIDER';
export const GET_REQUEST_RIGHT_SLIDER = 'movies/GET_REQUEST_RIGHT_SLIDER';

export const REQUEST_CAMPAINGNS = 'movies/REQUEST_CAMPAINGNS';
export const REQUEST_CAMPAINGNS_SUCCESS = 'movies/REQUEST_CAMPAINGNS_SUCCESS';

export const REQUEST_CAMPAINGNS_LIST = 'movies/REQUEST_CAMPAINGNS_LIST';
export const REQUEST_CAMPAINGNS_LIST_SUCCESS = 'movies/REQUEST_CAMPAINGNS_LIST_SUCCESS';

export const REQUEST_SCHOOL_TOP_MOVIE = 'movies/REQUEST_SCHOOL_TOP_MOVIE';
export const REQUEST_SCHOOL_TOP_MOVIE_SUCCESS = 'movies/REQUEST_SCHOOL_TOP_MOVIE_SUCCESS';

export const REQUEST_SCHOOL_TOP_FILM_TIPS = 'movies/REQUEST_SCHOOL_TOP_FILM_TIPS';
export const REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS = 'movies/REQUEST_SCHOOL_TOP_FILM_TIPS_SUCCESS';

export const REQUEST_CINEMA_DATA = 'movies/REQUEST_CINEMA_DATA';
export const REQUEST_CINEMA_DATA_SUCCESS = 'movies/REQUEST_CINEMA_DATA_SUCCESS';

export const REQUEST_POSTER_DATA = 'movies/REQUEST_POSTER_DATA';
export const REQUEST_POSTER_DATA_SUCCESS = 'movies/REQUEST_POSTER_DATA_SUCCESS';

export const REQUEST_ABOUT_INFO_DATA = 'movies/REQUEST_ABOUT_INFO_DATA';
export const REQUEST_ABOUT_INFO_DATA_SUCCESS = 'movies/REQUEST_ABOUT_INFO_DATA_SUCCESS';

export const TOP_MOVIES_REQUEST_LOCATION = 'movies/TOP_MOVIES_REQUEST_LOCATION';
export const MOVIES_FETCH_SUCCESS_LOCATION = 'movies/MOVIES_FETCH_SUCCESS_LOCATION';

export const GET_CINEMA_REQUEST_DATA = 'movies/GET_CINEMA_REQUEST_DATA';
export const GET_CINEMA_CAMPAIGN_REQUEST_DATA = 'movies/GET_CINEMA_CAMPAIGN_REQUEST_DATA';
export const GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA = 'movies/GET_CINEMA_CAMPAIGN_OTHER_REQUEST_DATA';
export const GET_CINEMA_REQUEST_SUCCESS_DATA = 'movies/GET_CINEMA_REQUEST_SUCCESS_DATA';
export const GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA = 'movies/GET_CINEMA_CAMPAIGN_REQUEST_SUCCESS_DATA';
export const GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA = 'movies/GET_CINEMA_CAMPAIGN_OTHER_REQUEST_SUCCESS_DATA';