import styled from 'styled-components';

export const CampaignImage = styled.div`
width: 100%;
float: left;
position: relative;
padding: 0;
    .form-input-button {
        border: 1px solid #000000;
        height: 40px;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 12px;
        line-height: 36px;
        font-weight: 700;
        color: black;
        padding: 0 20px 0 20px;
        background-color: #e9e9e9;
        cursor: pointer;
        pointer-events: auto;
        text-align: center;
        outline: none;
        border-radius: 50;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    h1.page-headline {
        position: absolute;
        top: 10%;
        left: 10%;
        text-transform: uppercase;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
        @media (max-width: 481px) {
            top: 5%;
            left: 5%;
        }
    }
`;
export const TextDescription = styled.div`
    padding: 0;
    .set_text {
        position: relative;
        background-color: #e9e9e9;
        p.copytext.introtext {
            padding: 20px;
        }
    }
`;
export const CampaignDetailStory = styled.div`
padding: 20px;
width: 100%;
float: left;
    h2.story-hl {
        position: relative;
        display: inline-block;
        width: 100%;
        float: left;
        text-transform: none;
        font-family: "Arvo",Arial,Verdana;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        background-color: transparent;
        padding: 10px 0px 10px 0px;
        display: inline-block;
    }
    p.storytext {
        position: relative;
        display: inline-block;
        width: 60%;
        text-align: justify;
        padding-bottom: 20px;
        float: left;
    }
    .movie-image {
        position: relative;
        display: inline-block;
        width: 15%;
        margin-left: 4%;
        margin-top: 25px;
        float: left;
    }
    .movie-image img {
        width: 100%;
        display: block;
    }
`;
export const CampaignBtn = styled.div`
    .form-input-button {
        padding: 8px;
        background: #8C8D8D;
        color: #FFFFFF;
        margin-left: 0px;
        margin-top: 40px;
        border:none;
    }
    @media (max-width: 767px) {
        .form-input-button {
            margin-left: 0px;
            margin-top: 15px;
        }
    }
`;
export const CampaignsDetailParagraph = styled.div`
    .campaigns-detail-paragraph {
        font-family: 'Arvo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: justify;
        color: #000000;
    }
`;
export const CampaignsDetailBtn = styled.div`
    .form-input-button {
        // padding: 15px;
        padding:10px 15px;
        background: #8C8D8D;
        color: #FFFFFF;
        border:none;
        font-family: 'Arvo';
        width: 221px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-top:28px;
        margin-bottom: 16px;
    }
`;

export const CampaignsSubClass = styled.div`
    margin: 50px 0px;
    @media (max-width: 767px) {
        margin: 20px 0px;
        h2, p {
            margin-top: 15px;
        }
    }
    p.text_description_part {
        max-width: 700px;
        margin: 0px;
    }
    button.age_button {
        position: relative;
    }
    img.info_set {
        position: absolute;
        bottom: 13%;
        right: 4%;
        height: 17px;
        width: 17px;
    }
    img.poster_img{
        width: 90%;
    }
`;