import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import newsletterModule from '../../../store/newsletter';
import NewsLetter from './NewsLetter.component';

const {
    requestPosterData
} = movieModule.actions;
const {
    requestForSendNewsletterFormData,
    requestForDeregistrationNewsletter,
} = newsletterModule.actions;
const mapDispatchToProps = {
    getPosterData: requestPosterData,
    sendNewsletterFormData: requestForSendNewsletterFormData,   
    deregistrationRequest :  requestForDeregistrationNewsletter,
};

const mapStateToProps = ({ movies, newsletter }) => (
    {
        data: movies.data,
        output: newsletter.output,
        emailData : newsletter.emailData,
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsLetter);





