import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';
import "react-datepicker/dist/react-datepicker.css";
import {
    TeaserImage,
    SchulaktionenTopleft,
    SchulaktionenTopright,
    CscDefault,
    SchoolactionFormwrapper
} from '../../../containers/movieSubDetail/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Banner from '../../../assets/media/banner/header_schulaktionen.jpg';
import { useTranslation } from 'react-i18next';
import FormInput from '../../FormInput';
import { BsInfoCircleFill } from "react-icons/bs"
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { useLocation } from 'react-router';
import moment from 'moment';
import 'moment/locale/de';
import { useSelector } from 'react-redux';

const propTypes = {
    states: PropTypes.array.isRequired,
    shortList: PropTypes.array,
    getStateList: PropTypes.func.isRequired,
    getDetailOfCinema: PropTypes.func.isRequired,
    films: PropTypes.array,
    filmsList: PropTypes.array,
    getTopMovies: PropTypes.func.isRequired,
    sendFormData: PropTypes.func.isRequired,
    sendCampainFormData: PropTypes.func.isRequired,
    sendCampainOtherFormData: PropTypes.func.isRequired,
    output: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    getCinemaData: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    sendNewsletterFormData: PropTypes.func.isRequired,
    getUpComingData: PropTypes.func.isRequired,
    getArchiveMovies: PropTypes.func.isRequired,

};

const FilmSubDetail = ({
    getStateList,
    states,
    shortList,
    getDetailOfCinema,
    getTopMovies,
    // films,
    // filmsList,
    sendFormData,
    sendCampainFormData,
    sendCampainOtherFormData,
    output,
    isLoading,
    getCinemaData,
    data,
    sendNewsletterFormData,
    getUpComingData,
    getArchiveMovies
}) => {
    const [t, i18next] = useTranslation();
    let stateId = sessionStorage.getItem('state');
    let movieID = sessionStorage.getItem('id');
    // let state_id_ = 0;
    const [techCompanies, setTechCompanies] = useState([]);
    const [dataSet, setDataSet] = useState(data?.[0]);
    const [isSetTech, setIsSetTech] = useState({ 'label': t('inquiry.select_state'), 'value': '-' });
    const [Items, setItems] = useState([]);
    const [isSetItem, setIsSetItem] = useState({ 'label': t('inquiry.select_cinema'), 'value': '-' });
    const [MovieTechnology, setMovieTechnology] = useState([]);
    const [isMovieTechnology, setIsMovieTechnology] = useState({ 'label': t('inquiry.select_technologie'), 'value': '-' });
    const [MoviesList, setMoviesList] = useState([]);
    const [isSetMovies, setIsSetMovies] = useState({ 'label': t('inquiry.select_movie'), 'value': '-' });

    // Set the locale to German
    moment.locale('de');

    const initialState = [
        { 'label': "Deutsch", 'value': 'Deutsch' },
    ];
    const location = useLocation();
    // console.log("location2", location.state);
    const initialState1 = [
        { 'label': t('inquiry.select_language'), 'value': '' },

    ];
    // let date = moment(location.state?.startDate).format('DD.MM.YYYY');
    // let time2 = moment(location.state?.startDate).format('DD.MM.YYYY  HH:mm');
    let date = moment(location.state?.startDate, moment.ISO_8601, true).isValid() ? moment(location.state?.startDate).format('DD.MM.YYYY') : location.state?.startDate.split(' ')[0];
    let time2 = moment(location.state?.startDate, moment.ISO_8601, true).isValid() ? moment(location.state?.startDate).format('DD.MM.YYYY HH:mm') : location.state?.startDate.replace("um", "");

    // const [MovieLanguage, setMovieLanguage] = useState(initialState);
    const [MovieLanguage, setMovieLanguage] = useState([]);
    const [isMovieLanguage, setISMovieLanguage] = useState(initialState1);
    const [FormError, setFormError] = useState({});

    const parseDate = (dateString) => {
        const formats = ['DD.MM.YYYY HH:mm', 'DD. MMMM YYYY HH:mm', 'DD.MM.YYYY', 'DD. MMMM YYYY'];
        let momentDate;
        for (const format of formats) {
            momentDate = moment.utc(dateString, format, true);
            if (momentDate.isValid()) {
                return momentDate;
            }
        }
        return momentDate;
    };

    const [formData, updateFormData] = useState({
        state: location.state?.cinemaName ? '1' : '',
        cinema: location.state?.cinemaName ? location.state?.cinemaName : '', //cinema location id
        movie: location.state?.movieName ? location.state?.movieName : '',  // movie Id
        languageVersion: location?.state?.startDate ? 'Deutsch' : '',
        technologie: location.state?.technologie ? location.state?.technologie.toString() : '',
        anotherFilm: '',
        date: '',
        time: '',
        // date: location?.state?.startDate ? moment(date, 'DD.MM.YYYY').toISOString() : '',
        // time: location.state?.startDate ? moment(time2, 'DD.MM.YYYY HH:mm') ? moment(time2, 'DD.MM.YYYY HH:mm') : moment(time2, 'DD.MM.YYYY') : '',
        // time: location?.state?.startDate ? parseDate(time2) : '',
        numberofStudents: '',
        numberaccoPersons: '',
        cinemabuffet: '',
        companion: '',
        nameofschool: '',
        street: '',
        zip: '',
        town: '',
        country: '',
        schoolPhonenumer: '',
        title: '',
        firstname: '',
        lastname: '',
        mail: '',
        phone: '',
        furtherInfo: '',
        subscribe: ''
    })
    const companionfree = location?.state?.dataList?.companionfree;
    const onlyforteacher = location?.state?.dataList?.onlyforteacher;
    const pupils = location?.state?.dataList?.pupils;
    const companionpaid = location?.state?.dataList?.companionpaid;

    const country = [
        { label: "Austria", value: 'Austria' },
        { label: "Deutschland", value: 'Deutschland' },
        { label: "Switzerland", value: 'Switzerland' },
        { label: "Albania", value: 'Albania' },
        { label: "Belgium", value: 'Belgium' },
        { label: "Bosnien u. Herzegowina", value: 'Bosnien' },
        { label: "Denmark", value: 'Denmark' },
        { label: "Austria", value: 'Austria' },
        { label: "Estonia", value: 'Estonia' },
        { label: "Finland", value: 'Finland' },
        { label: "France", value: 'France' },
        { label: "Greece", value: 'Greece' },
        { label: "Ireland", value: 'Ireland' },
    ];
    const title_ = [
        { label: t('common.title_mr'), value: t('common.title_mr') },
        { label: t('common.title_ms'), value: t('common.title_ms') },
        { label: t('common.title_no_information'), value: t('common.title_no_information') },
    ];


    const filmsMoviesData = useSelector(state => state.movies.films);
    const UpcomingMoviesData = useSelector(state => state.movies.up_coming_films);
    const ArchiveMoviesData = useSelector(state => state.movies.films_archive);
    const [filmsList, setFilmsList] = useState([]);
    const formFields = [
        { 'label': 'state', 'value': 'state', 'type': 'select', 'data': techCompanies, 'default': isSetTech },
        { 'label': 'cinema', 'value': 'cinema', 'type': 'select', 'data': Items, 'default': isSetItem },
        { 'label': 'movie', 'value': 'movie', 'type': 'select', 'data': MoviesList, 'default': isSetMovies },
        { 'label': 'language_version', 'value': 'languageVersion', 'type': 'select', 'data': MovieLanguage, 'default': isMovieLanguage },
        { 'label': 'technologie', 'value': 'technologie', 'type': 'select', 'data': MovieTechnology, 'default': isMovieTechnology },
        { 'label': 'anotherFilm', 'value': 'anotherFilm', 'type': 'text' },
        { 'label': 'date', 'value': 'date', 'type': 'date' },
        { 'label': 'time', 'value': 'time', 'type': 'time' },
        { 'label': t('inquiry.no_of_student'), 'value': 'numberofStudents', 'type': 'text' },
        { 'label': t('inquiry.no_of_person'), 'value': 'numberaccoPersons', 'type': 'text' },
        { 'label': 'Companion', 'value': 'companion', 'type': 'radio' },
        { 'label': 'Cinema Buffet', 'value': 'cinemabuffet', 'type': 'radio' },
        { 'label': t('inquiry.name_of_school'), 'value': 'nameofschool', 'type': 'text' },
        { 'label': t('inquiry.street'), 'value': 'street', 'type': 'text' },
        { 'label': t('inquiry.zip'), 'value': 'zip', 'type': 'text' },
        { 'label': t('inquiry.town'), 'value': 'town', 'type': 'text' },
        { 'label': t('inquiry.country'), 'value': 'country', 'type': 'select', 'data': country },
        { 'label': t('inquiry.phone'), 'value': 'schoolPhonenumer', 'type': 'text' },
        { 'label': t('inquiry.title_'), 'value': 'title', 'type': 'select', 'data': title_ },
        { 'label': t('inquiry.first_name'), 'value': 'firstname', 'type': 'text' },
        { 'label': t('inquiry.last_name'), 'value': 'lastname', 'type': 'text' },
        { 'label': t('inquiry.email_address'), 'value': 'mail', 'type': 'text' },
        { 'label': t('inquiry.mobile'), 'value': 'phone', 'type': 'text' },
        { 'label': t('inquiry.further_info'), 'value': 'furtherInfo', 'type': 'textarea' },
        { 'label': 'Subscribe Newsletter', 'value': 'subscribe', 'type': 'radio' }
    ];

    const cine_buffet = [
        { 'label': t('inquiry.and'), 'value': true },
        { 'label': t('inquiry.no'), 'value': false },
    ]
    const cine_companion = [
        { 'label': t('inquiry.and'), 'value': 1 },
        { 'label': t('inquiry.no'), 'value': 0 },
    ]
    const subscribe = [
        { 'label': t('inquiry.and_'), 'value': true },
        { 'label': t('inquiry.not_interested_'), 'value': false },
    ]
    const errors = {};

    const customStyles = {
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#ff7400" : null,
                color: "#000"
            };
        }
    };

    // console.log("formData",formData)

    useEffect(() => {
        setFilmsList([...filmsMoviesData, ...UpcomingMoviesData, ...ArchiveMoviesData])
    }, [filmsMoviesData, UpcomingMoviesData, ArchiveMoviesData]);
    const handleState = (e, type, value) => {
        if (value === "state") {
            var id = e;
            states?.map((element) => {
                if (element.id === id) {
                    let state_val_ = {
                        'label': element.name,
                        'value': element.id,
                    };
                    setIsSetTech(state_val_)
                    if (shortList !== undefined) {
                        setItems([])
                        shortList?.map((items) => {
                            if (element.items.indexOf(Number(items.id)) > -1) {
                                let item_val = {
                                    'label': items.name,
                                    'value': items.id,
                                };
                                setItems(Items => [...Items, item_val])
                            }
                        })
                    }
                }
            })
        } else if (value === "cinema") {
            getDetailOfCinema(e)
            // const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
            // // getTopMovies({ date: date })
            Items?.forEach(element => {
                if (element.value === e) {
                    let state_val_ = {
                        'label': element.label,
                        'value': element.value,
                    };
                    setIsSetItem(state_val_)
                    updateFormData({
                        ...formData,
                        [value]: element.label
                    });
                }
            });
        } else if (value === "movie") {
            filmsList?.map((items) => {
                if (items.id === e) {
                    let movie_val_ = {
                        'label': items.master.titleCalculated,
                        'value': items.id,
                    };
                    setIsSetMovies(movie_val_)
                    updateFormData({
                        ...formData,
                        [value]: items.master.titleCalculated
                    });
                    setMovieLanguage([])
                    setMovieTechnology([])
                    items?.master?.availableVersCMS?.map((language) => {
                        const htmlString = language?.Description;
                        const plainString = htmlString.replace(/<[^>]+>/g, '');
                        let state_val = {
                            'label': plainString.split('\n')[0],
                            'value': language?.id,
                        };
                        setMovieLanguage(MovieLanguage => [...MovieLanguage, state_val].slice(0, items?.master?.availableVersCMS.length))
                    })
                    items?.master?.availableTechCMS?.map((language) => {
                        const htmlString = language?.Description;
                        const plainString = htmlString.replace(/<[^>]+>/g, '');
                        let state_val = {
                            'label': plainString.split('\n')[0],
                            'value': language?.id,
                        };
                        setMovieTechnology(MovieTechnology => [...MovieTechnology, state_val].slice(0, items?.master?.availableTechCMS.length))
                    })
                }
            })
        }
        else if (value === 'languageVersion') {
            var languageId = e;
            MovieLanguage?.map((element) => {
                if (element.value === languageId) {
                    let state_val_ = {
                        'label': element.label,
                        'value': element.value,
                    };
                    setISMovieLanguage(state_val_)
                    updateFormData({
                        ...formData,
                        [value]: element.label
                    });
                }
            })
        }
        else if (value === 'technologie') {
            const technoId = e;
            MovieTechnology?.map((element) => {
                if (element.value === technoId) {
                    let state_val_ = {
                        'label': element.label,
                        'value': element.value,
                    };
                    setIsMovieTechnology(state_val_)
                    updateFormData({
                        ...formData,
                        [value]: element.label
                    });
                }
            })
        }
        if (value !== 'cinema' && value !== 'movie' && value !== 'languageVersion' && value !== 'technologie')
            updateFormData({
                ...formData,
                [value]: e
            });
    }

    useEffect(() => {
        if (formData.languageVersion === "Deutsch") {
            setISMovieLanguage(initialState)
        }
        // if(isSetItem.label==="Cineplexx Donau Zentrum"){
        //     setIsSetItem({ 'label': t('inquiry.select_cinema'), 'value': '-' })
        // }
    }, [formData, isSetItem])

    useEffect(() => {
        // const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,4}$/
        if (formData.mail !== "" && !regex.test(formData.mail)) {
            errors.mail = t('inquiry.mail_error')
        }
        setFormError(errors);
    }, [formData.mail])
    const OnSubmitForm = (e) => {
        e.preventDefault();
        // this added because getting error if schoolCampaing to direct anfragen page
        if (location?.state?.startDate) {
            const formats = ['DD.MM.YYYY HH:mm', 'DD. MMMM YYYY HH:mm', 'DD.MM.YYYY', 'DD. MMMM YYYY'];
            // formData.date = moment(date, 'DD.MM.YYYY').toISOString();
            formData.date = moment(date, formats).toISOString();
            formData.time = parseDate(time2);
        }
        if (formData.date === null || formData.date === undefined || formData.date === "") {
            errors.date = t('inquiry.date_error')
        }
        if (formData.time === null || formData.time === undefined || formData.time === "") {
            errors.time = t('inquiry.time_error')
        }
        if (formData.state === null || formData.state === undefined || formData.state === "") {
            errors.state = t('inquiry.state_error')
        } //1
        if (formData.cinema === null || formData.cinema === undefined || formData.cinema === "") {
            errors.cinema = t('inquiry.cinema_error')
        } //2 
        if ((formData.movie === null || formData.movie === undefined || formData.movie === "") && (formData.anotherFilm === null || formData.anotherFilm === undefined || formData.anotherFilm === "")) {
            errors.movie = t('inquiry.movie_error')
            errors.anotherFilm = t('inquiry.movie_error')
        } //3
        // if (formData.languageVersion === null || formData.languageVersion === undefined || formData.languageVersion === "") {
        //     errors.languageVersion = t('inquiry.languageVersion_error')
        // } //4
        // if (formData.technologie === null || formData.technologie === undefined || formData.technologie === "") {
        //     errors.technologie = t('inquiry.technologie_error')
        // } //5
        if (companionfree && (formData.companion === null || formData.companion === undefined || formData.companion === "")) {
            errors.companion = t('inquiry.companion_error')
        }
        const cinemaBuffetData = companionfree || onlyforteacher || companionpaid;
        if (!cinemaBuffetData && (formData.cinemabuffet === null || formData.cinemabuffet === undefined || formData.cinemabuffet === "")) {
            errors.cinemabuffet = t('inquiry.cinemabuffet_error')
        }
        if (formData.town === null || formData.town === undefined || formData.town === "") {
            errors.town = t('inquiry.town_error')
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        // const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!regex.test(formData.mail) || formData.mail === null || formData.mail === undefined || formData.mail === "") {
            errors.mail = t('inquiry.mail_error')
        }
        if (formData.firstname === null || formData.firstname === undefined || formData.firstname === "") {
            errors.firstname = t('inquiry.firstname_error')
        }
        if (formData.furtherInfo.length > 2000) {
            errors.furtherInfo = t('inquiry.Weitere_Informationen');
        }
        if (!/^\s*((\d+[\s+\-/\\]*)+|([\s+\-/\\]+\d+)+|(\d+[\s+\-/\\]+)+|([\s+\-/\\]+\d+[\s+\-/\\]+)+)?\s*$/.test(formData.phone)) {
            errors.phone = t('inquiry.phone_ex_error');
        }
        if (formData.subscribe === null || formData.subscribe === undefined || formData.subscribe === "") {
            errors.subscribe = t('inquiry.subscribe_error')
        }
        if (!/^\s*((\d+[\s+\-/\\]*)+|([\s+\-/\\]+\d+)+|(\d+[\s+\-/\\]+)+|([\s+\-/\\]+\d+[\s+\-/\\]+)+)?\s*$/.test(formData.schoolPhonenumer)) {
            errors.schoolPhonenumer = t('inquiry.schoolPhonenumer_ex_error')
        }
        if (formData.title === null || formData.title === undefined || formData.title === "") {
            errors.title = t('inquiry.title_error')
        }
        if (formData.firstname.length > 255) {
            errors.firstname = t('inquiry.Weitere_Informationen')
        }
        if (formData.firstname.length > 255) {
            errors.firstname = t('inquiry.Weitere_Informationen')
        }
        if (formData.lastname.length > 255) {
            errors.lastname = t('inquiry.Weitere_Informationen')
        }
        if (formData.nameofschool.length > 255) {
            errors.nameofschool = t('inquiry.Weitere_Informationen')
        }
        if (formData.street.length > 255) {
            errors.street = t('inquiry.Weitere_Informationen')
        }
        if (formData.zip.length > 255) {
            errors.zip = t('inquiry.Weitere_Informationen')
        }
        if (formData.town.length > 255) {
            errors.town = t('inquiry.Weitere_Informationen')
        }
        if (formData.anotherFilm.length > 255) {
            errors.anotherFilm = t('inquiry.Weitere_Informationen')
        }
        if (formData.numberofStudents.length > 255) {
            errors.numberofStudents = t('inquiry.Weitere_Informationen')
        }
        if (formData.numberaccoPersons.length > 255) {
            errors.numberaccoPersons = t('inquiry.Weitere_Informationen')
        }
        if (formData.phone.length > 255) {
            errors.phone = t('inquiry.Weitere_Informationen')
        }
        if (formData.schoolPhonenumer.length > 255) {
            errors.schoolPhonenumer = t('inquiry.Weitere_Informationen')
        }
        if (formData.nameofschool === null || formData.nameofschool === undefined || formData.nameofschool === "") {
            errors.nameofschool = t('inquiry.nameofschool_error')
        }
        if (formData.street === null || formData.street === undefined || formData.street === "") {
            errors.street = t('inquiry.street_error')
        }
        if (formData.lastname === null || formData.lastname === undefined || formData.lastname === "") {
            errors.lastname = t('inquiry.lastname_error')
        }
        if (formData.zip === null || formData.zip === undefined || formData.zip === "") {
            errors.zip = t('inquiry.zip_error')
        } else {
            if (!isNaN(+formData.zip) === false) {
                errors.zip = t('inquiry.zip_ex_error')
            }
        }

        setFormError(errors);
        if (Object.keys(errors)?.length === 0) {
            // formData.answered = "true";

            if (formData.date !== null || formData.date !== undefined || formData.date !== "") {
                const date = moment(formData.date);
                const time = moment(formData.time, "HH:mm:ss");
                const combinedDateTime = date
                    .set({
                        hour: time.hour(),
                        minute: time.minute(),
                        second: time.second(),
                    });
                if (location?.state?.startDate) {
                    // const formats = ['DD.MM.YYYY HH:mm', 'DD. MMMM YYYY HH:mm', 'DD.MM.YYYY', 'DD. MMMM YYYY'];
                    // const momentObj = moment(updatedMoment?._i, formats);
                    // const outputDateTime = momentObj.format("YYYY-MM-DD HH:mm:ss");
                    // formData.dateTime = moment(updatedMoment?._i).format('YYYY-MM-DD HH:mm:ss');
                    const isISOFormat = moment(combinedDateTime?._d, moment.ISO_8601, true)?.isValid();
                    if (isISOFormat) {
                        // formData.dateTime = new Date(updatedMoment?._d).toISOString();
                        formData.dateTime = combinedDateTime.format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        formData.dateTime = moment(combinedDateTime?._i).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                else {
                    // formData.dateTime = moment(updatedMoment?._d).format("YYYY-MM-DD HH:mm:ss");
                    const isISOFormat = moment(combinedDateTime?._d, moment.ISO_8601, true)?.isValid();
                    if (isISOFormat) {
                        // formData.dateTime = new Date(updatedMoment?._d).toISOString();
                        formData.dateTime = combinedDateTime.format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        formData.dateTime = moment(combinedDateTime?._d).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                // formData.dateTime = new Date(updatedMoment?._d).toISOString();
            }
            if (formData.numberofStudents === '') {
                formData.numberofStudents = 0
            }
            if (formData.numberaccoPersons === '') {
                formData.numberaccoPersons = 0
            }
            // console.log("sendFormData",sendFormData);

            if (formData.subscribe) {
                const newsLetterFormData = {
                    salutation: formData.title,
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    postcode: formData.zip,
                    schoolname: formData.nameofschool,
                    telephone: formData.schoolPhonenumer,
                    location: formData.town,
                    schoolgroup: "keine Auswahl",
                    email: formData.mail,
                }

                sendNewsletterFormData(newsLetterFormData);
            }
            if (pupils && location.state?.cinemaName) {
                sendCampainFormData(formData);
            }
            else if (companionfree || onlyforteacher || companionpaid) {
                sendCampainOtherFormData(formData)
            }
            else {
                if (formData.movie === "") {
                    formData.movie = '1';
                    sendFormData(formData);
                }
                else {
                    sendFormData(formData);
                }
            }
        } else {
            setFormError(errors);
        }
    }

    const ConstantUse = (sss) => {
        if (states.length > 0) {
            states?.map((element) => {
                if (stateId !== undefined && stateId !== null) {
                    var myArray = stateId?.split(',')
                    if (myArray !== undefined) {
                        var stateId_ = Number(myArray[sss])
                        if (element?.items?.includes(stateId_)) {
                            let state_val_ = {
                                'label': element.name,
                                'value': element.id,
                            };
                            // state_id_ = element.id;
                            setIsSetTech(state_val_)
                        }
                    }
                }
                let state_val = {
                    'label': element.name,
                    'value': element.id,
                };
                setTechCompanies(techCompanies => [...techCompanies, state_val].slice(0, states?.length))
            })
        }
    }

    useEffect(() => {
        ConstantUse(formData.state);
        // ConstantUse(0);
    }, [states]);

    useEffect(() => {
        if (filmsList.length > 0) {
            setMoviesList([])
            filmsList?.map((element) => {
                // if (element?.master?.cinemaIds.indexOf(isSetItem?.value) > -1) {
                if (!movieID) {
                    let state_val = {
                        'label': element?.master?.titleCalculated,
                        'value': element?.master?.id,
                    };
                    setMoviesList(MoviesList => [...MoviesList, state_val]);
                }
                // }
                //here when we go from aktuelle-filme to movie-detail to anfragen form then direct movie get selected in Wunschfilm/movie dropdown filed
                if (element?.id === movieID) {
                    let state_val_ = {
                        'label': element?.master?.titleCalculated,
                        'value': element?.master?.id,
                    };
                    setIsSetMovies(state_val_)
                    setMoviesList([state_val_])
                    updateFormData({
                        ...formData,
                        ['movie']: element?.master?.titleCalculated
                    });
                    setMovieLanguage([])
                    setMovieTechnology([])
                    element?.master?.availableVersCMS?.map((language) => {
                        const htmlString = language?.Description;
                        const plainString = htmlString.replace(/<[^>]+>/g, '');
                        let state_val = {
                            'label': plainString.split('\n')[0],
                            'value': language?.id,
                        };
                        setMovieLanguage(MovieLanguage => [...MovieLanguage, state_val].slice(0, element?.master?.availableVersCMS.length))
                    })
                    element?.master?.availableTechCMS?.map((language) => {
                        const htmlString = language?.Description;
                        const plainString = htmlString.replace(/<[^>]+>/g, '');
                        let state_val = {
                            'label': plainString.split('\n')[0],
                            'value': language?.id,
                        };
                        setMovieTechnology(MovieTechnology => [...MovieTechnology, state_val].slice(0, element?.master?.availableTechCMS.length))
                    })
                } else {
                    // ConstantUse(1)
                }

            })
        }
    }, [filmsList]);

    useEffect(() => {
        getStateList()
        // const current = new Date();
        // const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
        // getTopMovies({ date: date })
        getTopMovies()
        getUpComingData()
        getArchiveMovies()
        getCinemaData()
    }, []);

    useEffect(() => {
        if (output !== undefined) {
            toast.success('Request Send Successful', {
                position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [output])

    useEffect(() => {
        if (data !== null || data.length > 0) {
            setDataSet(data?.[0])
        }
    }, [data])
    useEffect(() => {
        // var id = state_id_;
        // states?.map((element) => {
        //     if (element.id === id) {
        //         if (shortList !== undefined) {
        //             shortList?.map((items) => {
        //                 if (element.items.indexOf(Number(items.id)) > -1) {
        //                     let state_val_ = {
        //                         'label': items.name,
        //                         'value': items.id,
        //                     };
        //                     setIsSetItem(state_val_)
        //                 }
        //             })
        //         }
        //     }
        // })
        setIsSetItem({ 'label': t('inquiry.select_cinema'), 'value': '-' })
    }, [isSetTech])

    useEffect(() => {
        setIsMovieTechnology({ 'label': t('inquiry.select_technologie'), 'value': '-' });
        setISMovieLanguage(initialState1);
    }, [isSetMovies])
    // useEffect(() => {
    //     if (filmsList?.length > 0) {
    //         filmsList?.map((items) => {
    //             if (items.id === isSetMovies.value) {
    //                 items?.master?.availableVersCMS?.map((language) => {
    //                     // if (language.length > 0) {
    //                     //     const newState = language?.map((d) => {
    //                     //         if (d !== "OV") {
    //                     //             return { 'label': d, 'value': d };
    //                     //         }
    //                     //         return d;
    //                     //     })
    //                     //     setIsMovieTechnology(newState)
    //                     //     return { 'label': 'Deutsch', 'value': 'Deutsch' };
    //                     // }
    //                     // if (key === 0) {
    //                     //     if (language.length > 0) {
    //                     //         if (language.indexOf("OV") > -1) {
    //                     //             let item_val_ = [{
    //                     //                 'label': "OV",
    //                     //                 'value': "OV",
    //                     //             },
    //                     //             {
    //                     //                 'label': "Deutsch",
    //                     //                 'value': "Deutsch",
    //                     //             }];

    //                     //             setISMovieLanguage(item_val_)
    //                     //         } else {
    //                     //             if (language.indexOf("FILMBRUNCH") > -1) {
    //                     //                 console.log()
    //                     //             } else {
    //                     //                 let item_val = [
    //                     //                     {
    //                     //                         'label': "Deutsch",
    //                     //                         'value': "Deutsch",
    //                     //                     }];

    //                     //                 setISMovieLanguage(item_val)
    //                     //             }
    //                     //         }
    //                     //     } else {
    //                     //         let item_val = [
    //                     //             {
    //                     //                 'label': "Deutsch",
    //                     //                 'value': "Deutsch",
    //                     //             }];

    //                     //         setISMovieLanguage(item_val)
    //                     //     }
    //                     // }

    //                     console.log("ISMovieLanguage", language)
    //                     console.log("setISMovieLanguage", setIsMovieTechnology(), setISMovieLanguage())
    //                 })
    //             }
    //         })
    //     }
    // }, [isSetMovies])

    return (
        <>
            <div className='row'>
                <TeaserImage>
                    <h1 className="page-headline">{i18next?.language === "en" ? dataSet?.headline1EN : dataSet?.headline1}</h1>
                    <img src={dataSet?.picture} style={{ width: '100%' }} />
                </TeaserImage>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-12'>
                        <SchulaktionenTopleft>
                            <CscDefault>
                                <h2 className="page-subline">{i18next?.language === "en" ? dataSet?.headline2EN : dataSet?.headline2}</h2>
                                <p className="schulaktionen-copytext-sidebar schulaktionen-copytext-sidebar-last" dangerouslySetInnerHTML={{ __html: i18next?.language === "en" ? dataSet?.descriptionEN : dataSet?.description }} />
                            </CscDefault>
                        </SchulaktionenTopleft>
                    </div>
                    <div className='col-lg-8 col-md-8 col-12'>
                        <SchulaktionenTopright>
                            <div className="row">
                                <div className="f-default__field mail">
                                    <SchoolactionFormwrapper>
                                        {
                                            location.state?.startDate ?
                                                <>
                                                    <div>
                                                        <h2 style={{ marginTop: '20px' }}>DIE VORSTELLUNG: </h2>
                                                        <h6><b>{location.state?.cinemaName}</b></h6>
                                                        {/* <h6><b>{t('common.date')}: {location.state.startDate.replace(" um", ",")} Uhr</b></h6> */}
                                                        {/* <h6><b>Datum: {location.state?.startDate.replace(" um", ",")} Uhr</b></h6> */}
                                                        {/* <h6>Anmeldeschluss: {location.state?.endDate}</h6> */}
                                                        {/* <h6><b>Datum: {moment(location.state?.startDate).format('DD.MM.YYYY  HH:mm')} Uhr</b></h6> */}
                                                        <h6><b>Datum: {moment(location.state?.startDate, moment.ISO_8601, true).isValid() ?
                                                            moment(location.state?.startDate).format('DD.MM.YYYY HH:mm')
                                                            : moment(location.state?.startDate, "DD.MM.YYYY HH:mm").format('DD.MM.YYYY HH:mm')} Uhr</b></h6>
                                                        {/* <h6>Anmeldeschluss: {moment(location.state?.endDate).format('DD.MM.YYYY')}</h6> */}
                                                        <h6>Anmeldeschluss:&nbsp;{moment(location.state?.endDate, moment.ISO_8601, true).isValid() ?
                                                            moment(location.state?.endDate).format('DD.MM.YYYY HH:mm')
                                                            : moment(location.state?.endDate, "DD.MM.YYYY HH:mm").format('DD.MM.YYYY HH:mm')}</h6>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='only_dropdowns diff_div'>
                                                        <h2 style={{ marginTop: '20px' }}>{t('inquiry.title')}</h2>
                                                        {
                                                            formFields.map((column, key) => (
                                                                key < 5 ?
                                                                    key < 3 ?
                                                                        <>
                                                                            <FormInput
                                                                                key={key}
                                                                                type={"select"}
                                                                                label="false"
                                                                                values={column?.default}
                                                                                option={column?.data}
                                                                                // option={column?.data?.find((option) => {
                                                                                //     return option?.value === isSetMovies?.value
                                                                                // }) ? [isSetMovies] : column?.data}
                                                                                className="select_drop"
                                                                                onChange={e => handleState(e.value, column?.type, column?.value)}
                                                                                name={column?.label}
                                                                                styles={customStyles}
                                                                                onError={FormError[column?.value]}
                                                                                id={column?.value}
                                                                                noOptionsMessage={() => t('common.no_data_here')}
                                                                            />
                                                                        </>
                                                                        :
                                                                        <FormInput
                                                                            key={key}
                                                                            type={"select"}
                                                                            label="false"
                                                                            values={column?.default}
                                                                            option={column?.data}
                                                                            className="select_drop"
                                                                            onChange={e => handleState(e.value, column?.type, column?.value)}
                                                                            name={column?.label}
                                                                            styles={customStyles}
                                                                            noOptionsMessage={() => t('common.no_data_here')}
                                                                            // onError={FormError[column?.value]}
                                                                            id={column?.value}
                                                                        />
                                                                    : console.log()
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='second_part diff_div'>
                                                        <h2>{t('inquiry.another_movie')}</h2>
                                                        {
                                                            formFields.map((column, key) => (
                                                                column?.value === "anotherFilm" ?
                                                                    <FormInput
                                                                        label=""
                                                                        key={key}
                                                                        type={"text"}
                                                                        name={column?.value}
                                                                        className="form-input"
                                                                        onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                        value={formData[column?.value]}
                                                                        id={column?.value}
                                                                        onError={FormError[column?.value]}
                                                                    />
                                                                    : console.log()
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='third_part diff_div'>
                                                        <h2>{t('inquiry.appointment_request')}</h2>
                                                        {
                                                            formFields.map((column, key) => (
                                                                column?.label === "date" ?
                                                                    <>
                                                                        <div className='form-input'>
                                                                            <label>{` ${t('common.date')} *`}</label>
                                                                            <FormInput
                                                                                type={"date"}
                                                                                key={key}
                                                                                label="false"
                                                                                selected={formData?.date}
                                                                                name={column?.value}
                                                                                className="form-input"
                                                                                onChange={e => handleState(e, column?.type, column?.value)}
                                                                                id={column?.value}
                                                                                onError={FormError[column?.value]}
                                                                                autoComplete={"off"}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    : column?.label === "time" ?
                                                                        <>
                                                                            <div className='form-input'>
                                                                                <label>{` ${t('common.time')} *`}</label>
                                                                                <FormInput
                                                                                    type={"time"}
                                                                                    key={key}
                                                                                    label="false"
                                                                                    onChange={e => handleState(e, column?.type, column?.value)}
                                                                                    name={column?.value}
                                                                                    value={formData?.time}
                                                                                    id={column?.value}
                                                                                    className="form-input"
                                                                                    onError={FormError[column?.value]}
                                                                                    autoComplete={"off"}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                        : console.log()
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                        }
                                        <div className='fourth_part diff_div'>
                                            {pupils && <h2>{t('inquiry.student_info')}</h2>}
                                            {location.state?.startDate ?
                                                <>
                                                    {/* commented by client for campaign page */}
                                                    {/* {
                                                        <FormInput
                                                            //    key={key}
                                                            type={"select"}
                                                            label="false"
                                                            values={formFields[0]?.default}
                                                            option={formFields[0]?.data}
                                                            className="select_drop"
                                                            onChange={e => handleState(e.value, formFields[0]?.type, formFields[0]?.value)}
                                                            name={formFields[0]?.label}
                                                            styles={customStyles}
                                                            onError={FormError[formFields[0]?.value]}
                                                            id={formFields[0]?.value}
                                                            noOptionsMessage={() => t('common.no_data_here')}
                                                        />
                                                    } */}
                                                    {
                                                        formFields.map((column, key) => (
                                                            (pupils && (column?.value === "numberofStudents" || column?.value === "numberaccoPersons")) ?
                                                                <>

                                                                    <FormInput
                                                                        label={column?.label}
                                                                        key={key}
                                                                        type={"text"}
                                                                        name={column?.value}
                                                                        className="form-input"
                                                                        onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                        value={formData[column?.value]}
                                                                        id={column?.value}
                                                                        onError={FormError[column?.value]}
                                                                    />

                                                                </>
                                                                :
                                                                (pupils && column?.value === "cinemabuffet") ?
                                                                    <>
                                                                        <label>{t('inquiry.cinema_buffet')} <span id="my-element" data-tooltip-content={t('inquiry.buffet_info')}>
                                                                            <BsInfoCircleFill style={{ fontSize: "15px", margin: "-5px 0px 0px 5px", cursor: "pointer", opacity: "50%" }} /></span> </label>
                                                                        <Tooltip anchorId="my-element" float="right" place='right' style={{ backgroundColor: "#fff", color: "#000", textAlign: 'left', boxShadow: '0px 0px 5px 2px #cfcfcf', opacity: '1' }} />
                                                                        <FormInput
                                                                            key={key}
                                                                            type={"radio"}
                                                                            label="false"
                                                                            selected={formData[column?.value]}
                                                                            radioOption={cine_buffet}
                                                                            name={column?.value}
                                                                            className="form-input"
                                                                            onChange={e => handleState(e, column?.type, column?.value)}
                                                                            id={column?.value}
                                                                            onError={FormError[column?.value]}
                                                                        />
                                                                    </> :
                                                                    (companionpaid && column?.value === "numberaccoPersons") ?
                                                                        <>

                                                                            <label>{t('inquiry.no_of_person')} <span style={{
                                                                                // color: "red",
                                                                                fontWeight: '600'
                                                                            }}>{t('inquiry.no_of_person_label')}</span></label>
                                                                            <FormInput
                                                                                // label={column?.label ? ` ${column?.label} ${t('inquiry.no_of_person_label')}` : ''}
                                                                                label=""
                                                                                key={key}
                                                                                type={"text"}
                                                                                name={column?.value}
                                                                                className="form-input"
                                                                                onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                                value={formData[column?.value]}
                                                                                id={column?.value}
                                                                                onError={FormError[column?.value]}
                                                                            />
                                                                        </>
                                                                        : (companionfree && column?.value === "companion") ?
                                                                            <>
                                                                                <label>{t('inquiry.companion')}:</label>
                                                                                <FormInput
                                                                                    key={key}
                                                                                    type={"radio"}
                                                                                    label="0"
                                                                                    selected={formData[column?.value]}
                                                                                    radioOption={cine_companion}
                                                                                    name={column?.value}
                                                                                    className="form-input"
                                                                                    onChange={e => handleState(e, column?.type, column?.value)}
                                                                                    id={column?.value}
                                                                                    onError={FormError[column?.value]}
                                                                                />
                                                                            </>
                                                                            :

                                                                            console.log()
                                                        ))
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        formFields.map((column, key) => (
                                                            column?.value === "numberofStudents" || column?.value === "numberaccoPersons" ?
                                                                <>
                                                                    <FormInput
                                                                        label={column?.label}
                                                                        key={key}
                                                                        type={"text"}
                                                                        name={column?.value}
                                                                        className="form-input"
                                                                        onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                        value={formData[column?.value]}
                                                                        id={column?.value}
                                                                        onError={FormError[column?.value]}
                                                                    />
                                                                </>
                                                                : column?.value === "cinemabuffet" ?
                                                                    <>
                                                                        <label>{t('inquiry.cinema_buffet')} <span id="my-element" data-tooltip-content={t('inquiry.buffet_info')}>
                                                                            <BsInfoCircleFill style={{ fontSize: "15px", margin: "-5px 0px 0px 5px", cursor: "pointer", opacity: "50%" }} /></span> </label>
                                                                        <Tooltip anchorId="my-element" float="right" place='right' style={{ backgroundColor: "#fff", color: "#000", textAlign: 'left', boxShadow: '0px 0px 5px 2px #cfcfcf', opacity: '1' }} />
                                                                        <FormInput
                                                                            key={key}
                                                                            type={"radio"}
                                                                            label="false"
                                                                            selected={formData[column?.value]}
                                                                            radioOption={cine_buffet}
                                                                            name={column?.value}
                                                                            className="form-input"
                                                                            onChange={e => handleState(e, column?.type, column?.value)}
                                                                            id={column?.value}
                                                                            onError={FormError[column?.value]}
                                                                        />
                                                                    </>
                                                                    : console.log()
                                                        ))
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className='fifth_part diff_div'>
                                            <h2>{t('inquiry.contact')}</h2>
                                            {
                                                formFields.map((column, key) => (
                                                    column?.value === "nameofschool" || column?.value === "street" || column?.value === "zip" || column?.value === "town" ?
                                                        <>
                                                            <FormInput
                                                                label={column?.label}
                                                                key={key}
                                                                type={"text"}
                                                                name={column?.value}
                                                                className="form-input"
                                                                onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                value={formData[column?.value]}
                                                                id={column?.value}
                                                                onError={FormError[column?.value]}
                                                            />
                                                        </>
                                                        // : column?.value === "country" ?
                                                        //     <>
                                                        //         <label>{`${t('inquiry.country')}`}</label>
                                                        //         <FormInput
                                                        //             key={key}
                                                        //             type={"select"}
                                                        //             label="false"
                                                        //             option={column?.data}
                                                        //             className="select_drop"
                                                        //             onChange={e => handleState(e.value, column?.type, column?.value)}
                                                        //             name={column?.label}
                                                        //             styles={customStyles}
                                                        //             noOptionsMessage={() => t('common.no_data_here')}
                                                        //             // onError={FormError[column?.value]}
                                                        //             id={column?.value}
                                                        //         />
                                                        //     </>
                                                        : column?.value === "schoolPhonenumer" ?
                                                            <>
                                                                <FormInput
                                                                    label={column?.label}
                                                                    key={key}
                                                                    type={"text"}
                                                                    name={column?.value}
                                                                    className="form-input"
                                                                    onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                    value={formData[column?.value]}
                                                                    id={column?.value}
                                                                    onError={FormError[column?.value]}
                                                                />
                                                            </>
                                                            : column?.value === "title" ?
                                                                <>
                                                                    <label>{`${t('inquiry.title_')} *`}</label>
                                                                    <FormInput
                                                                        key={key}
                                                                        type={"select"}
                                                                        label="false"

                                                                        option={column?.data}
                                                                        className="select_drop"
                                                                        onChange={e => handleState(e.value, column?.type, column?.value)}
                                                                        name={column?.label}
                                                                        styles={customStyles}
                                                                        noOptionsMessage={() => t('common.no_data_here')}
                                                                        onError={FormError[column?.value]}
                                                                    />
                                                                </>
                                                                : column?.value === "firstname" || column?.value === "lastname" || column?.value === "mail" ?
                                                                    <>
                                                                        <FormInput
                                                                            label={column?.label}
                                                                            key={key}
                                                                            type={"text"}
                                                                            name={column?.value}
                                                                            className="form-input"
                                                                            onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                            value={formData[column?.value]}
                                                                            id={column?.value}
                                                                            onError={FormError[column?.value]}
                                                                        />
                                                                    </>
                                                                    : column?.value === "phone" ?
                                                                        <>
                                                                            <FormInput
                                                                                label={column?.label}
                                                                                key={key}
                                                                                type={"text"}
                                                                                name={column?.value}
                                                                                className="form-input"
                                                                                onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                                value={formData[column?.value]}
                                                                                id={column?.value}
                                                                                onError={FormError[column?.value]}
                                                                            />
                                                                        </>
                                                                        : column?.type === "textarea" ?
                                                                            <>
                                                                                <FormInput
                                                                                    key={key}
                                                                                    label={column?.label}
                                                                                    type={"textarea"}
                                                                                    name={column?.value}
                                                                                    className="form-input"
                                                                                    onChange={e => handleState(e.target.value, column?.type, column?.value)}
                                                                                    rows="5"
                                                                                    id={column?.value}
                                                                                    onError={FormError[column?.value]}
                                                                                />

                                                                                <div className='w-100 text-right'>
                                                                                    <span className='form-label-pflicht'>{`* ${t('inquiry.required_fields')}`}</span>
                                                                                </div>
                                                                            </>
                                                                            : console.log()
                                                ))
                                            }
                                        </div>
                                        <div className='six_part diff_div'>
                                            <h2>{t('inquiry.subscribe_newsletter')}</h2>
                                            {
                                                formFields.map((column, key) => (
                                                    column?.value === "subscribe" &&
                                                    <>
                                                        <FormInput
                                                            key={key}
                                                            type={"radio"}
                                                            label="false"
                                                            selected={formData?.subscribe}
                                                            radioOption={subscribe}
                                                            name={column?.value}
                                                            className="form-input"
                                                            onChange={e => handleState(e, column?.type, column?.value)}
                                                            id={column?.value}
                                                        // onError={FormError[column?.value]}
                                                        />
                                                        <span className='form-label-pflicht'>{t('inquiry.left_content')}</span>
                                                    </>
                                                ))
                                            }
                                        </div>
                                        <button type="submit" onClick={OnSubmitForm} className="form-input-button mt-5">{t('inquiry.send')}</button>
                                        {Object.keys(FormError).length > 0 &&
                                            <div style={{ color: "rgb(255, 0, 0)", fontSize: "12px" }}>
                                                {t('inquiry.abschicken')}
                                            </div>
                                        }
                                    </SchoolactionFormwrapper>
                                </div>
                            </div>
                        </SchulaktionenTopright>
                    </div>
                </div>
                <ToastContainer />
            </div >
            {isLoading && <Loader />}
        </>

    )
}

FilmSubDetail.propTypes = propTypes;

export default FilmSubDetail;
