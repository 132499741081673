import { connect } from 'react-redux';
import movieModule from '../../../store/movies';
import Landings from './LandingPage.component';

const {
  getSchoolLandingPage,
  requestTopMovies,
  requestMovieDetail,
} = movieModule.actions;

const mapDispatchToProps = {
  fetchTopFilmTips: getSchoolLandingPage,
  getTopMovies: requestTopMovies,
  getMovieDetail: requestMovieDetail,
};

const mapStateToProps = ({ movies }) => (
  {
    landingPageData1: movies.landingPageData,
    films: movies.filmsDetail,
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landings);