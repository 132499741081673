import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    PosterWrapper, 
    SideBar, 
    RightList} from '../../../containers/aktuelle/styles';
import { useTranslation } from 'react-i18next';
import { getSortedAlphaNum } from '../../../utils';
import { SortElementsDiv } from '../../../containers/filmarchive/styles';
import CardUI from '../../Card/CardUI';
import AgeSidebar from '../../Sidebar/AgeSidebar';
import Loader from '../../Loader';
import { Pagination } from 'react-pagination-bar';
import 'react-pagination-bar/dist/index.css'
import 'react-tooltip/dist/react-tooltip.css';

const propTypes = {
    films: PropTypes.array.isRequired,
    getArchiveMovies: PropTypes.func.isRequired,
    states: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const ArchiveFilmList = ({ getArchiveMovies, films, isLoading }) => {
    const [t] = useTranslation();
    const [Films_, setFilms_] = useState([]);
    const [CheckBoxValue, setCheckBoxValue] = useState([]);
    const [LanguageValue] = useState([]);
    const [allTime, setAllTime] = useState(films);
    const [activeSet, setActiveSet] = useState("0");
    const Direct = films;
    const [currentPage, setCurrentPage] = useState(1);
    const pagePostsLimit = 20;

        // films.map((ele)=>{
        //     // console.log("films",ele.master);
        //     console.log("films",ele.master.rating, ele.master.ratingDescription);
        // })

    const UpdateFilmState = (CheckBoxValue) => {
        // setFilms_([]);
        // setAllTime([])
        let total_array = [];
        if (CheckBoxValue.length > 0 && CheckBoxValue.indexOf("all") === -1) {
            films?.length > 0 &&
                films?.map((film) => {
                    if (film?.master?.allStateRatings !== null) {
                        const ddd = film?.master?.allStateRatings;
                        if (ddd) {
                            const data = JSON.parse(ddd);
                            let uniqueAuthors = data?.stateFsk?.filter((valuedata, index, self) =>
                                index === self.findIndex((t) => (
                                    t?.fsk?.value === valuedata?.fsk?.value
                                ))
                            )
                            uniqueAuthors.map(item => {
                                if (item?.fsk) {
                                    const ageValue = item?.fsk?.value;
                                    if (ageValue !== 0 && CheckBoxValue.indexOf(ageValue) > -1) {
                                        if (!total_array.includes(film)) {
                                            total_array.push(film)
                                        }
                                    }
                                    else if (ageValue <= 0 && CheckBoxValue.indexOf("unrestricted") > -1) {
                                        if (!total_array.includes(film)) {
                                            total_array.push(film)
                                        }
                                    }
    
    
                                }
                            })
    
                        }
                    }
                       
                    })
            setAllTime(total_array)
        } 
        else if(CheckBoxValue.indexOf("all") > -1){
                setAllTime(films)            
        }
        else{
            setAllTime(films)  
        }
    }
    const UpdateFilmStatLanguage = (LanguageValue) => {
        // setAllTime([])
        if (LanguageValue.length > 0) {
            let filmArray = [];
            if (Films_.length > 0) {
                Films_?.length > 0 &&
                    Films_?.map((film) => {
                        film?.master?.technologies[0].map((arr) => {
                            if (LanguageValue.indexOf(arr) > -1) {
                                filmArray.push(film)
                            }
                        })
                    })
            } else {
                films?.length > 0 &&
                    films?.map((film) => {
                        film?.master?.technologies[0].map((arr) => {
                            if (LanguageValue.indexOf(arr) > -1) {
                                filmArray.push(film)
                            }
                        })
                    })
            }
            // setFilms_(filmArray)
            setAllTime(filmArray)
        } else {
            // setFilms_(Direct);
            setAllTime(Direct)
        }
    }
    const updateFilmStateSorting = (e, char) => {
        console.log("e---", e.target)
        setActiveSet(char)
        setAllTime([])
        films?.length > 0 &&
            films?.map((film) => {
                if ((film?.master?.titleCalculated?.indexOf(char) == 0)) {
                    setAllTime(allTime => [...allTime, film]);
                }
            })
    }

    useEffect(() => {
        UpdateFilmState(CheckBoxValue);
    }, [CheckBoxValue])

    useEffect(() => {
        UpdateFilmStatLanguage(LanguageValue);
    }, [LanguageValue])

    useEffect(() => {
        getArchiveMovies();
        setFilms_([])
    }, []);

    useEffect(() => {
        setAllTime(films)
    }, [films]);

    return (
        <>
            <SideBar>
                <>
                    <h3>{t('movies.movie-sorting')}</h3>
                    <SortElementsDiv>
                        {getSortedAlphaNum().map((item, key) => (
                            <span style={{ cursor: 'pointer' }} className={activeSet === item ? "alphabet_div" : ""} key={key} onClick={(e) => updateFilmStateSorting(e, item)}>{item}</span>
                        ))}
                        <p className="hinweis">
                            {t('common.article_wer')}<br></br>{t('common.nicht')}.
                        </p>
                    </SortElementsDiv>
                    <hr></hr>
                    <h3>{t('movies.age_rating')}</h3>
                    <AgeSidebar setCheckBoxValue={setCheckBoxValue} CheckBoxValue={CheckBoxValue}></AgeSidebar>
                    <div className="clearfix"></div>
                </>
            </SideBar>
            <RightList>
                <section className='right_section'>
                    <div className='container'>
                        <PosterWrapper>
                            <div className="text-wrapper poster_title">
                                <h2 className="page-subline-white">{t('movies.film-archive')}</h2>
                                <h3 className="page-subline-white sub-text">{t('movies.film-archive-desc')}</h3>
                            </div>
                        </PosterWrapper>
                    </div>
                </section>
                <section className='right_section mt-3'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-11 col-md-11 col-12'>
                                <div className='row'>
                                    {allTime.length > 0 ?
                                        allTime?.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                                            ?.map((film, key) => (
                                                <div className='col-lg-3 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center' key={key}>
                                                    <CardUI film={film} startDate={film.master?.startDate} ></CardUI>
                                                </div>
                                            ))
                                        : <h2>{t('common.no_data')}</h2>
                                    }
                                </div>
                                <div className='row'>
                                    <Pagination
                                        currentPage={currentPage}
                                        itemsPerPage={pagePostsLimit}
                                        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                                        totalItems={allTime?.length}
                                        pageNeighbours={2}
                                        customClassNames={{
                                            rpbItemClassName: 'custom-item',
                                            rpbItemClassNameActive: 'custom-item--active',
                                            rpbGoItemClassName: 'custom-go-item',
                                            rpbItemClassNameDisable: 'custom-item--disable',
                                            rpbProgressClassName: 'custom-progress-bar',
                                            rpbRootClassName: 'custom-root',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </RightList>
            {isLoading && <Loader />}
        </>

    )
}

ArchiveFilmList.propTypes = propTypes;

export default ArchiveFilmList;